export enum UserRoles {
  ROLE_ADMIN = 1,
  ROLE_COMPANY_ADMIN = 2,
  ROLE_USER = 3,
  ROLE_CAPTAIN = 4,
  ROLE_SUBADMIN = 5,
  ROLE_VENDOR = 6,
  ROLE_DRIVER = 6,
}

export enum UserRolesLabel {
  ROLE_ADMIN = 'Admin',
  ROLE_COMPANY_ADMIN = 'Company Admin/Manager',
  ROLE_USER = 'Purchase Officer',
  ROLE_CAPTAIN = 'Captain',
  ROLE_SUBADMIN = 'Sub Admin'
}

export enum Privilages {
  CREATE_AND_EDIT_COMPANY_USER = 8,
  CREATE_REQUISITION = 12,
  CREATE_COD_ORDER = 13,
  MANAGE_VENDOR_RFQS = 14,
  RESET_USERS_PASSWORD = 15,
  CREATE_VENDOR_COMPANY = 10,
  MANAGE_REQUISITIONS_VENDORS = 16,
  IMPORT_REQUISITION_DOC = 17,
  MANAGE_PRODUCT_PRICES = 18,
  MANAGE_CATALOGUE = 19,
  MANAGE_CATEGORIES_AND_SUB_CATAGORIES = 20,
  CHECK_WEBSITE_LEADS = 21,
  MANAGE_PORTS = 22,
  MANAGE_VENDOR_KYCS = 23,
  EDIT_ORDER = 24,
  MANAGE_SUB_ADMINS = 25,
  MANAGE_SHIPS = 27,
  MANAGE_VENDOR_AND_SHIPPING_COMPANY = 28,
  WAREHOUSE_KOCHIN = 31,
  WAREHOUSE_MUMBAI = 32,
  WAREHOUSE_GANDHIDHAM = 33,
  WAREHOUSE_CHENNAI = 34,
  WAREHOUSE_NOIDA = 42,
  GENERATE_BILL = 35,
  GENERATE_PO = 36,
  EDIT_ADJUSTMENTS = 37,
  SHOW_ALL_WAREHOUSES = 38,
  MANAGE_PURCHASE_RECEIVES = 39,
  MANAGE_PURCHASE_ORDERS = 40,
}

export enum Designation {
  Vendor = 6,
  Master = 25,
  Chief_Mate = 26,
  Chief_Cook = 27,
  '2nd_Mate' = 28,
  '3rd_Mate' = 29,
  Trainee_Officer = 30,
  Chief_Engineer = 31,
  '2nd_Engineer' = 32,
  '3rd_Engineer' = 33,
  '4th_Engineer' = 34,
  Junior_Engineer = 35,
  Driver = 36,
}
