import { Component, OnInit, Input, AfterViewInit, ViewChildren, ViewChild, TemplateRef, Output, EventEmitter } from "@angular/core";
import { NewDashboard } from "@app/dashboard/models/newdashboard.model";
import { Chart } from 'chart.js';
import { FilterComponent } from "../filter/filter.component";
import { PanelComponent } from "../panel/panel.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AlertService } from "@app/shared/service/alert.service";
import { SharedService } from "@app/shared/service/shared.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { DashboardService } from "@app/dashboard/dashboard.service";
import { ChartRedirectionService } from "@app/shared/service/chart-redirection.service";
@Component({
  selector: 'app-pie-chart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.css']
})

export class PieChartComponent implements OnInit, AfterViewInit {

  @Input() data: NewDashboard;
  pieData: NewDashboard;
  @ViewChildren('pieCharts') pieChart: any;
  @ViewChild('filterData', { static: false }) filterData: FilterComponent;
  @ViewChild('panelData', { static: false }) panelData: PanelComponent;
  @Output() callParentMethodEvent = new EventEmitter<string>();
  labelData = [];
  dataValue = [];
  navType = [];
  chart: any;
  editPanel: boolean;
  sharedData: NewDashboard;
  // modalRef: BsModalRef;
  filterLength: boolean;
  pieChartVisibility: boolean;
  isDownloading: boolean;
  deleteModal: BsModalRef;
  loadingDeletePane: boolean;
  constructor(private modalService: BsModalService,
    private altService: AlertService,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private router: Router,
    private dashboardService: DashboardService,
    private redirectionService: ChartRedirectionService) {
  }

  hiddenSegments: boolean[];

  ngOnInit() {
    this.hiddenSegments = new Array(this.data.axes.length).fill(false);
    this.pieData = this.data;
    this.initialisePieData();
  }

  initialisePieData() {
    if (this.data && typeof this.data != 'undefined') {
      this.data.column_values.forEach(response => {
        this.labelData.push(`${response[0]}`);
        if (response[1] != null)
          this.dataValue.push((response[1]));
        this.checkFilter();
      })
      this.navType = this.data.navigation_type;
      this.pieChartVisible();
    }
  }

  pieChartVisible() {
    this.pieChartVisibility = this.data.column_values.some(response => {
      return response[1];
    })
  }

  checkPieChart() {
    console.log(this.pieData);
  }

  checkFilter() {
    this.filterLength = this.data.filters.some(response => {
      return response.filter_value ? true : false;
    })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.createPieChart();
    }, 300)
  }

  createPieChart() {
    this.chart = null;
    var dataVal = {
      navigation: this.navType,
      labels: this.labelData,
      datasets: [{
        label: 'My First Dataset',
        data: this.dataValue,
        backgroundColor: [
          /*'rgb(151, 83, 142)',
          'rgb(68, 95, 158)',
          'rgb(61, 137, 196)',
          'rgb(79, 173, 218)',
          'rgb(75, 159, 144)',
          'rgb(168, 192, 91)',
          'rgb(229, 207, 75)',
          'rgb(228, 176, 66)',
          'rgb(204, 93, 95)',
          'rgb(183, 71, 118)' */
          'rgb(2, 33, 112)',
          'rgb(254, 107, 0)',
          'rgb(2, 123, 51)',
          'rgb(0, 116, 242)',
          'rgb(186, 68, 68)',
          'rgb(92, 171, 176)',
          'rgb(50, 83, 122)',
          'rgb(228, 108, 158)',
          'rgb(199, 167, 0)',
          'rgb(114, 114, 114)'
        ],
        hoverOffset: 4
      }]
    };
    let route = this.router;
    let canvasCharts = [];
    canvasCharts = this.pieChart._results;
    canvasCharts.map((myCanvas, i) => {
      this.chart = new Chart(myCanvas.nativeElement.getContext('2d'), {
        // type: 'pie',
        type: "doughnut",
        data: dataVal,
        options: {
          onHover: (event, chartElement) => {
            const target = event.native?.target as HTMLElement;
            if (target) {
              target.style.cursor = chartElement[0] ? 'pointer' : 'default';
            }
          },
          onClick: (event, elements) => {
            if (elements.length) {
              const clickedElement = elements[0];
              const datasetIndex = clickedElement.index;
              this.redirectionService.generateFilterBasedPayload(this.data, this.data.axes[datasetIndex].axis_id);
            }
            // this.redirectionService.generateFilterBasedPayload(val, val.axes[0].axis_id);
            // if (elements.length) {
            //   const clickedElement = elements[0];
            //   const datasetIndex = clickedElement.index;

            //   if (dataVal.navigation[datasetIndex]) {
            //     if (dataVal.navigation[datasetIndex].split("_").includes('order')) {
            //       switch (dataVal.navigation[datasetIndex]) {
            //         case "order_list":
            //           route.navigateByUrl('/orders')
            //           break;
            //         case "order_placed":
            //           route.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B1%255D%257D')
            //           break;
            //         case "order_out_for_delivery":
            //           route.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B3%255D%257D')
            //           break;
            //         case "order_delivered":
            //           route.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B4%255D%257D')
            //           break;
            //         case "order_cancelled":
            //           route.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B6%255D%257D')
            //           break;
            //         case "order_partially_delivered":
            //           route.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B5%255D%257D')
            //           break;
            //       }
            //     }
            //     else if (dataVal.navigation[datasetIndex].split("_").includes('requisition')) {
            //       switch (dataVal.navigation[datasetIndex]) {
            //         case "requisition_list":
            //           route.navigateByUrl('/requisitions')
            //           break;
            //         case "requisition_list_waiting_for_approval":
            //           route.navigateByUrl('/requisitions?req_status=0&page=1')
            //           break;
            //         case "requisition_list_sent_for_approval":
            //           route.navigateByUrl('/requisitions?req_status=1&page=1')
            //           break;
            //         case "requisition_list_rejected_by_manager":
            //           route.navigateByUrl('/requisitions?req_status=2&page=1')
            //           break;
            //         case "requisition_list_rejected_by_po":
            //           route.navigateByUrl('/requisitions?req_status=3&page=1')
            //           break;
            //         case "requisition_list_urgent":
            //           route.navigateByUrl('/requisitions?priority_type=Urgent&page=1')
            //           break;
            //       }
            //     }
            //   }
            // }
            // console.log(dataVal.navigation[datasetIndex])

            // if (dataVal.navigation[datasetIndex] === 'order_list') {
            //   route.navigateByUrl('/orders')
            // }
            // else if (dataVal.navigation[datasetIndex] === 'requisition_list') {
            //   route.navigateByUrl('/requisitions')
            // }
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: 'right',
              align: 'center',
              maxHeight: 180,
              labels: {
                generateLabels: (chart) => this.generateLabels(chart)
              },
              onClick: (e, legendItem, legend) => {
                const index = legendItem.index;
                this.hiddenSegments[index] = !this.hiddenSegments[index];
                this.updateChartVisibility(legend.chart);
              }
            },
          },

        }
      })
    })
  }

  private updateChartVisibility(chart) {
    const meta = chart.getDatasetMeta(0);
    this.hiddenSegments.forEach((hidden, index) => {
      console.log(meta, this.hiddenSegments);
      meta.data[index].hidden = hidden;
    });
    chart.update();
  }

  async downloadReport() {
    this.isDownloading = true;
    try {
      const res = await this.dashboardService.getReportData(this.data.report_id, null, 1);
      this.sharedService.saveAsBlob(
        res,
        'application/vnd.ms-excel',
        `PO_${this.data.report_title}.xlsx`
      );
      this.isDownloading = false;
      this.toastr.success('Downloaded Successfully.');
    } catch (error) {
      this.isDownloading = false;
      this.toastr.error('Failed to download report.');
    }
  }

  getValueAtIndexOrDefault(valueArray: any[], index: number, defaultValue: any) {
    if (valueArray && index < valueArray.length) {
      return valueArray[index];
    }
    return defaultValue;
  }

  generateLabels(chart) {
    const data = chart.data;

    if (!data || !data.labels || !data.datasets) {
      console.error('Invalid chart data:', data);
      return [];
    }

    if (data.labels.length && data.datasets.length) {
      return data.labels.map((label, i) => {
        const meta = chart.getDatasetMeta(0);
        const ds = data.datasets[0];
        const arc = meta.data[i];
        const custom = (arc && arc.custom) || {};

        const getValueAtIndexOrDefault = (value, i, defaultValue) =>
          Array.isArray(value) ? (value[i] !== undefined ? value[i] : defaultValue) : value;

        const arcOpts = chart.options.elements.arc;
        const fill = custom.backgroundColor
          ? custom.backgroundColor
          : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
        const stroke = custom.borderColor
          ? custom.borderColor
          : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
        const bw = custom.borderWidth
          ? custom.borderWidth
          : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

        // We get the value of the current label
        const value = ds.data[i];
        return {
          // Instead of `text: label,`
          // We add the value to the string
          text: label + ' : ' + value,
          fillStyle: fill,
          strokeStyle: stroke,
          lineWidth: bw,
          hidden: this.hiddenSegments[i] || isNaN(value),
          index: i
        };
      });
    } else {
      return [];
    }
  }


  onResize(event: Event): void {
    if (this.chart) {
      this.chart.resize();
    }
  }

  reloadPage(event) {
    this.callParentMethodEvent.emit(event);
  }

  openFilterModal() {
    this.filterData.openModel();
  }

  openPanelModal(isEdit = false) {
    this.editPanel = isEdit ? true : false;
    this.sharedData = this.data;
    this.panelData.openModel();
  }

  panelDeleteModal(template: TemplateRef<any>) {
    this.deleteModal = this.modalService.show(
      template,
      Object.assign({}, { class: "catalogue gray modal-sm" })
    );
    this.altService.backModalClose(this.deleteModal);
  }

  deletePanel() {
    this.loadingDeletePane = true;
    this.sharedService.deletePanel(this.data.report_id, 1).subscribe(res => {
      if (res) {
        this.toastr.success('Report Deleted Successfully!');
        this.dashboardService.deleteReport(true);
        this.callParentMethodEvent.emit(this.data.report_id.toString());
        this.loadingDeletePane = false;
        this.deleteModal.hide();
      }
    }, error => {
      this.toastr.error('Something went wrong!')
      this.loadingDeletePane = false;
      this.deleteModal.hide();
    })
  }

  filterAppied(event) {
    if (this.data.report_id) {
      this.data = event[0];
      this.labelData.length = 0;
      this.dataValue.length = 0;
      this.initialisePieData()
      this.chart?.destroy();
      this.ngAfterViewInit();
      this.dashboardService.deleteReport(false);
      this.callParentMethodEvent.emit(this.data.report_id.toString());
      //this.ngOnInit();
      this.checkFilter();
    }
    this.pieChartVisible();
  }

  /* Remove Filter */
  removeFilter(event) {
    this.sharedService.removeFilter(event);
    this.callParentMethodEvent.emit(event.report_id.toString());
  }
}