import { Privilages, UserRoles } from '@app/core/enums/user-roles';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Utils } from '@app/shared/service/util';
import { SharedService } from '@app/shared/service/shared.service';
import { RfqService } from '@app/modules/rfq/services/rfq.service';
import { UserService } from '@app/modules/user/services/user.service';
import { Company } from '@app/modules/company/models/company.modal';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '@app/authentication/services/loading.service';
import { ShipService } from '@app/modules/ship/services/ship.service';
import { PortService } from '@app/modules/port/port.service';
import { CategoryService } from '@app/modules/category/services/category.service';
import { NewDashboard } from '../models/newdashboard.model';
import { DashboardService } from '../dashboard.service';
import { PanelComponent } from '@app/shared/new-dashboard-config/panel/panel.component';
import { DataConfiguration } from '../models/data-configuration.model';
import { CdkDragEnter, CdkDragExit, moveItemInArray } from '@angular/cdk/drag-drop';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from '@app/shared/service/alert.service';
import { NewRequisitionQuotationService } from '@app/modules/new-requisition-quotation/services/new-requisition-quotation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from '@app/modules/user/models/user.modal';
import { FeedbackFormData } from '../models/feedback-form.model';
import { FeedbackFormComponent } from '@app/shared/new-dashboard-config/feedbackform/feedbackform.component';
import { CopyLinkService } from '@app/shared/service/copylink.service';
import { environment } from '@environment/environment';
import { OrderRatingComponent } from '@app/shared/components/order-rating/order-rating.component';
import { IndexedDbService } from '@app/shared/service/indexeddb.service';
import { interval } from 'rxjs/internal/observable/interval';
import { DisableRightClickService } from '@app/shared/service/disable-right-click.service';
@Component({
  selector: 'app-dashboard-updates',
  templateUrl: './dashboard-updates.component.html',
  styleUrls: ['./dashboard-updates.component.css']
})
export class DashboardUpdatesComponent implements OnInit, OnDestroy {
  isFeedbackFormModelOpen: boolean = false;
  isLoadingLoader = false;
  role: number;
  company: Company;
  userRoles = UserRoles;
  RFQCount: any;
  loading = false;
  shipList = [];
  categoryList = [];
  portList = [];
  dynamicSection: NewDashboard[] = [];
  productData = []
  dataCard = [];
  @ViewChild('viewCreatedForms', { static: false }) viewCreatedForms: TemplateRef<any>;
  @ViewChild('panelModal', { static: false }) panelModal: PanelComponent;
  @ViewChild('feedbackModal', { static: false }) feedbackModal: FeedbackFormComponent;
  @ViewChild('assignedUser', { static: false }) assignedUser: TemplateRef<any>;
  @ViewChild('orderRating', { static: false }) orderRating: OrderRatingComponent;
  panelData: DataConfiguration[]
  loggedInUserName
  currentItem: any
  modalRef: BsModalRef;
  fileNameList: any[];
  selectFile: boolean;
  submitted: boolean;
  fileName: string;
  isLoading: boolean = false;
  formData: FormData;
  inValidFileSize: boolean;
  count: number = 0;
  files;
  userRole = UserRoles;
  vendorRates = [];
  showLoading: boolean;
  rfqReportModal: BsModalRef;
  filterForm: FormGroup;
  openFilters: boolean;
  rfqLoading: boolean;
  users = [];
  minDate: string | Date = new Date();
  feedbackForms: FeedbackFormData[];
  formId: number;
  order_id: number;
  usersData;
  assignForm;
  assignedRoleIds = [];
  createdFormModal: BsModalRef;
  assignFormModal: BsModalRef;
  userList = []
  roleList: any;
  designationList = []
  filteredDesignation: any;
  formsToSubmit = [];
  disableUser: boolean;
  disableDesignation: boolean;
  selectUser: any;
  userDataLoader: boolean;
  loadDesignation: boolean;
  generatedLink: string;
  dataSection = []
  subAdminPrivilages: [number];
  subAdminUploadFile: boolean = false;
  privilages = Privilages;
  isCategoryLoading: boolean = false;
  category: any[] = [];
  categoryId: number = 0;

  refreshSubscription: any;
  subAdminList: { name: string; userId: any; roleId: any; }[];
  showReports: BsModalRef;
  checkedReport = [];
  dataSections: any[]
  createNewDynamicSection: NewDashboard[];
  vendorId: number;
  isDeleted: boolean;
  vendorData: any;
  isClicked: boolean = false;
  rewardPoints: any;
  rewardPointsExpiry: any;
  //dashboard;
  constructor(
    private sharedService: SharedService,
    private rfqService: RfqService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private router: Router,
    private loadingService: LoadingService,
    private shipService: ShipService,
    private portService: PortService,
    private categoryService: CategoryService,
    private dashboardService: DashboardService,
    private modalService: BsModalService,
    private alertService: AlertService,
    private newRequisitionQuotationService: NewRequisitionQuotationService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private copyLinkService: CopyLinkService,
    private indexedDBService: IndexedDbService,
    private cd: ChangeDetectorRef,
    private disableRightClick: DisableRightClickService
  ) {
    this.filterForm = this.formBuilder.group({
      sub_admin_user: [null],
      from_date: [null, [Validators.required]],
      to_date: [null, [Validators.required]]
    });

    if (environment.production == true) {
      this.generatedLink = "https://admin.shipskart.com/feedback-form"
    } else {
      this.generatedLink = "https://shipskartbeta.web.app/feedback-form"
      //this.generatedLink = "http://localhost:4200/feedback-form"
    }
  }
  ngOnDestroy(): void {

  }

  ngOnInit() {
    localStorage.removeItem('filterPayload');
    this.disableRightClick.detectDevTools()
    const action = this.activatedRoute.snapshot.queryParamMap.get('action');
    const token = this.activatedRoute.snapshot.queryParamMap.get('token');
    this.role = this.sharedService.getUserRole();
    this.submittedFormListing();
    this.formId = parseInt(this.activatedRoute.snapshot.queryParamMap.get('form_id'));
    this.order_id = parseInt(this.activatedRoute.snapshot.queryParamMap.get('order_id'));
    this.getPanelData()
    this.getDashBoardData()
    this.company = this.sharedService.getCompany();
    if (this.role == this.userRole.ROLE_COMPANY_ADMIN && this.company.type == 2) {
      this.getVendorData();
    }
    /* Checking privilage for subadmin role  to Import DOC for RFQ */
    if (this.role == this.userRole.ROLE_SUBADMIN) {
      this.subAdminPrivilages = this.sharedService.getSubAdminsPrivileges();
      if (this.subAdminPrivilages && this.subAdminPrivilages.length > 0)
        this.subAdminUploadFile = this.subAdminPrivilages.includes(this.privilages.IMPORT_REQUISITION_DOC);
      console.log('hii')
    }
    if (this.role == this.userRoles.ROLE_ADMIN || this.role == this.userRoles.ROLE_SUBADMIN) {
      this.userService.getAll(1, 1000, null, '', 0, 1).subscribe(data => {
        if (data) {
          let providedData = [];
          providedData = data.results.filter((response) => {
            // console.log(response)
            if (response) {
              if (response.user_role.length > 0) {
                if (this.role == this.userRoles.ROLE_ADMIN) {
                  if (response.user_role[0].role_id)
                    return true;
                }
                if (this.role == this.userRoles.ROLE_SUBADMIN)
                  if (response.user_role[0].role_id != 5)
                    return true;
              }
            }
          })
          this.usersData = providedData.map((response) => {
            if (response) {
              let fullname = `${response.fname} ${response.lname ? response.lname : ''} `
              let userId = response.id
              let roleId;
              if (response.user_role.length > 0) {
                roleId = response.user_role[0].role_id
              }
              let name = { name: fullname, userId: userId, roleId: roleId }
              return name
            }
          })
          this.subAdminList = providedData.filter((response) => {
            return response &&
              response.user_role.length > 0 &&
              response.user_role[0].role_id == 5;
          }).map((response) => {
            let fullname = `${response.fname} ${response.lname ? response.lname : ''} `;
            let userId = response.id;
            let roleId = response.user_role[0].role_id;
            let name = { name: fullname, userId: userId, roleId: roleId };
            return name;
          })
        }
      })
    }

    if (action == 'confirm_email' && token) {
      this.userService.confirmEmail(token).subscribe(data => {
        this.toastrService.success('Email verified successfully');
        this.router.navigateByUrl('/');
      }, error => {
        this.toastrService.error(error.error);
        this.router.navigateByUrl('/login');
      });
    } else {
      this.role = this.sharedService.getUserRole();
      let currentLoggedUserName = localStorage.getItem('currentUser');
      //let x = JSON.parse(currentLoggedUserName)
      let currentUserName = JSON.parse(currentLoggedUserName)
      if (this.role == this.userRoles.ROLE_CAPTAIN) {
        this.loggedInUserName = currentUserName.data.ship_ids[0].name;
      } else {
        this.loggedInUserName = currentUserName.data.user_details.user_details.fname;
      }


      if (
        this.role === this.userRoles.ROLE_COMPANY_ADMIN &&
        this.company.type === 2
      ) {
        this.rfqCount();
      }
    }
    // this.getShipList();
    // this.getCategoryList();
    // this.getPortList();


    if ((this.role == this.userRoles.ROLE_ADMIN || this.role === this.userRoles.ROLE_SUBADMIN)) {
      if (this.formId && this.order_id) {
        this.getAllFeedbackAssignedUser();
        setTimeout(() => {
          this.openEndUserModal();
        }, 100)
      }
      //this.openFeedbackModal(this.formId, this.order_id);
    }

    const currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.selectUser = currentUser.data.user_details.user_id;
    if (this.role == this.userRoles.ROLE_CAPTAIN || this.role == this.userRoles.ROLE_COMPANY_ADMIN) {
      if (this.formId && this.order_id && this.selectUser)
        this.openFeedbackModal(this.formId, this.order_id, this.selectUser);
    }
    if (this.role === this.userRoles.ROLE_COMPANY_ADMIN) {
      this.vendorId = this.company.id;
    }
    this.dashboardService.currentDelete.subscribe(res => this.isDeleted = res)
  }

  submittedFormListing() {
    let is_self = (this.role === this.userRoles.ROLE_ADMIN || this.role === this.userRoles.ROLE_SUBADMIN) ? 1 : 0;
    this.dashboardService.getFeedbackForms(null, is_self).subscribe(data => {
      this.formsToSubmit = data['data']['results'];
    })
  }

  selectUserByDropDown(event) {
    this.assignedRoleIds = event;
    this.disableDesignation = true;
    this.filteredDesignation = []
    if (this.assignedRoleIds.length > 0 && this.assignedRoleIds[0].roleId) {
      this.filteredDesignation = this.designationList.filter(designation => designation.role_id == this.assignedRoleIds[0].roleId)
    }
  }

  /**
   * getAllReports(): getting all dashboardReportData
   * 
    */

  getAllReports() {
    this.loadingService.setLoading(true);
    this.dashboardService.getDashboardReports().subscribe(async data => {
      this.dataSection = [...data['data']];
      const local = JSON.parse(localStorage.getItem('removeReport')) || [];

      // Extract report ids from localStorage for comparison
      const localReportIds = local.map(report => report.report_id);

      // Filter out reports that are already in localStorage
      const newReports = this.dataSection
        .filter(report => !localReportIds.includes(report.report_id))
        .map(report => ({ ...report, isChecked: false }));

      // Update localStorage with new reports if necessary
      if (newReports.length > 0) {
        localStorage.setItem('removeReport', JSON.stringify([...local, ...newReports]));
      }

      // Decide which data to use for display based on local storage size comparison
      if (local.length !== this.dataSection.length) {
        this.dataSection = this.dataSection.map(report => ({ ...report, isChecked: false }));
      } else if (local.length > 0) {
        this.dataSection = [...local];
      }

      // Filter reports where 'isChecked' is false
      this.dataSections = this.dataSection.filter(report => !report.isChecked);

      // Set loading to false and proceed to get the report by id
      this.loadingService.setLoading(false);
      this.getReportById();

    }, error => {
      this.toastrService.error(error.error.message);
      this.loadingService.setLoading(false);
    });

  }


  //fetching reportbyId
  async getReportById(reportID?: number) {
    // Fetching report data for each report in dataSection.
    if (this.dataSection.length > 0) {
      //Checking if dynamicSection having reports to show on screen then filtered to in dataSection(locastorage data) having isChecked is false data filterredout
      if (reportID) {
        //Cheking if Report is deleted then filter
        if (this.isDeleted) {
          this.dynamicSection = this.dynamicSection.filter(report => report.report_id != reportID);
          this.dataCard = this.dynamicSection.filter(data => data.report_type == "data_card");
          this.isDeleted = false;
          return;
        }
        let res = await this.dashboardService.getReportData(reportID, null, 0);
        if (res) {
          // Check if the new item exists in the result
          const newItem = res[0];
          const existingIndex = this.dynamicSection.findIndex(result => result.report_id === newItem.report_id);
          if (existingIndex !== -1) {
            // Update the existing item
            this.dynamicSection[existingIndex] = newItem;
          }
          // Update the createNewDynamicSection array
          this.createNewDynamicSection = [...this.dynamicSection];
        }
        this.dataCard = this.dynamicSection.filter(data => data.report_type == "data_card");
      } else {
        for (const item of this.dataSection) {
          if (item.report_id && !item.isChecked) {
            let res = await this.dashboardService.getReportData(item.report_id, null, 0);
            if (res) {
              if (!this.dynamicSection.some(result => result.report_id === res[0].report_id)) {
                this.dynamicSection.push(res[0]);
                this.createNewDynamicSection = [...this.dynamicSection];
              }
              const removeIndex = this.dataSections.findIndex(report => (report.report_id === item.report_id));
              if (removeIndex !== -1) {
                this.dataSections.splice(removeIndex, 1);
              }
            }
            this.dataCard = this.dynamicSection.filter(data => data.report_type == "data_card");
          }
        }
      }
    }
  }

  /**
   * GetdashboardData: This method is used for getting to show on dashboard
   * dataSection variable: setting ids from api to show shimmer UI.
   * dynamicSection variable: getting data api pushed in this aaray and show data.
   * pop out data from dataSection.
   * */
  getDashBoardData(isChecked: boolean = false) {
    this.getAllReports();
  }

  /**
   * categorySelected() : change category
   */
  categorySelected(event) {
    if (event) {
      this.categoryId = event.id;
    }
  }

  private rfqCount() {
    this.loading = true;
    this.loadingService.setLoading(true);
    return this.rfqService.rfqsCount(this.company.id).subscribe(rfqData => {
      this.RFQCount = rfqData.data.total;
      this.loading = false;
      this.loadingService.setLoading(false);
    });
  }
  getShipList() {
    this.shipService.getAll(1, 1000).subscribe(data => {
      this.shipList = data.results;
    }, error => {
      this.toastrService.error(error.error.message);
    });
  }
  /* Old Method not in use */
  /* getCategoryList() {
    this.categoryService.getAllV2(1, 20).subscribe(data => {
      this.categoryList = data.data;
    }, error => {
      this.toastrService.error(error.error.message);
    });
  } */

  /* getPortList() {
    this.portService.getPortsForComparision(1, 500, 1).subscribe(res => {
      this.portList = res.results;
    }, error => {
      this.toastrService.error(error.error.message);
    });
  } */
  /* Old Method not in use */

  //Subscribe metadata api to get paneldata.
  getPanelData() {
    this.dashboardService.getPanelData().subscribe((data) => {
      this.panelData = { ...data.data };
      if (data['reward_points']) {
        this.rewardPoints = data['reward_points'] ? data['reward_points'] : 0;
        this.rewardPointsExpiry = data['reward_expiry_date'] ? data['reward_expiry_date'] : 'N/A';
      }
    });
  }

  //Open Panel Modal to ceate new panel
  openPanelModal() {
    this.panelModal.openModel();
  }

  openFeedbackModal(formId, order_id, userId) {
    if (this.isFeedbackFormModelOpen) {
      return;
    }
    this.isFeedbackFormModelOpen = true;
    this.feedbackModal.openModal(formId, order_id, this.selectUser);
  }

  onDragStart(report) {
    this.currentItem = report

  }

  onDrop(event, item) {
    const prevIndex = event.previousIndex;
    const currentIndex = event.currentIndex;
    let primaryAxisId = event.item.data.axes.filter(primaryData => primaryData.is_main_axis === true).map(primaryIds => primaryIds.axis_id).join()

    let payload = {
      title: event.item.data.report_title,
      report_type: event.item.data.report_type,
      primary_axis_id: +primaryAxisId,
      position_index: currentIndex
    }

    moveItemInArray(this.dynamicSection, prevIndex, currentIndex);

    /*  this.dashboardService.updateReportData(payload, event.item.data.report_id).subscribe(data => {
       console.log(data);

     }) */
  }

  enteredContainerId
  onDragEnter(event: CdkDragEnter<string[]>): void {
    this.enteredContainerId = event.container.id;
  }

  onDragExit(event: CdkDragExit<string[]>): void {
    this.enteredContainerId = null;
  }

  openImportModal(template: TemplateRef<any>) {
    this.indexedDBService.deleteDatabase()
      .then(() => console.log('Database deleted successfully'))
      .catch(error => console.error(error));;//clear database store before uploading file
    const page = 1;
    if (this.category.length == 0) {
      this.isCategoryLoading = true;
      this.categoryService.getAllV2(page, 20).subscribe((data) => {
        this.category = data.data;
        this.isCategoryLoading = false;
      });
    }
    this.fileNameList = [];
    this.modalRef = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', keyboard: false, class: 'gray modal-small product-modal' })
    );
    this.alertService.backModalClose(this.modalRef)
  }

  /* Updload requisitiom */
  uploadReqQuotation() {
    this.submitted = true;
    if (!this.selectFile || this.inValidFileSize) {
      return false;
    }
    //create indexeddb database to upload excel file
    this.indexedDBService.openDatabase();
    if (!this.formData.get('file')) {
      this.formData.append('file', null);
    }
    this.isLoading = true;
    let excelList = []
    const uploadStartTime = new Date();
    Array.from(this.fileNameList).forEach((name, i) => {
      const fileReader = new FileReader();
      if (name.name) {
        const filesize = this.files[i].size / 1024 / 1024;
        if (filesize > 5) {
          this.inValidFileSize = true;
        } else {
          this.formData = new FormData;
          this.formData.append('file', this.files[i], this.files[i].name);
          this.formData.append('category_id', this.categoryId.toString());
          this.selectFile = true;
          /* this.subscriptions.add( */
          this.newRequisitionQuotationService.uploadRequisitionQuotation(this.formData).subscribe(data => {
            this.modalRef.hide();
            this.toastr.success('Successfully uploaded!', '',
              {
                closeButton: true,
                timeOut: 2000,
                positionClass: 'toast-bottom-left'
              }
            );
            if (data && data.status == "success") {
              this.count++;
              let fileStartTime = new Date();
              let fileUploadTime
              fileReader.onload = () => {
                const fileEndTime = new Date();
                fileUploadTime = fileEndTime.getTime() - fileStartTime.getTime();
                fileUploadTime = 1000
              };
              fileReader.readAsDataURL(this.files[i]);
              const uploadEndTime = new Date();
              let totalUploadTime = uploadEndTime.getTime() - uploadStartTime.getTime();
              excelList.push({ data: data.data, newRequisition: i, intime: Math.round(totalUploadTime / 1000), category: data.category });
              if (excelList.length > 0) {
                //save data in indexedDB
                this.indexedDBService.saveToIndexedDB(excelList[i]);
              }
              //localStorage.setItem('reqQuotationData', JSON.stringify(excelList));
              if (this.count > 0) {
                if (this.fileNameList.length > 1) {
                  const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard/new-requisition-quotation'], { queryParams: { i } }));
                  window.open(url, '_blank').focus();
                } else {
                  this.router.navigate(['/dashboard/new-requisition-quotation'], { queryParams: { i } });
                }
              }
            }
            this.selectFile = false;
            this.submitted = false;
            this.fileName = null;
            this.isLoading = false;
            this.formData = new FormData();
          }, error => {
            this.toastr.error('Something went wrong!', '',
              {
                closeButton: true,
                timeOut: 2000,
                positionClass: 'toast-bottom-left'
              });
            this.submitted = false;
            this.isLoading = false;
            this.selectFile = false;
            this.fileNameList = [];
            this.fileName = null;
            this.selectFile = false;
            this.submitted = false;
            this.inValidFileSize = false;
            this.indexedDBService.deleteDatabase()
              .then(() => console.log('Database deleted successfully'))
              .catch(error => console.error(error));;//clear indexedDB database.
            this.formData = new FormData();
          })

        }
      }
    })
  }

  /* Select File to upload  */
  selectedfileName(files: FileList) {
    if (files.length > 0 && files.length <= 5) {
      this.fileName = files[0].name;
      Array.from(files).forEach(arr => {
        this.fileNameList.push(arr);
      });
      this.inValidFileSize = false;
      this.files = files
      Array.from(this.fileNameList).forEach((name, i) => {
        if (name.name) {
          const filesize = files[i].size / 1024 / 1024;
          if (filesize > 5) {
            this.inValidFileSize = true;
          } else {
            this.formData = new FormData;
            this.selectFile = true;
          }
        }
      })
    } else {
      if (this.fileNameList.length == 0) {
        this.toastr.error('You can upload Minimum 1 file and Maximum 5 files', '',
          {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-bottom-left'
          });
        this.isLoading = false;
      }
    }
  }

  /* Open Rating Modal */
  showModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', class: 'gray modal-lg fit' })
    );
    this.alertService.backModalClose(this.modalRef)
  }

  viewFormModal(template: TemplateRef<any>) {
    this.dashboardService.getFeedbackForms().subscribe(data => {
      this.feedbackForms = data['data']['results'];
    })
    this.createdFormModal = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', class: 'gray modal-xl' })
    );
    this.alertService.backModalClose(this.modalRef)
  }

  viewFormAssignmentModal(template: TemplateRef<any>, formId) {
    this.assignForm = this.formBuilder.group({
      to_user_ids: [, [Validators.required]],
      role_ids: [],
      designation_ids: [],
      order_id: [0]
    })
    if (this.designationList.length == 0) {
      this.getDisignation()
    }
    this.formId = formId;
    if (this.users.length == 0) {
      this.getUserLists()
    }
    this.assignFormModal = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', class: 'gray modal-lg' })
    );
    this.alertService.backModalClose(this.modalRef)
  }

  onAssignFormSubmit() {
    this.isLoadingLoader = true;
    this.assignForm.get('designation_ids').setValue(this.assignForm.get('designation_ids').value);
    if (this.assignForm.get('designation_ids').disabled) {
      this.assignForm.get('designation_ids').enable();
    }
    if (this.assignForm.get('to_user_ids').disabled) {
      this.assignForm.get('to_user_ids').enable();
    }
    this.assignForm.get('designation_ids').setValue(this.assignForm.get('designation_ids').value);
    this.dashboardService.assignForm(this.assignForm.value, this.formId).subscribe(data => {
      this.toastr.success("Form Assigned Successfully", "Success",
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      this.assignForm.reset();
      this.assignedRoleIds = [];
      this.assignFormModal.hide();
      this.createdFormModal.hide();
      this.isLoadingLoader = false;
      /* this.modalRef.hide(); */
    }, error => {
      this.toastr.error(error.error || error.message || "Something Went Wrong!", '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      this.isLoadingLoader = false;
    })
  }

  onViewClick(formId: number) {
    this.createdFormModal.hide();
    this.router.navigate(['/dashboard/create-form'], { queryParams: { form_id: formId } })
  }

  /* Vendor rating api subscribe */
  vendorRate() {
    /* this.subscriptions.add( */
    this.userService.getVendorRating().subscribe(response => {
      this.vendorRates = response;
      this.showLoading = false;
    })
    /* ) */
  }

  exportRfqreport(template: TemplateRef<any>) {
    this.rfqReportModal = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', class: 'gray modal-lg' })
    );
    this.alertService.backModalClose(this.rfqReportModal)

  }

  /* Export Subadmin RFQ report Filter options  */
  filterData() {
    if (this.filterForm.get('from_date').value) {
      const fromDate = this.datePipe.transform(this.filterForm.value.from_date, 'yyyy-MM-dd');
      this.filterForm.get('from_date').setValue(fromDate);
    }
    if (this.filterForm.get('to_date').value != null) {
      const toDate = this.datePipe.transform(this.filterForm.value.to_date, 'yyyy-MM-dd');
      this.filterForm.get('to_date').setValue(toDate);
    }
    this.exportReport('xlsx', this.filterForm.get('from_date').value, this.filterForm.get('to_date').value, this.filterForm.get('sub_admin_user').value)
    this.openFilters = false;
  }

  /* Export SUB-Admin Report */
  exportReport(type = 'xlsx', from_date = null, to_date = null, sub_admin_user = null) {
    let exports = 1;
    var query = '';
    if (from_date != null) {
      query = query + `&from_date=${from_date}`;
    }
    if (to_date != null) {
      query = query + `&to_date=${to_date}`;
    }
    if (sub_admin_user != null) {
      query = query + `&user_id=${sub_admin_user}`;
    }
    const mime = type === 'pdf' ? 'application/pdf' : 'application/vnd.ms-excel';
    const fileName = ('rfqs_report').replace(/ /g, '_');
    this.rfqLoading = true;
    /*  this.subscriptions.add( */
    this.newRequisitionQuotationService.exportRfqReport(exports, query).subscribe(data => {
      if (data) {
        this.sharedService.saveAsBlob(data, mime, `${fileName}.${type}`);
        this.rfqLoading = false;
      }
    })
    /*  ) */
  }

  /* Change ETA in RFQ Report*/
  onChangeEta(event) {
    this.minDate = event;
  }

  openEndUserModal() {
    this.modalRef = this.modalService.show(
      this.assignedUser,
      Object.assign({ backdrop: 'static', class: 'gray modal-sm' }
      ))
  }

  getAllFeedbackAssignedUser() {
    this.dashboardService.getFeedbackFormAssignedUser(this.formId, this.order_id).subscribe(res => {
      this.userList = res['data'].map(user => `${user.to_user.fname} ${user.to_user.lname ? user.to_user.lname : ''}`);
    })
  }

  //Select End
  changeUser(event) {
    this.selectUser = event.id;
    this.modalRef.hide();
    if ((this.role == this.userRoles.ROLE_ADMIN || this.role == this.userRoles.ROLE_SUBADMIN) && this.selectUser) {
      this.openFeedbackModal(this.formId, this.order_id, this.selectUser);
    }
  }

  getDisignation() {
    this.loadDesignation = true;
    this.userService.getDesignation(1, 1000).subscribe(res => {
      this.loadDesignation = false;
      this.designationList = res.results.filter(designation => designation.designations.length > 0);
    })
  }

  navigateUrl(id) {
    if (id == 74) {
      this.router.navigate(['/dashboard/delivery/feedback-form-listing'], { queryParams: { formId: id } })
      this.createdFormModal.hide();
    } else {
      this.router.navigate(['/dashboard/feedback-form-listing'], { queryParams: { formId: id } });
      this.createdFormModal.hide();
    }
  }

  resetForm() {
    this.filterForm.reset();
  }

  changeDesignation(event) {

    if (event && event.length > 0) {
      this.assignForm.get('to_user_ids').disable()
    } else {
      this.assignForm.get('to_user_ids').enable()
    }
    this.disableUser = true;
  }

  selectToAssignUser(event) {
    if (event && event.length > 0) {
      this.assignForm.get('designation_ids').disable()
    } else {
      this.assignForm.get('designation_ids').enable()
    }
    this.assignedRoleIds = event;
    this.disableDesignation = true;
    this.filteredDesignation = []
    if (this.assignedRoleIds.length > 0 && this.assignedRoleIds[0].roleId) {
      this.filteredDesignation = this.designationList.filter(designation => designation.role_id == this.assignedRoleIds[0].roleId)
    }
  }

  //Get all list of USERS assigned and not assigned
  getUserLists() {
    const params = new HttpParams()
      .set('page', `${1}`)
      .set('limit', `${3000}`)
      .set('form_id', `${this.formId}`)
    const headers = new HttpHeaders()
    this.userDataLoader = true
    this.dashboardService.getUserList(params, headers).subscribe(response => {
      this.userDataLoader = false
      const providedData = response.results.filter((response) => {
        if (response && response.user_role) {
          return true;
        }
      })
      this.users = providedData.map((user: User) => {
        if (user.user_role) {
          let rowData = {
            id: user.id,
            fname: user.fname,
            lname: user.lname ? user.lname : '',
            form_status: user.form_status == "assigned" ? 1 : 0
          };
          rowData.fname = rowData.fname + ' ' + rowData.lname;
          return rowData;
        }
      });
    })
  }

  onChildSubmitted(event) {
    this.submittedFormListing();
  }

  async copyLink(formId) {
    this.generatedLink = this.generatedLink + '?' + 'form_id=' + formId;
    const success = await this.copyLinkService.copyToClipboard(this.generatedLink);
    if (success) {
      this.toastr.success('Link copied to clipboard', '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
    } else {
      console.error('Failed to copy link to clipboard');
    }
  }

  /**
   * openRatingModal(): method used to show rating by vendor
   * vendorId: param used to access vendorId pass in modal to open rating
    */
  openRatingModal(vendorId) {
    if (!this.isClicked) {
      this.isClicked = true;
      setTimeout(() => {
        this.orderRating.openModal(vendorId);
        this.isClicked = false;
      }, 500)
    }
  }

  /**
   * startAutoRefresh(): after save data autorefresh indexedDB
   *
    */

  startAutoRefresh() {
    this.refreshSubscription = interval(5000)
      .pipe(
      /*  switchMap(() => this.fetchDataFromServer()) */
    )
      .subscribe(
        (data: any) => {
          this.updateIndexedDB(data);
        },
        (error) => {
          console.error('Error fetching data from server:', error);
        }
      );
  }

  updateIndexedDB(data) {
    //update indexedDB database.
    //this.indexedDBService.updateData(data)
  }

  stopAutoRefresh() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  /**
   * Show All Report in modal for check and uncheck
   */
  showReport(template: TemplateRef<any>) {
    this.showReports = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', class: 'gray modal-md' }
      ))
    this.alertService.backModalClose(this.showReports)
  }

  /**
   * removeReport(): this method used for checked or unchecked reports
    */
  removeReport(event, report, index) {
    // Initialize this.checkedReport as an empty array if it's null
    if (!this.checkedReport) {
      this.checkedReport = [];
    }
    report.isChecked = event.target.checked == true ? true : false;
    // Use Map to remove duplicate reports
    const mapReport = new Map(this.dataSection.map(item => [item.report_id, item]));
    this.dataSection = Array.from(mapReport.values());
  }

  /**
   * submiteHideReport(): method used for submit and store in localstorage.
   * getDashboardData() is callling again
   */
  submitHidereport() {
    localStorage.setItem('removeReport', JSON.stringify(this.dataSection));
    this.showReports.hide();
    this.updateData()
  }

  //Update reports show hide , delete feature without again api call
  async updateData(event?, isEdit: boolean = false) {
    if (event == undefined) {
      this.getAllReports();
      this.dataSections.length = 0;
      return;
    } else {
      const localStorageData = localStorage.getItem('removeReport');
      if (localStorageData && !event) {
        this.dataCard = [];
        let reportsData = JSON.parse(localStorageData).filter(report => report.isChecked === false);
        let map = new Map(reportsData.map(item => [item.report_id, item]).values());
        this.dynamicSection = this.createNewDynamicSection.filter(report => map.has(report.report_id));
        this.dataCard = this.dynamicSection.filter(card => card.report_type === 'data_card');
      } else {
        if (!isEdit) {
          this.dynamicSection = this.dynamicSection.reduce((acc, item) => {
            if (item.report_id != event) {
              acc.push(item);
            }
            return acc;
          }, []);
        }
        this.dataCard = this.dynamicSection.filter(card => card.report_type === 'data_card');
      }
    }

    this.getReportById(event);
    this.cd.detectChanges(); // Trigger change detection
  }

  navigate() {
    if (this.vendorId)
      this.router.navigate(['/RFQs/shipskart-rfq'], { queryParams: { vendor: this.vendorId } });
  }
  //Get Vednor Rating and Delivery Strength
  getVendorData() {
    this.userService.getDashboard(null, 0, "json", 0).subscribe({
      next: (data) => {
        if (this.role === this.userRoles.ROLE_COMPANY_ADMIN && this.company.type === 2) {
          this.vendorData = data;
        }
      },
      error: (error) => {
        this.toastr.error(error)
      },
    });
  }
}
