import { Product } from '@app/modules/product/model/product.model';
import { Ship } from '@app/modules/ship/model/ship.model';
import { Privilages, UserRoles, Designation } from '@app/core/enums/user-roles';
import { CartService } from './../services/cart.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Order } from '@app/modules/order/model/order.model';
import { SharedService } from '@app/shared/service/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipService } from '@app/modules/ship/services/ship.service';
import { ProductPopupComponent } from '@app/shared/components/product-popup/product-popup.component';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { RfqService } from '@app/modules/rfq/services/rfq.service';
import { CategoryService } from '@app/modules/category/services/category.service';
import { DatePipe, TitleCasePipe } from '@angular/common';
import swal from 'sweetalert2';
import { LoadingService } from '@app/authentication/services/loading.service';
import { CommonService } from '@app/shared/helper/common';
import { AlertService } from '@app/shared/service/alert.service';
import { FormDataToJsonService } from '@app/shared/service/form-data-to-json.service';
import { S3Service } from '@app/shared/service/aws-bucket.service';
import { environment } from '@environment/environment';
//import { CartCategory, CartRequirement, CartRequiremnt } from '../models/cart.model';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  PROVISION_CATEGORY_ID = 61;
  cartInit = false;
  ship: Ship;
  //checkAllCategories = false;
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  loading: boolean;
  carts: any = [];

  //cartCategories: CartCategory[] = [];

  //uniqueCategory = [];
  //uniqueRequiremnt = [];


  category: any = [];
  categoryList: any = [];
  //staticCategoryList: any = [];
  order: Order[] = [];
  isCheckedCategory1 = [];
  isCheckedCategory = [];
  selectedCategory = [];
  selectedRequirement = [];
  selectedRequirement2 = [];
  //selectShipidDropdown = false;
  productDetail: Product;
  orderData: FormGroup;
  submitted = false;
  selectedRow: any;
  shipId: number;
  //srNo = 0;
  //moreItem = false;
  //separateDialCode = true;
  SearchCountryField = SearchCountryField;
  /* TooltipLabel = TooltipLabel; */
  CountryISO = CountryISO;
  //preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  eta;
  etd;
  minEta: any;
  portlist: any = [];
  allPortList: any = [];
  @ViewChild('productPopup', { static: false })
  productPopup: ProductPopupComponent;
  @ViewChild('editrequis', { static: false }) editrequis: TemplateRef<any>;
  showRemove = false;
  deleteCheckAll: boolean;
  deleteSelected: number[] = [];
  source = 'WEB';
  currencyDropdown = [];
  currency: string;
  orderName: string;
  Requisitions = [
    { headMessage: 'Requisitions Placed Successfully' },
    { secondMessage: 'Your Order for Requisitions has been Successfully Created.' }
  ];

  countryList;
  selectedCountry;
  PortList;
  role: number;
  priority: any[] = [
    { name: 'Urgent', value: 'Urgent' },
    { name: 'Normal', value: 'Normal' }
  ];

  @ViewChild('orderForm', { static: false }) orderForm: TemplateRef<any>;
  @ViewChild('removeReOrderItems', { static: false }) removeReOrderItems: TemplateRef<any>;
  shipList: any;
  page = 1;
  limit = 50;
  userRoles = UserRoles;
  today = new Date();
  selectedCategory2: any = [];
  @ViewChild('confirmation', { static: false }) confirmation;
  @ViewChild('confirmOrder', { static: false }) confirmOrder: TemplateRef<any>;
  @ViewChild('confirmReq', { static: false }) confirmReq: TemplateRef<any>;
  @ViewChild('selectReq', { static: false }) selectReq: TemplateRef<any>;
  isLoading: boolean;

  currencyCode: string;
  cartPortId: number;
  countryId: number;
  cartTotal: any = 0;
  provisionsSelected: boolean;
  canPlaceOrder = false;
  showloader = true;
  transportCharged = true;
  launchCharged = true;
  budgetData: any;
  processing = false;
  selectFile = false;
  userDetails: any;
  showDateFrom: any;
  showDateTo: any;
  carryForwarded: boolean;
  assigned_budget: any;
  loaderView: boolean;
  disableRemark = false;
  budget: any;
  nextMonthbudget: any
  allProductShow = true;
  totalOrderAmount: number = 0;
  rfqData: any = [];
  togglePA = true;
  togglePNA = true;
  countPNA: number;
  countPA: number;
  countRFQSubmitted: number;
  uploadForm: FormGroup;
  processing2 = false;
  file: File;
  uploading: boolean;
  showInvalidEmail = false;
  zeroItems: number;
  groupedCategory = [];
  cartRequirements = [];
  selectedMergeRequirement = [];
  backupSelectedMergeRequirement = [];
  tempSelectedRequirement = [];
  storeCategoryList = [];
  otherCategoryList = [];
  showNextPopup = false;
  showRequisitionForm = false;
  combinedReq = false;
  selectedReq;
  storeCatReqList;
  mergeReqCatList = [];
  provisionReqList = [];
  disable: boolean = false;
  selectedRequirementName: any;
  provisionsRequirement = [];
  requisitionId: number;
  requisitionName: string;
  exportReq: number;

  cartNewRequisitionForm: FormGroup;
  @ViewChild('cartNewRequirement') cartNewRequirement: TemplateRef<any>;
  requirementCategories = [];
  canPlaceReq: boolean;
  subAdminAssignedCompanyIds: [number];
  subAdminPrivilageIds: [number];
  privilages = Privilages;
  subAdminPrivilageAssigned = false;

  remarkForm: FormGroup;
  remarkModal: BsModalRef;
  supportModal: BsModalRef;
  noCombine: boolean = false;
  supportAttachements
  attached: number;
  attachementBy: string;
  supportDoc: string[] = [];
  emptyReq: boolean = true;
  assignedShips = [];
  //USER_Designation
  designation = Designation
  designationID: number;
  isCanNotPlaceOrder: boolean = false;
  onSubmitRequisition: boolean;
  dateOfEnquiry: Date;
  env;
  rewardPoints: any;
  rewardExpiryDate: any;
  selectedHour: number;
  selectedMinute: number;
  // catMostProd;

  //SELECT ALL ITEMS TO DELETE FROM THE CART
  selectAllToDelete(ischecked, selectedReq?) {
    this.carts.forEach((cart) => {
      if (selectedReq) {
        if (cart.requirement_id === selectedReq.requirement_id) {
          this.showRemove = true;
          this.selectItemToDelete(cart, ischecked);
        }
      } else {
        this.selectItemToDelete(cart, ischecked);
      }
    });
  }
  async removeFromCart(id, showToastr = true) {
    await this.cartService
      .removeFormCart(id)
      .then(res => {
        this.cartInit = false;
        if (showToastr) {
          this.toastr.info('Removed from Cart', 'Successfully',
            {
              closeButton: true,
              timeOut: 2000,
              positionClass: 'toast-bottom-left'
            });
          this.getCart(false);
        }
        this.category = [];
        //this.selectedRequirement = [];
      })
      .catch(err => {
        if (showToastr) {
          this.toastr.error(err.error, '',
            {
              closeButton: true,
              timeOut: 2000,
              positionClass: 'toast-bottom-left'
            });
        }
        this.processing = false;
      });
  }

  /* METHOD TO SELECT PRODUCTS TO DELETE */
  selectItemToDelete(cart, ischecked) {
    const index = this.deleteSelected.findIndex(
      x => (x == cart.id)
    );
    if (ischecked && index < 0) {
      if (this.deleteSelected.length < 400)
        this.deleteSelected.push(cart.id);
      // this.deleteCheck[i] = true;
    } else if (index >= 0) {
      this.deleteSelected.splice(index, 1);
      // this.deleteCheck[i] = false;
    }
    // uncheck select all
    if (this.carts.length === this.deleteSelected.length) {
      this.deleteCheckAll = true;
    } else {
      this.deleteCheckAll = false;
      this.emptyReq = true;
    }
  }

  constructor(
    private formDataToJsonService: FormDataToJsonService,
    private modalService: BsModalService,
    private cartService: CartService,
    private sharedService: SharedService,
    private router: Router,
    private formbuilder: FormBuilder,
    private shipService: ShipService,
    private toastr: ToastrService,
    private alertService: ToastrService,
    private authService: AuthenticationService,
    private rfqService: RfqService,
    private activatedRoute: ActivatedRoute,
    private categoryService: CategoryService,
    private datepipe: DatePipe,
    private loadingService: LoadingService,
    private commonService: CommonService,
    private altService: AlertService,
    private titlecase: TitleCasePipe,
    private s3Service: S3Service
  ) {
    this.env = environment
    this.getCountry();
    this.sharedService.shipIdSubject.subscribe(id => {
      if (id) {
        this.shipId = id;
      }
    });
    this.assignedShips = this.sharedService.getShipData().data.ship_ids
  }

  getUserData() {
    this.canPlaceOrder = this.authService.currentUserValue.data.user_details.can_place_order;
    this.canPlaceReq = this.authService.currentUserValue.data.user_details.can_place_req;
    this.currencyCode = this.authService.currentUserValue.data.user_details.company_details.currency_code;
    this.countryId = this.authService.currentUserValue.data.user_details.company_details.country_id;
  }

  ngOnInit() {
    this.remarkForm = this.formbuilder.group({
      brand_name: [null],
      pack_type: [null],
      remark: [null],
      brand_id: [null]
    });
    this.getUserData();
    this.getShip();
    this.getAllBrands();
    // this.getShipByID();
    // this.getCategoryList();
    this.orderData = this.formbuilder.group({
      agent_name: [''],
      agent_email: [''],
      mobile_prefix: [''],
      mobile: ['', Validators.minLength(4)],
      agent_address: [''],
      ship_id: [this.shipId, Validators.required],
      // order_name: ['', Validators.required],
      message: [''],
      country_id: ['', Validators.required],
      requirement_id: [this.selectedRequirement2, Validators.required],
      eta: ['', Validators.required],
      etd: ['', Validators.required],
      port_id: ['', Validators.required],
      priority_type: ['Normal', Validators.required],
      requirements: [this.selectedRequirement, Validators.required],
      source: [this.source],
      launch_charged: [null],
      transport_charged: [null],
      launch: [null],
      transport: [null],
      next_budget_cycle: [null],
      currency: [null],
      quotation_number: [''],
      date_of_enquiry: [null]
      // preferred_category_id:[null]
    });

    this.currencyDropdown = this.sharedService.currencyDropdown;
    this.uploadForm = this.formbuilder.group({
      ship_id: [null, Validators.required],
      requirement_id: [null, Validators.required],
      // category_id: [null, Validators.required],
      port_id: [null],
    });
    this.role = this.sharedService.getUserRole();
    this.designationID = this.sharedService.getDesignation()

    if (this.role == this.userRoles.ROLE_SUBADMIN) {
      this.subAdminAssignedCompanyIds = this.sharedService.getSubAdminsCompanies();
      this.subAdminPrivilageIds = this.sharedService.getSubAdminsPrivileges();
      this.orderData.get('date_of_enquiry').setValidators(Validators.required);
      this.orderData.updateValueAndValidity();
    } else {
      this.orderData.get('date_of_enquiry').clearValidators();
      this.orderData.updateValueAndValidity();
    }
    if (this.role === this.userRoles.ROLE_CAPTAIN && this.canPlaceOrder) {
      this.getBudgetAmount();
    }
    // this.getCart();
    this.getShipList();
    this.userDetails = this.sharedService.getUserDetails();
    // if (!this.shipId) {
    //   this.toastr.error('You can not place an order without ship');
    //   this.router.navigate(['/dashboard/catalogue']);
    // }

    // const currentUser  = this.authenticationService.currentUserValue;
    // // console.log(currentUser);

    // this.provisionsSelected = this.activatedRoute.snapshot.paramMap.get('provisions') != null;
    this.activatedRoute.queryParams.subscribe(
      p => {
        const newVal = p.provisions != null && this.canPlaceOrder;
        const provisionSelection = this.selectedRequirement;
        if (newVal != this.provisionsSelected) {
          this.provisionsSelected = newVal;
          this.category = [];
          //this.selectedRequirement = [];
          //this.cartRequirements = [];
          this.selectedRequirement = provisionSelection.filter(x => x.category_id != 61);
          this.selectAllToDelete(false);
          this.showRemove = false;
          if (this.provisionsSelected) {
            this.selectedRequirement = provisionSelection.filter(x => x.category_id == 61)
          }
        }
        this.cartInit = false;
        this.getCart();
        this.getCategory();
        this.createCartNewRequisitionForm();
      });
    if (this.subAdminPrivilageIds && this.subAdminPrivilageIds.length > 0) {
      this.subAdminPrivilageAssigned = this.subAdminPrivilageIds.includes(this.privilages.CREATE_REQUISITION)
    }
    if (this.role == this.userRoles.ROLE_USER && this.designationID in this.designation) {
      this.isCanNotPlaceOrder = true
    }
  }
  cycle: string = '';
  getBudgetAmount() {
    this.shipService.getBudgetByid(this.shipId).subscribe(data => {
      this.budgetData = data;
      if (this.budgetData) {
        this.budgetData.forEach(item => {
          if (item.id === 61 && item.budget != null && item.cycle_type == 'current') {
            this.budget = item.budget.toFixed(2);
            this.assigned_budget = item.assigned_budget;
            if ((new Date(item.validity_from).getMonth() + 1) == 12) {
              this.showDateFrom = (new Date(item.validity_from).getFullYear() + 1) + '-' + (new Date(item.validity_to).getMonth() + 1) + '-' + 1;
              this.showDateTo = (new Date(item.validity_to).getFullYear()) + '-' + '1' + '-' + '1';
            } else {
              this.showDateFrom = (new Date(item.validity_from).getFullYear()) + '-' + (new Date(item.validity_to).getMonth() + 1) + '-' + 1;
              this.showDateTo = (new Date(item.validity_to).getFullYear()) + '-' + (new Date(item.validity_from).getMonth() + 1) + '-' + (new Date(item.validity_from).getDate());
            }
            if (this.budgetData && this.budgetData.length > 0 && item.budget_type == 'monthly') {
              let SetDate = (new Date(item.validity_from).getMonth() + 1);
              if ((new Date(item.validity_from).getFullYear()) == (new Date().getFullYear() - 1)) {
                let LastDate: any = new Date(new Date(item.validity_from).getFullYear(), SetDate, 0);
                this.showDateFrom = (new Date(item.validity_from).getFullYear() + 1) + '-' + '1' + '-' + 1;
                this.showDateTo = (new Date(item.validity_to).getFullYear()) + '-' + '1' + '-' + LastDate.getDate();
              } else {
                let LastDate: any = new Date(new Date(item.validity_from).getFullYear(), SetDate + 1, 0);
                this.showDateFrom = (new Date(item.validity_to).getFullYear()) + '-' + (new Date(item.validity_from).getMonth() + 2) + '-' + 1;
                this.showDateTo = (new Date(item.validity_from).getFullYear()) + '-' + (new Date(item.validity_from).getMonth() + 2) + '-' + LastDate.getDate();
              }
            }
          } else if (item.id === 61 && item.budget != null && item.cycle_type == 'next') {
            this.nextMonthbudget = item.budget;
            this.cycle = item.cycle_type
          }
        });
      }
    });
  }

  getTotalOrderAmount(shipId) {
    this.cartService.totalOrderAmount(shipId).subscribe(data => {
      this.totalOrderAmount = data;
    });
  }

  private utcToLocal(date: Date): Date {
    const offsetMinutes = date.getTimezoneOffset(); // Get local timezone offset in minutes
    return new Date(date.getTime() - offsetMinutes * 60000); // Subtract offset to move to local time
  }
  /*  private localToUtc(date: Date): Date {
     return new Date(
       Date.UTC(
         date.getFullYear(),
         date.getMonth(),
         date.getDate(),
         date.getHours(),
         date.getMinutes(),
         date.getSeconds())
     );
   } */

  openPopup(product) {
    this.productDetail = product;
    this.productPopup.openModal();
  }
  setShip(ship) {
    this.shipId = ship.id;
    this.category = [];
    this.selectedCategory = [];
    // temp
    this.selectedCategory2 = [];
    this.selectedRequirement = [];
    this.selectedRequirement2 = [];
    localStorage.setItem('ship_id', this.shipId.toString());
    this.cartInit = false;
    this.getCart();
  }

  get oForm() {
    return this.orderData.controls;
  }

  get uploadForms() {
    return this.uploadForm.controls;
  }
  orderConfirm(confirmOrder, orderForm) {
    this.onSubmitRequisition = true;
    //this.mergeRequirement
    this.orderData.get('mobile_prefix').setValue(this.selectedCountry.isd_code);
    this.orderData.get('ship_id').setValue(this.shipId);
    // this.orderData.get('preferred_category_id').setValue(this.catMostProd);
    const currency = this.orderData.get('currency').value;
    if (currency) {
      this.orderData.get('currency').setValue(currency);
    }
    if (this.orderData.get('agent_email').value != null) {
      const lowerCaseEmail = this.orderData.get('agent_email').value.toLowerCase();
      this.orderData.get('agent_email').setValue(lowerCaseEmail);
    }

    if (!this.orderData.valid || this.showInvalidEmail) {
      this.submitted = true;
      this.onSubmitRequisition = false;
    } else {
      if (this.carryForwarded) {
        this.orderData.get('next_budget_cycle').setValue(this.carryForwarded);
      }
      // console.log(this.orderData.get('eta').value);
      // console.log(this.orderData.get('etd').value);
      const etaDate: Date = this.orderData.get('eta').value;
      const etdDate: Date = this.orderData.get('etd').value;
      this.orderData.get('eta').setValue(this.utcToLocal(etaDate));
      this.orderData.get('etd').setValue(this.utcToLocal(etdDate));
      if ((this.transportCharged || this.launchCharged) && this.provisionsSelected) {
        let tranportFee;
        let launchCharge;
        this.carts.forEach(item => {
          tranportFee = item.product.vendor[0].transport_fee;
          if (this.launchCharged) {
            launchCharge = item.product.vendor[0].launch_charge;
          }
        });
        this.orderData.get('transport_charged').setValue(this.transportCharged);
        this.orderData.get('transport').setValue(tranportFee);
        if (this.launchCharged) {
          this.orderData.get('launch_charged').setValue(this.launchCharged);
          this.orderData.get('launch').setValue(launchCharge);
        }
      } else {
        this.orderData.removeControl('transport_charged');
        this.orderData.removeControl('launch_charged');
        this.orderData.removeControl('transport');
        this.orderData.removeControl('launch');
      }

      // this.hideModal();
      this.modalRef.hide();
      this.modalRef = this.modalService.show(
        confirmOrder,
        Object.assign({ backdrop: 'static', class: 'gray modal-sm' })
      );
      this.altService.backModalClose(this.modalRef)
    }
  }

  /**
   * Place Requisition: This method used for create requisition.
   * After succesfull show toastr message for created requsition
   * Move to Requisition Listing.
   */
  placeRequisition() {
    this.checkSeperateORCombine();
    this.loading = true;
    this.loadingService.setLoading(true);
    if (!this.provisionsSelected) {
      this.orderData.get('currency').setValue(this.currencyCode);
    }
    if (this.orderData.get('date_of_enquiry').value) {
      const utcDate = this.orderData.get('date_of_enquiry').value;
      this.orderData.get('date_of_enquiry').setValue(utcDate);
    }
    else {
      const todayDate = new Date();
      const utcDate = todayDate;
      this.orderData.get('date_of_enquiry').setValue(utcDate)
    }
    this.cartService.placeOrder(this.orderData.value).subscribe((orderData: any) => {
      this.loading = false;
      this.loadingService.setLoading(false);
      this.modalRef.hide();
      this.showNextPopup = false;
      this.showRequisitionForm = false;
      this.getCart();
      setTimeout(() => {
        this.toastr.success('', orderData.data,
          {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-bottom-left'
          });
        this.router.navigate(['/requisitions']);
      });
    }, error => {
      this.loading = false;
      this.loadingService.setLoading(false);
      this.toastr.error(error.error, '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      this.showNextPopup = false;
      this.showRequisitionForm = false;
    });
  }

  /**
   * Placeorder : This method used for create order Directly.
   * This method used for Provision Cart after creation successfull its show toaster.
   * After created order it move on Order Listing.
   */
  placeOrder() {
    this.loading = true;
    this.loadingService.setLoading(true);
    this.checkSeperateORCombine();
    this.cartService.placeProductOrder(this.orderData.value).subscribe((orderData: any) => {
      this.loading = false;
      this.loadingService.setLoading(false);
      this.modalRef.hide();
      this.showNextPopup = false;
      this.showRequisitionForm = false;
      this.getCart();
      this.selcetedCartData = [];
      setTimeout(() => {
        this.toastr.success('', orderData.data,
          {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-bottom-left'
          });
        this.router.navigate(['/orders']);
        this.onSubmitRequisition = false;
      });
    }, error => {
      this.loading = false;
      this.loadingService.setLoading(false);
      this.toastr.error(error.error, '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      this.showNextPopup = false;
      this.showRequisitionForm = false;
      this.onSubmitRequisition = false;
    });
  }

  /**
   * Reset Order Formcontolname.
   */
  formReset() {
    this.orderData.reset();
  }
  uploadReq: string;
  openModal(template: TemplateRef<any>) {
    this.cartRequirements.forEach(x => this.uploadReq = `<b>${x.name}</b> ${x.category_name} <br/> Created By : <b>${x.creator_name} / ${x.rank}</b>`)
    if (!this.shipId) {
      this.toastr.error('Please select the ship first', '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      return;
    }
    if (this.canPlaceOrder && this.provisionsSelected) {
      this.getAllPorts();
    }
    this.modalRef = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', keyboard: false, class: 'gray modal-small' })
    );
    this.altService.backModalClose(this.modalRef);
  }

  getShip() {
    return this.sharedService.getShip();
  }

  getShipList() {
    const page = this.page;
    const limit = 1000;
    this.shipService.getAll(page, limit).subscribe(res => {
      this.shipList = res.results;
      this.shipList = this.shipList.filter((data) => {
        data.name = this.titlecase.transform(data.name)
        return true
      });
      if (this.role == this.userRoles.ROLE_SUBADMIN) {
        if (this.subAdminAssignedCompanyIds && this.subAdminAssignedCompanyIds.length > 0)
          this.shipList = this.shipList.filter(ship => this.subAdminAssignedCompanyIds.includes(ship?.company?.id))
      }
      if (this.role !== this.userRoles.ROLE_COMPANY_ADMIN && this.role !== this.userRoles.ROLE_SUBADMIN && this.role !== this.userRoles.ROLE_ADMIN) {
        this.shipList = this.shipList.filter(ship => {
          for (let i = 0; i < this.assignedShips.length; i++) {
            if (this.assignedShips[i].id == ship?.id)
              return true;
          }
          return false
        })
      }
    });
  }
  getShipByID() {
    this.shipService.getByid(this.shipId).subscribe(res => {
      this.ship = res;
    });
  }
  isUpdate = false;
  private getCart(showloader = true, currentRequirementId?: number) {
    try {
      if (showloader && !this.isUpdate) {
        this.loading = true;
        this.loadingService.setLoading(true);
      }
      this.isUpdate = false;
      this.getCartAllRequirements(this.shipId);
      // this.cartTotal = 0;
      this.processing = true;
      if (!this.provisionsSelected) {
        this.cartRequirements = [];
      }
      // this.carts = [];
      // this.category = [];
      // this.selectedCategory = [];
      // this.selectedCategory2 = [];
      this.cartService.getCart(this.shipId, this.provisionsSelected).subscribe(data => {
        this.carts = data.data;
        this.groupedCategory = data.grouped_categories.map(x => x.id);
        this.rewardPoints = data.reward_points;
        this.rewardExpiryDate = data.reward_expiry_date;
        this.carts.map(cart => {
          cart.ship_id = this.getShip();
        });
        /* if (this.carts && this.carts.length > 0 && this.carts.find(x => x.category && x.category.id == 0)) {
        this.checkQty();
        } */
        // category
        this.cartCalculation();
        /* this.carts.forEach(cart => {
          if (!this.cartInit) {
            this.category.push(cart.category);
            const requirementCatObj = {
              category_id: cart.requirement.category.id,
              category_name: cart.requirement.category.name,
              requirement_id: cart.requirement.id,
              name: cart.requirement.requirement_name,
              creator_name: cart.requirement.creator_name,
              rank: cart.requirement.rank
            }
            this.cartRequirements.push(requirementCatObj);
          }
          this.cartPortId = cart.port_id;
          if (this.role === this.userRoles.ROLE_CAPTAIN && this.provisionsSelected && iterate1 === 0) {
            iterate1 = 1;
            this.countryId = cart.port ? cart.port.country.id : null;
            setTimeout(() => {
              this.getPort(this.countryId);
            }, 3000);
          }
          console.log('cart', cart);
          if (cart.requirement_id == this.cartRequirements[0].requirement_id)
            if (cart.product.vendor.length && cart.product.vendor[0].price) {
              this.countPA++;
              this.cartTotal += (cart.quantity * cart.product.vendor[0].price);
              if (this.provisionsSelected && cart && cart.product.vendor && iterate === 0 && (this.transportCharged || this.launchCharged)) {
                iterate = 1;
                if (cart.product.vendor && cart.product.vendor[0].transport_fee && this.transportCharged) {
                  this.cartTotal += +cart.product.vendor[0].transport_fee;
                }
                if (cart.product.vendor && cart.product.vendor[0].launch_charge && this.launchCharged) {
                  this.cartTotal += +cart.product.vendor[0].launch_charge;
                }
              }
            } else {
              this.countPNA++;
              if (cart.product.rfq_submitted) {
                this.countRFQSubmitted++;
              }
            }
        }); */
        this.processing = false;
        // ref :  https://codeburst.io/javascript-array-distinct-5edc93501dc4

        if (!this.cartInit) {
          this.cartRequirements = Array.from(new Set(this.cartRequirements.map(x => x.requirement_id))).map(
            id => {
              return {
                requirement_id: id,
                category_id: this.cartRequirements.find(s => s.requirement_id === id).category_id,
                category_name: this.cartRequirements.find(s => s.requirement_id === id).category_name,
                name: this.cartRequirements.find(s => s.requirement_id === id).name,
                checked: true,
                order_name: '',
                count: this.carts.filter(cart => cart.requirement_id === id).length,
                creator_name: this.cartRequirements.find(s => s.requirement_id === id).creator_name,
                rank: this.cartRequirements.find(s => s.requirement_id === id).rank,
                update_on: this.cartRequirements.find(cart => cart.requirement_id === id).updated_on
              };
            }
          );
          this.category = Array.from(new Set(this.category.map(x => x.id))).map(
            id => {
              return {
                id,
                name: this.category.find(s => s.id === id).name,
                checked: true,
                count: this.carts.filter(cart => cart.category.id == id).length,
                requirements: this.cartRequirements.filter(req => req.category_id === id),
              };
            }
          );
          // this.category.sort(x=> !this.groupedCategory.includes(x.id));
          // console.log(this.category);
        }
        this.checkedAll();
        /* if (!this.provisionsSelected) {
        } */
        if (this.selectedCategory.length == 0) {
          this.checkedAll();
        }
        /* this.category = this.category.filter((item, i, ar) => {
          return ar.indexOf(item) === i;
        }); */
        this.cartInit = true;
        this.loading = false;
        this.loadingService.setLoading(false);
        this.emptyRequisition();
        if (this.provisionsSelected) {
          this.selectedRequirementName = currentRequirementId ?
            this.cartRequirements.find(x => x.requirement_id === currentRequirementId) : this.cartRequirements[0];
          this.selectProvisionRequsition(this.selectedRequirementName);
        }
      });
    } catch (e) {
      this.loading = false;
      this.loadingService.setLoading(false);
    }
  }
  emptyRequisition() {
    if (!this.provisionsSelected) {
      this.selectedMergeRequirement = [];
      this.selectedRequirement = [];
      this.selectedRequirement2 = [];
      this.mergeReqCatList = [];
      this.cartRequirements.map(x => {
        if (x.checked && !this.selectedRequirement2.includes(x.requirement_id)) {
          this.selectedRequirement.push(x);
          let tempVar = Number(x.requirement_id)
          if (isNaN(tempVar)) {
            x.requirement_id.forEach(element => {
              this.selectedRequirement2.push(element);
            });
          } else {
            this.selectedRequirement2.push(tempVar);
          }

        }
      });
    }
  }
  onSelectEta(event) {
    this.etd = null;
    this.minEta = this.commonService.verifyEta(event, this.eta, this.minEta);
  }
  //Chanfe date of enquiry
  onChangeDateofEnquiry(event, cal3) {
    const date = this.localToUtc(event);
    this.dateOfEnquiry = date;
    this.orderData.get('date_of_enquiry').setValue(event);
    this.setTimeFromDate(event)
    setTimeout(() => {
      cal3.overlayVisible = true;
    }, 0);
  }

  setSelectedDate(event) {
    if (this.etd < this.minEta) {
      this.etd = this.minEta;
    }
  }
  setSelectDateofEnquiry(event) {
    this.dateOfEnquiry = new Date(event.getFullYear(),
      event.getMonth(),
      event.getDate(),
      event.getHours(),
      event.getMinutes(),
      event.getSeconds()
    );
  }
  increaseVal(i) {
    this.processing = true;
    i.quantity++;
    this.addtocart(i);
  }
  decreaseVal(i) {
    if (i.quantity >= 0) {
      this.processing = true;
      if (i.quantity < 1) {
        i.quantity = 0;
      } else {
        i.quantity--;
      }
      if (i.quantity == 0) {
        this.removeFromCart(i.id);

      } else {
        this.addtocart(i);
      }
    }
  }

  updateCart(i) {
    if (i.quantity >= 0) {
      this.processing = true;
      if (i.quantity == 0) {
        this.removeFromCart(i.id);
      } else {
        this.addtocart(i);
      }
      this.toastr.success('Cart Updated', 'Successfully',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      // this.toastr.info('Successfully decrease the quantity');
    }
  }

  private getCountry() {
    // get Country
    this.sharedService.getCountry().subscribe(data => {
      this.countryList = [];
      data.forEach(item => {
        if (item.ports && item.ports.length > 0) {
          this.countryList.push(item);
        }
      });
      if (this.countryId && this.role === this.userRoles.ROLE_CAPTAIN && this.provisionsSelected) {
      } else {
        this.getPort(this.countryId);
      }
    });
  }

  /* async addtocart(item) {
    const payload = this.buildCartPayload(item);
    if (isNaN(payload.quantity)) {
      this.toastr.error('Please select a valid product quantity', 'Invalid quantity provided', {
        closeButton: true,
        timeOut: 2000,
        positionClass: 'toast-top-center'
      });
      this.processing = false;
      return;
    }
    if (payload.remark != '') {
      this.disableRemark = true;
    }
    await this.cartService
      .addToCart(payload)
      .then(res => {
        this.disableRemark = false;
        let iterate = 0;
        this.cartTotal = 0;
        this.carts.forEach(cart => {
          if (this.cartRequirements[0].requirement_id == cart.requirement_id)
            if (cart.product.vendor.length > 0 && cart.product.vendor[0].price) {
              this.cartTotal += (cart.quantity * cart.product.vendor[0].price);
              if (this.provisionsSelected && cart && cart.product.vendor && iterate == 0 && (this.transportCharged || this.launchCharged)) {
                iterate = 1;
                if (cart.product.vendor && cart.product.vendor[0].transport_fee && this.transportCharged) {
                  this.cartTotal += +cart.product.vendor[0].transport_fee;
                }
                if (cart.product.vendor && cart.product.vendor[0].launch_charge && this.launchCharged) {
                  this.cartTotal += +cart.product.vendor[0].launch_charge;
                }
              }
            }
        });
        this.toastr.success('Cart Updated', 'Successfully', {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-top-center'
        });
        this.processing = false;
        // this.getCart(false);
      })
      .catch(err => {
        this.disableRemark = false;
        this.processing = false;
        // error
      });
  } */

  confirmRemove(template: TemplateRef<any>, id, requirement) {
    this.selectedRow = id;
    this.selectedReq = requirement;
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-small confirmation-box new-modal' })
    );
    this.altService.backModalClose(this.modalRef)
  }

  displayModal(template: TemplateRef<any>, requirement) {
    this.selectedReq = requirement;
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-small confirmation-box new-modal' })
    );
    this.altService.backModalClose(this.modalRef)
  }

  remove() {
    const id = this.selectedRow;
    if (this.carts.length == 1) {
      this.deleteSelected = [];
      this.selectAllToDelete(false);
      this.removeMultiple(true, this.selectReq)
    } else {
      this.cartService
        .removeFormCart(id)
        .then(res => {
          this.cartInit = false;
          this.getCart();
          if (!this.provisionsSelected) {
            this.category = [];
            this.selectedRequirement = [];
          }
          this.hideModal();
          if (res) {
            this.toastr.success('Product removed from the cart successfully', '',
              {
                closeButton: true,
                timeOut: 2000,
                positionClass: 'toast-bottom-left'
              });
          }
        })
        .catch(err => {
          // error
        });
    }

  }
  removeMultiple(val = null, req?) {
    this.loading = true;
    this.loadingService.setLoading(true);
    this.selectedReq = req;
    if (val) {
      this.deleteSelected = [];
      this.selectAllToDelete(true);
    }
    let deleteRequirement = this.selectedReq && this.selectedReq.count === this.deleteSelected.length ? 1 : 0;
    this.cartService
      .removeFormCart(this.deleteSelected, deleteRequirement)
      .then(res => {
        this.cartInit = false;
        this.category = [];
        //this.selectedRequirement = [];
        this.getCart(true);
        if (res) {
          this.toastr.success(this.deleteSelected.length + ' product(s) removed from the cart successfully', '',
            {
              closeButton: true,
              timeOut: 2000,
              positionClass: 'toast-bottom-left'
            });
          this.showRemove = false;
          this.emptyReq = true
        }
      })
      .catch(err => {
        this.loading = false;
        this.loadingService.setLoading(false);
      });
  }

  removeZeroItems() {
    if (this.carts && this.carts.length > 0) {
      this.deleteSelected = [];
      this.carts.forEach(x => {
        let reqId = x.requirement_id;
        let req;
        this.selectedRequirement.forEach(element => {
          if (element.requirement_id == reqId && element.checked) {
            req = element;
            return;
          }
        });
        if (req != undefined) {
          if (x.quantity === 0) {
            this.deleteSelected.push(x.id);
          }
        }
      });
      this.removeMultiple(null);
    }
  }

  checkedCategory(ischecked, value) {
    this.category.map(x => {
      if (value.id == x.id) {
        x.checked = ischecked;
      }
    });

    if (ischecked) {
      if (this.selectedCategory2 && this.selectedCategory2.length > 0 && this.selectedCategory2.includes(value.id)) { } else {
        this.selectedCategory.push(value);
        // temp
        this.selectedCategory2.push(value.id);
      }
      if (this.selectedCategory.length == 1) {

      }
    }
    if (!ischecked) {
      const index = this.selectedCategory.findIndex(x => x.id == value.id);
      // temp
      const index2 = this.selectedCategory2.findIndex(x => x == value.id);
      if (index > -1) {
        //this.selectedCategory.splice(index, 1);
        // temp
        //this.selectedCategory2.splice(index2, 1);
      }
    }
    // console.log(this.selectedCategory, this.selectedCategory2);
  }

  checkedRequirement(ischecked, value) {
    this.cartRequirements.map(x => {
      if (value.requirement_id == x.requirement_id) {
        x.checked = ischecked;
      }
    });

    if (ischecked) {
      if (this.selectedRequirement2 && this.selectedRequirement2.length > 0 && this.selectedRequirement2.includes(value.requirement_id)) { } else {
        this.selectedRequirement.push(value);
        // temp
        let tempVar = Number(value.requirement_id)
        if (isNaN(tempVar)) {
          value.requirement_id.forEach(element => {
            this.selectedRequirement2.push(element);
          });
        } else {
          this.selectedRequirement2.push(tempVar);
        }
      }
    }
    if (!ischecked) {
      const index = this.selectedRequirement.findIndex(x => x.requirement_id == value.requirement_id);
      // temp
      const index2 = this.selectedRequirement2.findIndex(x => x == value.requirement_id);
      if (index > -1) {
        this.selectedRequirement.splice(index, 1);
        // temp
        this.selectedRequirement2.splice(index2, 1);
      }
    }
    // console.log(this.selectedRequirement, this.selectedRequirement2);
  }

  /* showRequirements(catRequirements, requirement) {
    if (catRequirements && catRequirements.length > 0) {
      if (catRequirements.find(x=> x.requirement_id === requirement.requirement_id)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } */

  placeOrderOpen(order, orderForm: TemplateRef<any>, isOrder = false) {
    // if(isOrder){
    //   let priceAvailable = true
    //   if(this.provisionsSelected){
    //     this.carts.forEach(cartItem=>{
    //       if(!cartItem.product.vendor.length || cartItem.product.vendor[0].price == null){
    //         priceAvailable = false;
    //         return;
    //       }
    //     })
    //   }
    //   if (!priceAvailable) {
    //     this.toastr.error('One or more item do not have prices available', 'Can not place order');
    //     return;
    //   }
    // }

    if (this.isReqEmpty()) {
      if (isOrder) {
        this.toastr.warning('Please enter Order name/ no. !', '',
          {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-bottom-left'
          });
        return;
      }
      this.toastr.warning('Please enter Requisition name/ no. !', '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      return;
    }

    if (this.provisionsSelected && this.countPNA != 0) {
      this.toastr.warning('Prices of all products are not available!', '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      return;
    }

    if (this.cartTotal > this.budget && isOrder) {
      this.carryForwarded = true;
      const budgetTotal = parseFloat(this.budget) + this.assigned_budget - this.totalOrderAmount;
      let res = budgetTotal - this.cartTotal;
      if (this.cycle == "next") {
        const budgetTotal = parseFloat(this.nextMonthbudget) - this.totalOrderAmount;
        res = budgetTotal - this.cartTotal;
      }
      if (res < 0) {
        this.toastr.error('Order total exceeds from next budget cycle amount.', '',
          {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-bottom-left'
          });
        return false;
      }
      if (this.provisionsSelected) {
        if (this.orderName) {
          this.placeProvisionOrder(orderForm);

        } else {
          this.toastr.error('Please Enter Order No.', '',
            {
              closeButton: true,
              timeOut: 2000,
              positionClass: 'toast-bottom-left'
            })
        }
      } else {
        this.placeProvisionOrder(orderForm)
      }
      /* suggested by vivek sir hide this popup - not now again exceed popup  */
      /*swal.fire({
        title: 'Exceeding Budget For ' + this.carts[0].category.name + '!',
        text: `You are exceeding the current budget, this order is for next budget cycle or period..?
        Your current remaining budget will be carry forwarded to the next cycle.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          // if (this.provisionsSelected) {
          //   if (this.orderName) {
          //     this.placeProvisionOrder(orderForm);
          //   } else {
          //     this.toastr.error('Please Enter Order No.')
          //   }
          // }
          this.carryForwarded = true;
          const budgetTotal = parseFloat(this.budget) + this.assigned_budget - this.totalOrderAmount;
          const res = budgetTotal - this.cartTotal;
          if (res < 0) {
            this.toastr.error('Order total exceeds from next budget cycle amount.', null, {
              disableTimeOut: true,
              tapToDismiss: true,
              closeButton: false,
            });
            return false;
          } else {
            this.placeProvisionOrder(orderForm);
          }
        } else {
          this.carryForwarded = false;
          return;
        }
      });*/
    } else {
      this.carryForwarded = false;
      if (this.provisionsSelected) {
        if (this.orderName) {
          this.placeProvisionOrder(orderForm);
        } else {
          this.toastr.error('Please Enter Order No.', '',
            {
              closeButton: true,
              timeOut: 2000,
              positionClass: 'toast-bottom-left'
            })
        }
      } else {
        this.placeProvisionOrder(orderForm);
      }
    }
  }
  storeList: boolean = false;
  placeProvisionOrder(orderForm) {
    this.zeroItems = this.zeroQuantityItems().length;
    if (this.zeroItems > 0) {
      this.modalRef = this.modalService.show(
        this.removeReOrderItems,
        Object.assign({}, { class: 'gray modal-md new-modal' })
      );
      this.altService.backModalClose(this.modalRef);
      // this.toastr.warning('Cart contains ' + zeroItems + ' item(s) of zero quantity, please update or remove them to proceed');
      // return;
    } else {
      if (this.selectedCategory.length > 0 && !this.isReqEmpty()) {
        //this.selectedCategory = this.category.filter(x => x.checked == true);
        this.selectedRequirement.forEach(x => { x.requirement_id = [x.requirement_id], x.name = [x.name] });

        //LUBES , PAINTS , PROVISION CATEGORY
        this.otherCategoryList = [];
        this.selectedCategory.filter(x => !this.groupedCategory.includes(x.id) && x.checked).forEach(val => this.otherCategoryList.push(Object.assign({}, val)))
        this.otherCategoryList.filter(x => { x.requirements = x.requirements.filter(item => item.checked === true) });
        this.otherCategoryList = this.otherCategoryList.filter(store => store && store.requirements.length > 0)
        if (this.otherCategoryList.find(x => x.id === this.PROVISION_CATEGORY_ID)) {
          const index = this.otherCategoryList.findIndex(x => x.id === this.PROVISION_CATEGORY_ID);
          this.otherCategoryList.unshift(this.otherCategoryList.splice(index, 1)[0]);
        }

        //STORES PRODUCT CATEGORY LIST
        this.storeCategoryList = [];
        this.selectedCategory.filter(x => this.groupedCategory.includes(x.id)).forEach(val => this.storeCategoryList.push(Object.assign({}, val)))
        this.storeCategoryList.filter(x => { x.requirements = x.requirements.filter(item => item.checked === true) });
        this.storeCategoryList = this.storeCategoryList.filter(store => store && store.requirements.length > 0);
        this.selectedCategory.forEach(item => {
          if (!this.provisionsSelected && item.id) {
            if ((this.storeCategoryList && this.storeCategoryList.length == 0) && this.otherCategoryList && this.otherCategoryList.length == 0) {
              this.toastr.warning('Please select at least one Requisition!', '',
                {
                  closeButton: true,
                  timeOut: 2000,
                  positionClass: 'toast-bottom-left'
                });
              this.modalRef.hide();
            } else {
              item.requirements.forEach(i => {
                if (this.otherCategoryList && this.otherCategoryList.length == 1) {
                  this.showNextPopup = this.otherCategoryList.some(item => item.requirements && item.requirements.length > 1) ? false : true;
                }
                if (this.otherCategoryList && this.otherCategoryList.length > 1) {
                  this.showNextPopup = this.otherCategoryList.some(item => item.requirements && item.requirements.length > 1) ? false : true;
                }
                if (this.storeCategoryList && this.storeCategoryList.length == 1) {
                  this.showNextPopup = this.storeCategoryList.some(i => i.requirements && i.requirements.length > 1) ? false : true;
                  if (this.otherCategoryList && this.otherCategoryList.length == 1 && this.showNextPopup == true) {
                    this.showNextPopup = this.otherCategoryList.some(item => item.requirements && item.requirements.length > 1) ? false : true;
                  }
                  this.storeCategoryList.forEach(i => this.storeList = i.requirements && i.requirements.length > 1 ? true : false);
                }
                if (this.storeCategoryList && this.storeCategoryList.length > 1) {
                  this.showNextPopup = false;
                  this.storeList = this.storeCategoryList.map(i => i.requirements && i.requirements.length > 1) ? true : false;
                }
              })
            }
          }
          if (this.provisionsSelected && (item.id || item.category_id) && item.requirements.length > 1) {
            this.provisionReqList = [...item.requirements];
            item.requirements.forEach(i => this.showNextPopup = i.requirement_id && i.requirement_id.length > 1 ? true : false);
            this.showRequisitionForm = false;
          } else if (this.provisionsSelected) {
            this.showNextPopup = true;
            this.showRequisitionForm = true;
          }
        })
        // MODAL FOR SELECT CATEGORY FOR PLACE ORDER
        if (this.storeCategoryList && this.storeCategoryList.length > 0 || this.otherCategoryList && this.otherCategoryList.length > 0) {
          this.modalRef = this.modalService.show(
            orderForm,
            Object.assign({ backdrop: 'static', class: 'gray modal-lg' })
          );
          this.altService.backModalClose(this.modalRef)
          this.formReset();
          this.orderData.get('priority_type').setValue(this.priority[1].value);
          this.orderData.get('mobile_prefix').setValue(this.selectedCountry?.isd_code)
        }
      } else {
        this.toastr.warning('Please select at least one Category!', '',
          {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-bottom-left'
          });
      }
    }
  }

  getPort(countryId) {
    this.portlist = [];
    if (countryId && this.countryList && this.countryList.length > 0) {
      const country = this.countryList.find(x => x.id === countryId);
      if (country) {
        this.selectedCountry = country;
        this.portlist = country.ports;
      }
    }
  }

  getAllPorts() {
    this.allPortList = [];
    if (this.countryList && this.countryList.length > 0) {
      this.countryList.forEach(item => {
        if (item && item.ports && item.ports.length > 0) {
          item.ports.forEach(i => this.allPortList.push(i));
        }
      });
    }
  }
  impaCat
  checkedAll(ischecked = true) {
    this.selectedCategory = [];
    this.selectedCategory2 = [];
    this.category.forEach((item, index) => {
      if (this.provisionsSelected) {
        this.isCheckedCategory[index] = item.id == this.PROVISION_CATEGORY_ID && ischecked;
        item.requirements = item.requirements.filter(i => {
          if (this.cartChange) {
            return i.requirement_id == this.cartRequirements[0].requirement_id;
          } else {
            return i.requirement_id == this.cartRequirements[0].requirement_id
          }
        })
        this.checkedCategory(this.isCheckedCategory[index], item);
        if (item.requirements && item.requirements.length > 0) {
          item.requirements.forEach(x => {
            x.checked = item.id == this.PROVISION_CATEGORY_ID && ischecked;
            this.checkedRequirement(x.checked, x);
          });
        }
      } else {
        this.isCheckedCategory[index] = item.requirements.some(x => x.checked = true) ? true : false;
        //this.isCheckedCategory[index] = ischecked;
        this.checkedCategory(this.isCheckedCategory[index], item);
        if (item.requirements && item.requirements.length > 0) {
          item.requirements.forEach(x => {
            x.checked = ischecked;
            this.checkedRequirement(ischecked, x);
          });
        }
      }
    });

    /* this.storeCategoryList = this.selectedCategory.filter(x => this.groupedCategory.includes(x.id));
    this.otherCategoryList = this.selectedCategory.filter(x => !this.groupedCategory.includes(x.id));
    this.selectedCategory = [...this.otherCategoryList, "IMPA Categories", ...this.storeCategoryList];
    this.selectedCategory.forEach((selectedCat, index) => {
      if (this.groupedCategory.find(x => selectedCat && selectedCat.id == x)) {
        this.impaCat = this.selectedCategory.splice(index - 1, 1);
        console.log(this.impaCat);
      }
    })

    console.log("store", this.selectedCategory) */
  }
  //MERGE STORE PRODUCT REQUIREMNT
  combineStore
  mergeRequirement(item, isChecked) {
    if (isChecked) {
      if (this.selectedMergeRequirement && this.selectedMergeRequirement.length > 0 && this.selectedMergeRequirement.find(x => x.category_id === item.category_id)) {
        this.selectedMergeRequirement.forEach(x => {
          if (x && x.category_id === item.category_id && !x.requirement_id.includes(item.requirement_id[0])) {
            x.requirement_id.push(item.requirement_id[0]);
            x.name.push(item.name[0]);
          }
        });
      } else {
        if (this.selectedMergeRequirement && this.selectedMergeRequirement.length > 0 && this.selectedMergeRequirement.find(x => this.groupedCategory.includes(x.category_id)) && this.groupedCategory.includes(item.category_id)) {
          this.selectedMergeRequirement.find(x => this.groupedCategory.includes(x.category_id)) ? console.log('truee') : console.log('falsee');
          // let count = 1;
          this.selectedMergeRequirement.forEach(x => {
            if (x && this.groupedCategory.includes(x.category_id) && this.groupedCategory.includes(item.category_id) && !x.requirement_id.includes(item.requirement_id[0])) {
              x.requirement_id.push(item.requirement_id[0]);
              x.name.push(item.name[0]);
            }
            /* else {
              if (x && (x.category_id !== item.category_id && x.requirement_id.includes(item.requirement_id[0]))) {
                if (count === 1 && this.groupedCategory.includes(x.category_id) && this.groupedCategory.includes(item.category_id) && !x.requirement_id.includes(item.requirement_id[0])) {
                  count = 0;
                  this.selectedMergeRequirement.push(item);
                } else {
                  this.selectedMergeRequirement.push(item);
                }
              }
            } */
          });
        } else {
          this.selectedMergeRequirement.push(Object.assign({}, item))
          // this.selectedMergeRequirement.push(item);
        }
      }
    } else {
      this.selectedMergeRequirement.filter((x, index) => {
        const findIndexId = x.requirement_id.indexOf(item.requirement_id[0])
        const findReqName = x.name.indexOf(item.name[0])
        if (findIndexId > -1) {
          this.selectedMergeRequirement[index].requirement_id.splice(findIndexId, 1);
        }
        if (findReqName > -1) {
          this.selectedMergeRequirement[index].name.splice(findReqName, 1)
        }
      });

      //this.selectedMergeRequirement.splice(this.selectedMergeRequirement.findIndex(x => x.requirement_id.includes(item.requirement_id[0])), 1);
      // this.backupSelectedMergeRequirement.splice(this.backupSelectedMergeRequirement.findIndex(x => x.requirement_id.includes(item.requirement_id[0])), 1);
    }

    if (!this.mergeReqCatList.find(x => x.category_id == item.category_id)) {
      this.selectedMergeRequirement.forEach(selectMerge => {
        if ((!this.mergeReqCatList.includes(item.category_id)) &&
          this.storeCategoryList.find(list => list.id == item.category_id)) {
          this.mergeReqCatList.push({ category_id: item.category_id, category_name: item.category_name });
        }
      })
      this.mergeReqCatList = this.mergeReqCatList.reduce((unique, o) => {
        if (!unique.some(obj => obj.category_id === o.category_id && obj.category_name === o.category_name)) {
          unique.push(o);
        }
        return unique;
      }, []);
      if (this.storeCategoryList.find(list => list.id == item.category_id)) {
        this.combineStore = this.mergeReqCatList[0].category_id;
      }
    }
  }
  //COMBINE REQUIREMENTS STORE PRODUCT LIST
  combineRequirements(combine) {
    if (combine) {
      if (this.selectedMergeRequirement && this.selectedMergeRequirement.length == 0 || (this.selectedMergeRequirement.find(x => x.requirement_id.length <= 1) != undefined)) {
        this.toastr.warning('Please select at least two Requisitions of a Category!', '',
          {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-bottom-left'
          });
        return false;
      }
      this.combinedReq = true;
      if (this.selectedMergeRequirement && this.selectedMergeRequirement.length > 0 &&
        this.selectedMergeRequirement.find(x => x.requirement_id && x.requirement_id.length > 1)) {
        this.selectedRequirement.forEach((item, i) => {

          if (item && (item.requirement_id && item.requirement_id.length > 1) ||
            (item.requirement_id && item.requirement_id.length == 1 &&
              this.selectedMergeRequirement.find(x => x.requirement_id.includes(item.requirement_id[0])))) {
            this.selectedMergeRequirement[0].order_name = this.selectedMergeRequirement[0].name[0];
          } else {
            // this.selectedMergeRequirement.push(item);
            this.selectedMergeRequirement.push(Object.assign({}, item))
          }
        });
        this.showNextPopup = true;
        this.selectedRequirement = [];
        this.selectedRequirement = this.selectedMergeRequirement;
      } else {
        this.selectedRequirement = [];
        this.selectedRequirement = this.selectedMergeRequirement;
        if (this.selectedRequirement.find(x => x.requirement_id && x.requirement_id.length > 0)) {
          this.selectedRequirement.forEach(x => { x.requirement_id = x.requirement_id[0], x.name = x.name[0] });
        }
      }
    } else {
      if (this.selectedRequirement.find(x => x.requirement_id && x.requirement_id.length > 0)) {
        this.selectedRequirement.forEach(x => { x.requirement_id = x.requirement_id[0], x.name = x.name[0] });
      }
      this.combinedReq = false;
      this.showNextPopup = true;
    }
  }
  //CREATE REQUISITION METHOD
  createRequisition(create) {
    this.orderData.patchValue({
      priority_type: this.priority[1].value,
      mobile_prefix: this.selectedCountry?.isd_code
    })
    if (create) {
      this.showRequisitionForm = true;
      // this.showNextPopup = false;
      if (this.combinedReq) {
        this.selectedMergeRequirement.forEach(selectReq => {
          if (!selectReq.name) {
            this.toastr.error('Requisition name is required for all categories !', '',
              {
                closeButton: true,
                timeOut: 2000,
                positionClass: 'toast-bottom-left'
              });
            this.showRequisitionForm = false;
          }
        })
        // this.orderData.get('requirements').setValue(this.selectedMergeRequirement);

      } else {
        this.selectedRequirement.forEach(selectedReq => {
          if (!selectedReq.name) {
            this.toastr.error('Requisition name is required for all Requisitions !', '',
              {
                closeButton: true,
                timeOut: 2000,
                positionClass: 'toast-bottom-left'
              });
            this.showRequisitionForm = false;
            this.showNextPopup = true;
          } else {
            this.showRequisitionForm = true;
          }
        })
        console.log("select req", this.selectedRequirement);
      }
    } else {
      this.showRequisitionForm = false;
    }
  }


  buildCartPayload(item) {
    return {
      product_id: item.product_id,
      id: item.id,
      remark: this.remarkForm.get('remark').value || item.remark,
      pack_type: this.remarkForm.get('pack_type').value || item.pack_type,
      brand_name: this.remarkForm.get('brand_name').value || item.brand_name,
      quantity: item.quantity,
      ship_id: this.shipId,
      category_id: item.category_id,
      port_id: item.category_id == this.PROVISION_CATEGORY_ID ? item.port_id : null,
      requirement_id: item.requirement.id,
    };
  }

  onReqNameChanged() { }

  isReqEmpty() {
    return this.category.some(x => x.order_name == '' && x.checked == true);
  }

  zeroQuantityItems() {
    const selectedCategories = this.selectedRequirement.filter(x => x.checked);
    let items = [];
    selectedCategories.forEach(cat => {
      const i = this.carts.filter(x => (x.category && x.category.id == cat.category_id) && (x.quantity == 0) && (x.requirement_id == cat.requirement_id));
      items = [...items, ...i];
    });
    return items;
  }

  showModal(template) {
    this.modalRef = this.modalService.show(
      template, Object.assign({ backdrop: 'static', keyboard: false })
    );
    this.altService.backModalClose(this.modalRef)
  }

  closeModalClicked(event) {
    this.modalRef.hide();
    if (event) {
      this.alertService.success('Product added successfully in your cart');
      this.cartInit = false;
      this.getCart();
    }
  }

  selectRfq(vendorId, productId, portId, i, isChecked, remark, id) {
    if (isChecked) {
      this.rfqPayloadBuilder(vendorId, productId, portId, remark, id);
      const vendorSelectedRfq = this.rfqData.filter(x => x.vendor_id == vendorId);
      // this.selectVendor(ischecked, vendor);
      // // console.log('vendor', vendorSelectedRfq);

      // if (
      //   vendorSelectedRfq.length ==
      //   this.orderDetails.length - vendor.price.length
      // ) {
      //   this.selectedAll[i] = true;
      // }
    } else {
      // this.selectedAll[i] = false;
      const index = this.rfqData.findIndex(x => (x.product_id = productId && x.vendor_id == vendorId));
      this.rfqData.splice(index, 1);
    }
  }
  rfqPayloadBuilder(vendorId, productId, portId, remark, id) {
    // this.vendors.map(x => (x.active = false));
    // this.selectVendor(true, vendor);
    const paylaod = {
      product_id: productId,
      vendor_id: vendorId,
      port_id: portId,
      remark: remark ? remark : '',
      currency: this.currencyCode === 'USD' ? 'USD' : this.currencyCode === 'INR' ? 'INR' : 'JPY',
      cart_id: id,
      requester_id: this.userDetails.user_id
    };
    this.rfqData.push(paylaod);
  }

  submitRFQ() {
    this.processing2 = true;
    this.rfqService.submitRfq(this.rfqData).subscribe(
      data => {
        this.toastr.success('RFQ submitted successfully', '',
          {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-bottom-left'
          });
        this.getCart();
        this.rfqData = [];
        this.processing2 = false;
        this.cartInit = false;
      }, error => {
        this.toastr.error(error.error, '',
          {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-bottom-left'
          });
        this.processing2 = false;
      }
    );
  }

  addLaunchCharge(event) {
    this.launchCharged = event;
    if (this.launchCharged) {
      this.cartTotal += +this.carts[0].product.vendor[0].launch_charge;
    } else {
      this.cartTotal = this.cartTotal - +this.carts[0].product.vendor[0].launch_charge;
    }
  }

  addTransportCharged(event) {
    this.transportCharged = event;
    if (this.transportCharged) {
      this.cartTotal += +this.carts[0].product.vendor[0].transport_fee;
    } else {
      this.cartTotal = this.cartTotal - +this.carts[0].product.vendor[0].transport_fee;
    }
  }

  hideModal() {
    this.orderData.get('eta').setValue(null);
    this.orderData.get('etd').setValue(null);
    this.modalRef.hide();
    this.showNextPopup = false;
    this.showRequisitionForm = false;
    this.combinedReq = false;
    this.emptyRequisition();
    if (this.selectedRequirement.find(x => x.requirement_id && x.requirement_id.length > 0)) {
      this.selectedRequirement.forEach(x => { x.requirement_id = x.requirement_id[0], x.name = x.name[0] });
    }
  }

  allRfqSubmitted() {
    return this.countRFQSubmitted == this.countPNA;
  }

  display_item(cart: any) {
    return !this.provisionsSelected ||
      (this.provisionsSelected && ((this.togglePNA && cart.product.vendor[0].price == null) || (this.togglePA && cart.product.vendor[0].price != null)))
  }

  uploadProducts() {
    this.submitted = true;

    if (!this.uploadForm.valid || this.file == null) {
      this.selectFile = false;
      return;
    }
    if (this.canPlaceOrder && this.provisionsSelected && this.uploadForm.value.port_id == null) {
      this.toastr.error('For COD orders please select the port', '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      return;
    }

    const formData = this.sharedService.convertModelToFormData(this.uploadForm.value);
    formData.append('file', this.file);
    this.uploading = true;
    this.cartService.upload(formData).subscribe(data => {
      this.modalRef.hide();
      const exportData = data.export_invalid_data;
      let html = '';
      let exports: any
      if (data.logs && data.logs.length > 0) {
        exports = "Export"
        data.logs.forEach((l, index) => {
          html += (index + 1) + '. ' + l + '<br>';
        });
        html = data.message + '<div style="text-align:left;font-size: smaller;margin-top: 20px; max-height: 120px; overflow: scroll;white-space: nowrap; padding: 4px; border-radius: 5px; background-color: #f3f3f3 "><b>Logs:</b><br>' + html + '</div>';
      }
      swal.fire({
        title: 'Products uploaded successfully!',
        html: html,
        width: 650,
        icon: 'success', heightAuto: false,
        showCancelButton: true,
        confirmButtonText: "Export",
        cancelButtonText: 'Ok'
      }).then((result) => {
        this.ngOnInit();
        if (result.isConfirmed) {
          const formData = new FormData();
          formData.append('invalid_sheet_data', JSON.stringify(exportData));
          this.cartService.exportInvalidProducts(formData).subscribe(res => {
            this.sharedService.saveAsBlob(res, 'application/vnd.ms-excel', 'invalid_product_list.xlsx');
          });
        }
      });
    }, error => {
      this.toastr.error(error.error, '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      this.uploading = false;
      this.submitted = false;
    }, () => {
      this.uploading = false;
      this.submitted = false;
    });
  }

  selectedfileName(files: any) {
    if (files.length > 0) {
      this.file = files[0];
      this.selectFile = true;
    }
  }

  productWithPrice(event) {
    if (event.target.id == 'price-avail') {
      this.allProductShow = false;
      this.togglePNA = false;
      this.togglePA = true;
    } else if (event.target.id == 'price-not-avail') {
      this.allProductShow = false;
      this.togglePA = false;
      this.togglePNA = true;
    } else {
      this.allProductShow = true;
      this.togglePA = true;
      this.togglePNA = true;
    }
  }

  checkEmail(event) {
    this.showInvalidEmail = false;
    if (event && event.target.value && !this.commonService.validateEmail(event.target.value)) {
      this.showInvalidEmail = true;
    }
  }

  exportCart() {
    this.loaderView = true;
    const fileName = 'cart-items: ' + `requisition- ${this.requisitionName}`;
    const type = 'xlsx';
    const mime = 'application/vnd.ms-excel';
    let isOrder = 0;
    if (this.provisionsSelected) {
      isOrder = 1;
    } else {
      isOrder = 0;
    }
    if (!this.requisitionId) {
      this.toastr.error('Please select requisition', '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      this.loaderView = false;
      return;
    } else {
      this.cartService.exportCart(this.shipId, isOrder, type, this.requisitionId).subscribe(data => {
        this.sharedService.saveAsBlob(data, mime, `${fileName}.${type}`);
        this.loaderView = false;
        this.modalRef.hide();
      }, error => {
        this.loaderView = false;
      });
    }

  }

  /* getCategory(event){
    this.catMostProd = event.id;
  }

  checkQty() {
        const catIds = this.carts.filter(x=>x.category.id==0).map(cart => cart.category_id);
        this.groupedCategory = this.groupedCategory.filter(x=> catIds.includes(x.id));
        if (catIds && catIds.length > 0) {
          let frequency = {};
          let max = 0;
          let result;
          for(let v in catIds) {
              frequency[catIds[v]]=(frequency[catIds[v]] || 0)+1;
              if(frequency[catIds[v]] > max) {
                max = frequency[catIds[v]];
                result = catIds[v];
              }
          }
          this.catMostProd = result;
        }
  } */

  trackByFn(index, item) {
    return index;
  }

  changeRequirementCategory(event, req) {
    this.selectedMergeRequirement.forEach((mergeReqCat, index) => {
      if (mergeReqCat.requirement_id && mergeReqCat.requirement_id.length > 1 && this.selectedMergeRequirement[index].category_id == req.category_id) {
        this.selectedMergeRequirement[index].category_id = event.category_id;
        this.selectedMergeRequirement[index].category_name = event.category_name;
      }
    })
  }

  selectAllItems() {
    this.showRemove = true;
    this.selectAllToDelete(true, this.selectedReq);
    this.modalRef.hide();
  }

  selectedReqRemove(reqId?) {
    if (this.selectedReq) {
      if (reqId.requirement_id == this.selectedReq.requirement_id) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  mergeReq(req) {
    if (req.requirement_id && req.requirement_id.length > 1 && this.mergeReqCatList.some(i => i.category_id == req.category_id)) {
      return true;
    } else {
      return false;
    }
  }
  selcetedCartData = []
  selectProvisionRequsition(event) {
    this.selcetedCartData = [];
    this.orderName = '';
    this.cartTotal = 0;
    if (this.provisionsSelected) {
      this.selectedRequirement = [];
      this.selectedRequirement2 = [];
      this.category.forEach((item, index) => {
        this.isCheckedCategory[index] = item.id == this.PROVISION_CATEGORY_ID;
        item.requirements = [event];
        this.selcetedCartData.push(event);
        this.selectedRequirement.push(event);
        this.selectedRequirement2.push(event.requirement_id)
        if (this.provisionsSelected && item.id == 61 && event.category_id == 61) {
          this.selectedCategory = [];
          this.selectedCategory2 = [];
          this.checkedCategory(this.isCheckedCategory[index], item);
        }
        this.cartCalculation(event);
        this.cartChange = false;
      })
    }
  }
  cartChange = true;
  async cartCalculation(item?) {
    this.countPA = 0;
    this.countPNA = 0;
    this.countRFQSubmitted = 0;
    let iterate = 0;
    let iterate1 = 0;
    this.cartTotal = 0;
    this.carts.forEach(cart => {
      if (!this.cartInit) {
        if (this.category.filter(x => x.id == cart.category.id).length > 0) {
        } else {
          if (this.provisionsSelected) {
            if (cart.category.id == this.PROVISION_CATEGORY_ID) {
              this.category.push(cart.category);
            }
          } else {
            this.category.push(cart.category);
          }

        }

        const requirementCatObj = {
          category_id: cart.requirement.category.id,
          category_name: cart.requirement.category.name,
          requirement_id: cart.requirement.id,
          name: cart.requirement.requirement_name,
          creator_name: cart.requirement.creator_name,
          rank: cart.requirement.rank,
          updated_on: cart.requirement.updated_on
        }
        this.cartRequirements.push(requirementCatObj);
      }
      this.cartPortId = cart.port_id;
      if (this.role === this.userRoles.ROLE_CAPTAIN && this.provisionsSelected && iterate1 === 0) {
        iterate1 = 1;
        this.countryId = cart.port ? cart.port.country.id : null;
        setTimeout(() => {
          this.getPort(this.countryId);
        }, 3000);
      }
      if ((item && item.requirement_id || this.cartRequirements && this.cartRequirements[0].requirement_id) == cart.requirement_id)
        if (cart.product.vendor.length && cart.product.vendor[0].price) {
          this.countPA++;
          this.cartTotal += (cart.quantity * +cart.product.vendor[0].price);
          if (this.provisionsSelected && cart && cart.product.vendor && iterate === 0 && (this.transportCharged || this.launchCharged)) {
            iterate = 1;
            if (cart.product.vendor && cart.product.vendor[0].transport_fee && this.transportCharged) {
              this.cartTotal += +cart.product.vendor[0].transport_fee;
            }
            if (cart.product.vendor && cart.product.vendor[0].launch_charge && this.launchCharged) {
              this.cartTotal += +cart.product.vendor[0].launch_charge;
            }
          }
        } else {
          this.countPNA++;
          if (cart.product.rfq_submitted) {
            this.countRFQSubmitted++;
          }
        }
    });
  }
  async addtocart(item) {
    this.cartTotal = 0;
    const payload = this.buildCartPayload(item);
    if (isNaN(payload.quantity)) {
      this.toastr.error('Please select a valid product quantity', 'Invalid quantity provided',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      this.processing = false;
      return;
    }
    if (payload.remark != '') {
      this.disableRemark = true;
    }
    await this.cartService
      .addToCart(payload)
      .then(res => {
        this.disableRemark = false;
        this.remarkForm.reset();
        this.isUpdate = true;
        let iterate = 0;
        this.cartTotal = 0;
        this.carts.forEach(cart => {
          if (!this.cartChange && this.selcetedCartData && this.selcetedCartData.length > 0 && this.selcetedCartData[0].requirement_id == cart.requirement_id) {
            if (cart.product.vendor.length > 0 && cart.product.vendor[0].price) {
              this.cartTotal += (cart.quantity * +cart.product.vendor[0].price);
              if (this.provisionsSelected && cart && cart.product.vendor && iterate == 0 && (this.transportCharged || this.launchCharged)) {
                iterate = 1;
                if (cart.product.vendor && cart.product.vendor[0].transport_fee && this.transportCharged) {
                  this.cartTotal += +cart.product.vendor[0].transport_fee;
                }
                if (cart.product.vendor && cart.product.vendor[0].launch_charge && this.launchCharged) {
                  this.cartTotal += +cart.product.vendor[0].launch_charge;
                }
              }
            }
          } else if (this.cartChange && this.cartRequirements[0].requirement_id == cart.requirement_id) {
            if (cart.product.vendor.length > 0 && cart.product.vendor[0].price) {
              this.cartTotal += (cart.quantity * +cart.product.vendor[0].price);
              if (this.provisionsSelected && cart && cart.product.vendor && iterate == 0 && (this.transportCharged || this.launchCharged)) {
                iterate = 1;
                if (cart.product.vendor && cart.product.vendor[0].transport_fee && this.transportCharged) {
                  this.cartTotal += +cart.product.vendor[0].transport_fee;
                }
                if (cart.product.vendor && cart.product.vendor[0].launch_charge && this.launchCharged) {
                  this.cartTotal += +cart.product.vendor[0].launch_charge;
                }
              }
            }
          }
        });
        this.toastr.success('Cart Updated', 'Successfully',
          {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-bottom-left'
          });
        this.processing = false;
        this.ngOnInit()
        // this.getCart(false);
      })
      .catch(err => {
        this.disableRemark = false;
        this.processing = false;
        // error
      });
  }
  creatorRank = [];
  cartRequirementList = []
  getCartAllRequirements(shipId: number) {
    if (this.shipId != undefined) {
      this.cartService.getCartAllRequirements(this.shipId).subscribe((res) => {
        this.creatorRank = res.ranks.map(rank => rank);
        if (res && res.data.length > 0) {
          this.cartRequirementList = res.data;
          if (this.provisionsSelected) {
            this.cartRequirementList = this.cartRequirementList.filter(i => i.category_id == 61)
          } else if (!this.canPlaceOrder && this.canPlaceReq) {
            this.cartRequirementList = this.cartRequirementList
          } else {
            this.cartRequirementList = this.cartRequirementList.filter(i => i.category_id != 61)
          }
        } else {
          this.cartRequirementList = null;
        }
      }, error => {
        this.cartRequirementList = null;
      });
    }
  }
  selectRequisition() {
    //this.exportReq = this.selectedRequirement[0].requirement_id;
    //this.selectedRequirement.forEach(x => this.exportReq = `<b>${x.name}</b> ${x.category_name} <br/> Created By : <b>${x.creator_name} / ${x.rank}</b>`)
    this.modalRef = this.modalService.show(
      this.selectReq,
      Object.assign({ backdrop: 'static', class: 'gray modal-md' })
    );
  }
  changeRequirement(event) {
    this.requisitionId = event.requirement_id;
    this.requisitionName = event.name;
  }

  /* changeUploadReq(event) {
    //this.uploadForm.get('requirement_id').setValue(event.id);
  } */
  editRequisition(requirement) {
    this.modalRef = this.modalService.show(
      this.editrequis,
      Object.assign({ backdrop: 'static', class: 'gray modal-sm' })
    );
    this.altService.backModalClose(this.modalRef)
  }

  createCartNewRequisitionForm() {
    this.cartNewRequisitionForm = this.formbuilder.group({
      requirement_category_id: [null, Validators.required],
      requirement_name: [null, Validators.required],
      creator_name: ['', Validators.required],
      rank: [null, Validators.required],
      product_id: [''],
      quantity: [''],
      category_id: [''],
      port_id: [''],
      remark: [''],
    });
  }
  get Rform() {
    return this.cartNewRequisitionForm.controls;
  }
  createCartRequirement() {
    this.submitted = false;
    if (this.shipId) {
      this.submitted = true;
      if (!this.cartNewRequisitionForm.valid) {
        return false;
      } else {
        this.loading = true;
        const formData = new FormData()
        formData.append('requirement_category_id', this.cartNewRequisitionForm.get('requirement_category_id').value);
        formData.append('requirement_name', this.cartNewRequisitionForm.get('requirement_name').value);
        formData.append('creator_name', this.cartNewRequisitionForm.get('creator_name').value);
        formData.append('rank', this.cartNewRequisitionForm.get('rank').value);
        const json = this.formDataToJsonService.convertFormDataToJson(formData);
        this.cartService.createCartRequirements(this.shipId, json).subscribe((res: any) => {
          if (res) {
            this.submitted = false;
            this.loading = false;
            this.modalRef2.hide();
            this.toastr.success('New Cart Requisition Successfully!', '',
              {
                closeButton: true,
                timeOut: 2000,
                positionClass: 'toast-bottom-left'
              });
            this.cartNewRequisitionForm.reset();
            this.getCartAllRequirements(this.shipId);
          }
        }, error => {
          this.loading = false;
          this.toastr.error('Something went wrong! Try again.', '',
            {
              closeButton: true,
              timeOut: 2000,
              positionClass: 'toast-bottom-left'
            });
        });
      }
    } else {
      this.toastr.error('No Ship Found!', '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
    }
  }
  openNewRequirementPopup() {
    if (!this.shipId) {
      this.alertService.error('Please select ship');
      return;
    }
    this.cartNewRequisitionForm.reset();
    this.modalRef2 = this.modalService.show(
      this.cartNewRequirement,
      Object.assign({ backDrop: 'static', class: 'gray modal-md' })
    );
    this.altService.backModalClose(this.modalRef2);
    this.submitted = false;
    this.checkSelectCategory();
  }
  checkSelectCategory() {
    if (this.role === this.userRoles.ROLE_CAPTAIN && this.canPlaceOrder && !this.canPlaceReq) {
      this.requirementCategories = this.categoryList.filter(cat => cat.id === this.PROVISION_CATEGORY_ID);
    } else if (!this.canPlaceOrder && this.canPlaceReq) {
      this.requirementCategories = this.categoryList
    } else {
      if (this.provisionsSelected) {
        this.requirementCategories = this.categoryList.filter(cat => cat.id === this.PROVISION_CATEGORY_ID);
      }
      else {
        this.requirementCategories = this.categoryList.filter(cat => cat.id !== this.PROVISION_CATEGORY_ID);
      }
    }
  }
  cancelRequirement() {
    this.modalRef2.hide();
    this.submitted = false;
    this.processing = false;
  }
  getCategory() {
    const page = this.page;
    this.categoryService.getAllV2(page, 20).subscribe((data: any) => {
      data.data.map(x => { x.active = false; });
      this.categoryList = data.data;
      this.requirementCategories = data.data;
    });
  }
  assignedCompany() {
    if (this.role == this.userRoles.ROLE_CAPTAIN) {
      return true;
    } else if (this.role == this.userRoles.ROLE_COMPANY_ADMIN || this.role == this.userRoles.ROLE_USER) {
      return true
    } else if (this.subAdminAssignedCompanyIds && this.subAdminAssignedCompanyIds.length > 0 || this.role == this.userRoles.ROLE_ADMIN) {
      return true;
    } else {
      return false
    }
  }
  productDetails;
  rowIndex
  openRemarkModal(template: TemplateRef<any>, product, index) {
    this.productDetails = product;
    if (product.brand_name) {
      this.remarkForm.patchValue({ brand_name: product.brand_name });
    }
    if (product.pack_type) {
      this.remarkForm.patchValue({ pack_type: product.pack_type });
    }
    if (product.remark) {
      this.remarkForm.patchValue({ remark: product.remark });
    }

    if (!product.brand_name && !product.pack_type && !product.remark) {
      this.remarkForm.reset()
    }
    this.rowIndex
    this.remarkModal = this.modalService.show(
      template, Object.assign({ backdrop: 'static', class: 'catalogue gray modal-md' })
    )
  }
  brands
  getAllBrands() {
    this.sharedService.getAllBrands().subscribe(res => {
      if (res) {
        this.brands = res
      }
    })
  }
  changeRemark(event) {
    this.remarkForm.get('brand_id').patchValue(event.id);
    this.remarkForm.get('brand_name').patchValue(event.name);
  }

  supportAttachementModal(template: TemplateRef<any>, supportdata, attachedBy) {
    this.attached = attachedBy
    this.supportAttachements = supportdata;
    if (attachedBy == 1) {
      this.attachementBy = `Vendor's Attachments`
      this.supportDoc = this.supportAttachements.product.vendor[0].supporting_documents.split(', ');
    }
    if (attachedBy == 2) {
      this.attachementBy = `Vessel's Attachments`
    }
    this.supportModal = this.modalService.show(
      template, Object.assign({ backdrop: 'static', class: 'catalogue gray modal-md' })
    )
  }

  async downloadVesselAttachement(file, attachedBy) {
    switch (true) {
      case attachedBy == 2:
        const img1 = await this.s3Service.getS3Object(this.env.bucket, file).then(res => res);
        window.open(img1)
        break;
      case attachedBy == 1:
        const img = await this.s3Service.getS3Object(this.env.bucket, this.sharedService.downloadAttachement(file)).then(res => res);
        window.open(img)
        break;
      default:
        return
    }
  }
  checkSeperateORCombine() {
    if (this.provisionsSelected) {
      this.orderData.value.requirements[0].order_name = this.orderName;
    } else {
      this.orderData.value.requirements = this.orderData.value.requirements.map(req => {
        if (!this.noCombine && req.requirement_id.length > 1) {
          return {
            ...req,
            requirement_id: req.requirement_id,
            order_name: req.name[0]
          }
        } else {
          if (this.noCombine) {
            return {
              ...req,
              requirement_id: [req.requirement_id],
              order_name: req.order_name = req.name
            }
          } else {
            return {
              ...req,
              requirement_id: req.requirement_id,
              order_name: req.order_name = req.name[0]
            }
          }

        }
      })





      /*  if (this.combinedReq) {
         this.orderData.value.requirements[0].order_name = this.orderData.value.requirements[0].name[0]
       } else {
         if (this.noCombine) {
           this.orderData.value.requirements = this.orderData.value.requirements.map(obj => {
             return {
               ...obj,
               requirement_id: [obj.requirement_id],
               order_name: obj.order_name = obj.name
             };
           })
         }
       } */
    }
  }

  changeQuotationNUmber(event) {
    this.orderData.patchValue({ quotation_number: event.target.value.toString() });
  }

  async priscribeFormat() {
    const img = await this.s3Service.getS3Object(this.env.bucket, 'static/file/cart_upload_format.xlsx').then(res => res);
    window.open(img, '_blank');
  }


  //Validate time in Hour and Minute
  validateTimeInput(event: any, type: 'hour' | 'minute' | 'second'): void {
    const value = event.target.value;
    const numValue = parseInt(value, 10);
    if (type == "hour" && (numValue > 0 || numValue < 23)) {
      this.selectedHour = Math.max(0, Math.min(23, numValue));
      event.target.value = this.selectedHour;
    }
    if ((type == 'minute' || type == 'second') && (numValue > 0 || numValue < 59)) {
      if (type == 'minute') {
        this.selectedMinute = Math.max(0, Math.min(59, numValue));
        event.target.value = this.selectedMinute;
      }
    }
  }

  //Apply Custom date on date of enquiry
  applyCustomTime(): void {
    const selectedDate = this.orderData.get('date_of_enquiry').value;
    if (selectedDate && this.selectedHour !== null && this.selectedMinute !== null) {
      // Append custom time to the selected date
      const updatedDate = new Date(selectedDate); // Clone the selected date
      updatedDate.setHours(this.selectedHour);
      updatedDate.setMinutes(this.selectedMinute);
      updatedDate.setSeconds(0); // Optional, set seconds to 0
      // Update the FormControl with the new date and time
      this.dateOfEnquiry = updatedDate;
      this.orderData.get('date_of_enquiry').setValue(this.dateOfEnquiry)
    }
  }

  setTimeFromDate(date: Date): void {
    const parsedDate = new Date(date);
    this.selectedHour = parsedDate.getHours();
    this.selectedMinute = parsedDate.getMinutes();
  }

  /* private utcToLocal(date: Date): Date {
    // tslint:disable-next-line:max-line-length
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
  } */

  private localToUtc(date: Date): Date {
    const offsetMinutes = date.getTimezoneOffset(); // Get local timezone offset in minutes
    return new Date(date.getTime() - offsetMinutes * 60000); // Add offset to move to UTC
  }

  // private localToUtc(date) {
  //   return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  // }

  keepCalendarOpen(cal3: any) {
    // Manually keep the calendar open
    return cal3.overlayVisible = true;
  }
}
