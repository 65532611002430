<div class="catelogue-container">
    <div class="row">
        <div class="col-md-12">
            <div class="catalogue-dropdown">
                <div class="shipname rmargin10" *ngIf="role != userRoles.ROLE_CAPTAIN">
                    <label>Select Ship<span class="mendatory">*</span></label>
                    <ng-select [items]="shipList" bindLabel="name" [(ngModel)]="shipId" (change)="setShip($event)" bindValue="id" [multiple]="false" placeholder="Select Ship" class="form-control" clearAllText="Clear" [clearable]="false">
                    </ng-select>
                </div>
                <!-- <div class="shipname rmargin10" [style.height.px]="'71'">
                    <label>Select Country<span class="mendatory">*</span></label>
                    <ng-select [items]="countryList" bindLabel="name" [(ngModel)]="countryId" bindValue="id" [multiple]="false" [placeholder]="loading2 ? 'Loading...' : 'Select Country'" class="form-control" style="height: 43px;" clearAllText="Clear" [clearable]="true" (change)="selectedCountry(countryId)">
                    </ng-select>
                    <small><a href="javascript:void(0)" (click)="onShowingGlobalProducts()">Show Global Products</a></small>
                </div>-->
                <!-- <div class="shipname rmargin10" *ngIf="selectedCategory == PROVISION_CATEGORY_ID">               
                    <label>Select Port of Delivery<span class="mendatory">*</span></label>
                    <ng-select [items]="portList" bindLabel="name" [(ngModel)]="portId" bindValue="id" [multiple]="false" [placeholder]="loading2 ? 'Loading...' : 'Select Port'" class="form-control" style="height: 43px;" clearAllText="Clear" [clearable]="true" [loading]="loading2" (change)="changePort(portId)">
                    </ng-select>
                </div>  -->
                <div class="shipname rmargin10" *ngIf="shipId" [style.height.px]="'71'">
                    <label>Select Requisition Name<span class="mendatory">*</span></label>
                    <ng-select (change)="changeRequirement($event)" [(ngModel)]="selectedRequirementName" [multiple]="false" placeholder="Select Requisition" class="form-control select-requirement" style="height: 43px;" [searchable]="false">
                        <ng-option *ngFor="let item of requirementsDropdownDataSource" [value]="item"><b><span [title]="item?.requirement_name" class="requirement-name-span">{{item?.requirement_name}}</span> |</b> {{item?.category?.name}} <br> <em>Created By: </em> <b>{{item?.creator_name? item?.creator_name: ''}} / {{item?.rank? item?.rank: ''}}</b></ng-option>
                    </ng-select>
                    <small>(Items will be added to this requisition in the cart)</small>
                </div>
                <div class="shipname" *ngIf="canPlaceOrder && role == userRoles.ROLE_CAPTAIN && selectedCategory == PROVISION_CATEGORY_ID  || canPlaceOrder && role == userRoles.ROLE_CAPTAIN && !selectedCategory">
                    <label>Select Port of Delivery<span class="mendatory">*</span></label>
                    <ng-select [items]="portList" bindLabel="name" [(ngModel)]="portId" bindValue="id" [multiple]="false" [placeholder]="loading2 ? 'Loading...' : 'Select Port'" class="form-control" style="height: 43px;" clearAllText="Clear" [clearable]="false" [loading]="loading2" (change)="changePort(portId)">
                    </ng-select>
                </div>

                <div class="shipname create">
                    <button type="button" class="btn btn-primary reqQuotation float-right" (click)="openNewRequirementPopup()" *ngIf="role == userRoles.ROLE_CAPTAIN || role == userRoles.ROLE_COMPANY_ADMIN ||role ==userRoles.ROLE_ADMIN || role ==userRoles.ROLE_USER || subAdminRequisitionRights">Create New Requisition</button>
                </div>
            </div>
            <br>
            <hr [style.margin-top.px]="'25'">
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <div class="bmargin10 searchin-box"
                        *ngIf="clearAll && !pageLoading">
                        <label class="search-category"><span *ngIf="!canPlaceReq && canPlaceOrder; else allCategories">{{
                                categoryName ?
                                categoryName : 'All Products' }}</span> <ng-template #allCategories><span>{{ categoryName ?
                                    categoryName
                                    : 'All Categories' }}</span></ng-template> <span class="search-category"
                                *ngIf="categoryName">/ {{ subCategoryName }}</span><span class="search-result"> / {{total >= 10000 ?
                                    total : total}}
                                Products</span></label>
                    </div>
                    <div class="search-categories search-field">
                        <form [formGroup]="searchForm">
                            <div class="searchbox"
                                [class.showCross]="selectedAutocomplete">
                                <input type="hidden"
                                    class=""
                                    [(ngModel)]="selectedSubCategory"
                                    formControlName="category_id">
            
                                <div [ngClass]="{error : submitted && form.search.errors}">
                                    <div class="ng-autocomplete">
                                        <p-autoComplete class="long-search"
                                            formControlName="search"
                                            placeholder="Search any items by name or code"
                                            [suggestions]="suggestion"
                                            (keyup)="showCrossBtn($event)"
                                            (completeMethod)="search($event)"
                                            (keyup.enter)="searchProduct()"
                                            (onSelect)="doOnSelect($event)">
                                            <ng-template let-suggestion
                                                let-i="index"
                                                pTemplate="item"
                                                styleClass="wid100">
                                                <div class="search-product ui-helper-clearfix"
                                                    style="border-bottom:1px solid #D5D5D5;width: 500px;">
                                                    <p class="code">{{suggestion.name | titlecase}}</p>
                                                    <p *ngIf="suggestion && suggestion?.unit"
                                                        class="code">Unit: <strong>{{suggestion && suggestion.unit ? suggestion.unit
                                                            :
                                                            ''}}</strong></p>
                                                    <p *ngIf="(suggestion && suggestion?.pack_type)"
                                                        class="code">Pack Type:<strong> {{suggestion.pack_type |
                                                            titlecase}}</strong>
                                                    </p>
                                                    <p *ngIf="(suggestion && suggestion?.brand_name)"
                                                        class="code">Brand:<strong> {{suggestion?.brand_name | titlecase}}</strong>
                                                    </p>
                                                    <p class="code impa">{{suggestion.product_code || 'N/A'}}, IMPA:
                                                        {{suggestion.impa_code || 'N/A'}}</p>
                                                </div>
                                                <div *ngIf="suggestion.length == i-1">
                                                    <button>View All</button>
                                                </div>
                                            </ng-template>
                                        </p-autoComplete>
                                    </div>
                                </div>
                                <div class="search-btns">
                                    <button class="find-btn cross-btn cat"
                                        (click)="resetForm()"
                                        type="button">&times;</button>
                                    <button class="find-btn cat"
                                        type="button"
                                        (click)="searchProduct()"><span class="fa fa-search"></span></button>
                                </div>
                            </div>
                        </form>
                    </div>                                
                    <div class="upload-product-box request-place bmargin10  pull-right"
                        *ngIf="shipId">
                        <h5>Can’t find the product in our catalogue?</h5>
                        <p>Place a request with us and we will get it delivered to you.</p>
                        <button type="button"
                            class="btn request"
                            (click)="showModal(newProductRequestTemplate)">Add Request</button>
                        <img src="assets/images/dropbox-icon.svg"
                            alt="excel-icon">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="categories-box bg-light-gray" [ngClass]="{'over-all':visibleCategories}">
                <a class="all-categories-link" *ngIf="!canPlaceReq && canPlaceOrder" (click)="setSubCategory(0, 0, true, true);toggle=false;rfqData=[]">View All Categories</a>
                <a class="all-categories-link" *ngIf="canPlaceReq && canPlaceOrder" (click)="setSubCategory(0, 0, true, true);toggle=false;rfqData=[]">View All Categories</a>
                <a class="all-categories-link" *ngIf="canPlaceReq && !canPlaceOrder" (click)="setSubCategory(0, 0, false, true);toggle=false;rfqData=[]">View All Categories</a>
                <ng-container>
                    <div>
                        <ul class="category-list" *ngIf="storeCategoryList && storeCategoryList.length>0 ">
                            <ng-container *ngFor="let category of storeCategoryList|slice:0-1">
                                <li class="category-item req-list" [class.disable-category]="!canPlaceReq && canPlaceOrder && category.id !== PROVISION_CATEGORY_ID">
                                    <div class="category-link-wrap">
                                        <a [ngClass]="{'opened':selectCategory === category.id && toggle}" [style.background-image]="'url(https://s3.ap-south-1.amazonaws.com/shipskart-combined/static/category/deck.png)'" (click)="openList(category.id);">{{'Impa Category' | titlecase}} <i class="fa fa-chevron-down" aria-hidden="true"></i></a>
                                        <ul class="sub-category-list" *ngIf="selectCategory === category.id && toggle">
                                            <li class="sub-category-item" *ngFor="let subCategory of uniqueObjectsArray.sort()">
                                                <a [ngClass]="{'active': subCategory.id == selectedSubCategory && category.id == selectCategoryId}" (click)="setSubCategory(subCategory.id, category.id, false);viewCategories();rfqData=[]">{{subCategory.name | titlecase}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                    <h5 class="category-head pl-3" *ngIf="(otherCategoryList?.length > 0)">Other Categories</h5>
                    <div *ngIf ="otherCategoryList && otherCategoryList.length>0">
                        <!-- <a class="all-categories-link" *ngIf="!canPlaceReq && canPlaceOrder" (click)="setSubCategory(0, 0, true, true);rfqData=[]">All Products</a> -->
                        <ul class="category-list">
                            <ng-container *ngFor="let category of otherCategoryList">
                                <li class="category-item req-list" [class.disable-category]="!canPlaceReq && canPlaceOrder && category && category?.id !== PROVISION_CATEGORY_ID">
                                    <div class="category-link-wrap">
                                        <a [ngClass]="{'opened':selectCategory === category?.id && toggle}" [style.background-image]="'url('+category?.category_image+')'" (click)="openList(category?.id);">{{category?.name | titlecase}} <i class="fa fa-chevron-down" aria-hidden="true"></i></a>
                                        <ul class="sub-category-list" *ngIf="selectCategory === category?.id && toggle">
                                            <li class="sub-category-item" *ngFor="let subCategory of category?.sub_categories.sort()">
                                                <a *ngIf="(canPlaceReq && canPlaceOrder || !canPlaceReq && canPlaceOrder) && selectCategory === PROVISION_CATEGORY_ID" [ngClass]="{'active': subCategory?.id == selectedSubCategory && category.id == selectCategoryId}" (click)="setSubCategory(subCategory.id, category.id, true, false);viewCategories();rfqData=[]">{{subCategory.name | titlecase}}</a>
                                                <a *ngIf="selectCategory !== PROVISION_CATEGORY_ID || canPlaceReq && !canPlaceOrder" [ngClass]="{'active': subCategory?.id == selectedSubCategory && category?.id == selectCategoryId}" (click)="setSubCategory(subCategory?.id, category?.id, false);viewCategories();rfqData=[]">{{subCategory.name | titlecase}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="col-md-12">
            <!-- <tabset> -->
                <div class="data-table">
                    <div class="scroll-table overflow">
                        <table class="table table-striped full-table smallTag">
                            <tr>
                                <th width="200">Name</th>
                                <th>Subcategory</th>
                                <th>Product code</th>
                                <!-- <th>IMPA code</th> -->
                                <th>Unit</th>
                                <th>Pack Size</th>
                                <th *ngIf="selectCat()">Price ({{currencyCode}})</th>
                                <th>Quantity</th>

                            </tr>
                            <ng-template [ngIf]="!noProduct && !pageLoading">
                                <tr *ngFor="let item of productList; let sl = index">
                                    <td>
                                        <div *ngFor="let iter of item.iteration[0]; let i = index" [ngClass]="item.random[i]">
                                            <div *ngFor="let iter of item.iteration[1]; let j = index" [ngClass]="item.random[j]">
                                                <div *ngFor="let iter of item.iteration[2]; let k = index" [ngClass]="item.random[k]">
                                                    <div *ngFor="let iter of item.iteration[3]; let l = index" [ngClass]="item.random[l]">
                                                        <div *ngFor="let iter of item.iteration[4]; let m = index" [ngClass]="item.random[m]">
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><span *ngIf="i==item.iteration[0].length-1" class="user-select">
                                                <a  (click)="openPopup(item)">
                                                    {{ item.name | titlecase }}
                                                  </a><br>
                                                <!-- If it's the last div, include the anchor tag -->
                                                <span><strong>Brand:</strong> {{(item?.brand?.name|titlecase) || 'N/A'}}</span>
                                            </span>
                                          </div>

                                    </td>
                                    <td class="subcat-td">
                                        <div *ngFor="let iter of item.iteration[0]; let i = index" [ngClass]="item.random[i]">
                                            <div *ngFor="let iter of item.iteration[1]; let j = index" [ngClass]="item.random[j]">
                                                <div *ngFor="let iter of item.iteration[2]; let k = index" [ngClass]="item.random[k]">
                                                    <div *ngFor="let iter of item.iteration[3]; let l = index" [ngClass]="item.random[l]">
                                                        <div *ngFor="let iter of item.iteration[4]; let m = index" [ngClass]="item.random[m]">
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span *ngIf="i==item.iteration[0].length-1">
                                                {{item.sub_category?.name | titlecase}}<br>
                                                <small>Available in: <span *ngFor="let cat of item.categories;index as ind">{{ind < item.categories.length - 1 ? cat+', ' : cat}}</span></small>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngFor="let iter of item.iteration[0]; let i = index" [ngClass]="item.random[i]">
                                            <div *ngFor="let iter of item.iteration[1]; let j = index" [ngClass]="item.random[j]">
                                                <div *ngFor="let iter of item.iteration[2]; let k = index" [ngClass]="item.random[k]">
                                                    <div *ngFor="let iter of item.iteration[3]; let l = index" [ngClass]="item.random[l]">
                                                        <div *ngFor="let iter of item.iteration[4]; let m = index" [ngClass]="item.random[m]">
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span *ngIf="i==item.iteration[0].length-1">
                                                {{item.product_code}}<br>
                                                <small>IMPA: {{item.impa_code || 'N/A'}}</small>
                                            </span>
                                        </div>
                                    </td>
                                    <!-- <td>{{item.impa_code || 'N/A'}}</td> -->
                                    <td>
                                        <div *ngFor="let iter of item.iteration[0]; let i = index" [ngClass]="item.random[i]">
                                            <div *ngFor="let iter of item.iteration[1]; let j = index" [ngClass]="item.random[j]">
                                                <div *ngFor="let iter of item.iteration[2]; let k = index" [ngClass]="item.random[k]">
                                                    <div *ngFor="let iter of item.iteration[3]; let l = index" [ngClass]="item.random[l]">
                                                        <div *ngFor="let iter of item.iteration[4]; let m = index" [ngClass]="item.random[m]">
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span *ngIf="i==item.iteration[0].length-1">
                                                <input readonly type="hidden" class="valbox form-control" [(ngModel)]="item.ship_id" />

                                        <input readonly type="hidden" class="valbox form-control" [(ngModel)]="item.category_id" />
                                        <div class="select-wrap" *ngIf="item.variants?.length > 1; else pack">
                                            <i class="fa fa-chevron-down"></i>
                                            <select class="select-list form-control" (change)="onPackChange($event.target.value, item.variants, item)">
                        <option *ngFor="let variant of item.variants" [selected]="variant.id == item.id"
                              [value]="variant.id">{{variant.unit?.name ? variant.unit?.name : ''}} <!-- {{variant.unit?.name}} -->
                        </option>
                    </select></div>
                                        <ng-template #pack> {{item.unit?.name}}</ng-template>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngFor="let iter of item.iteration[0]; let i = index" [ngClass]="item.random[i]">
                                            <div *ngFor="let iter of item.iteration[1]; let j = index" [ngClass]="item.random[j]">
                                                <div *ngFor="let iter of item.iteration[2]; let k = index" [ngClass]="item.random[k]">
                                                    <div *ngFor="let iter of item.iteration[3]; let l = index" [ngClass]="item.random[l]">
                                                        <div *ngFor="let iter of item.iteration[4]; let m = index" [ngClass]="item.random[m]">
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span *ngIf="i==item.iteration[0].length-1">{{item.pack_type ? item.pack_type : ''}}</span>
                                        </div>
                                    </td>
                                    <td *ngIf="selectCat()">
                                        <div *ngFor="let iter of item?.iteration; let i = index" [class]="item.random[i]">
                                        </div>
                                        <ng-container *ngIf="item.sub_category && item.sub_category.category_ids && item.sub_category.category_ids.includes(61) && item.vendor[0]?.price == null && item.rfq_submitted">
                                            <div class="rfq-submitted-label">RFQ Submitted</div>
                                        </ng-container>
                                        <ng-container *ngIf="canPlaceOrder && item.sub_category && item.sub_category.category_ids && item.sub_category.category_ids.includes(61)">
                                            <ng-container *ngIf="item.vendor[0]?.price == null && item.rfq_submitted == false">
                                                <label for="rfq-{{ sl }}{{ item.vendor[0]?.id }}" class="custom-checkbox">
                                                    <input type="checkbox"
                                                        id="rfq-{{ sl }}{{ item.vendor[0]?.id }}"
                                                        (change)="selectRfq(item.vendor[0]?.id, item.id, 0, $event.target.checked)"/><span class="shape"></span>
                                                    RFQ
                                                </label>
                                            </ng-container>
                                            {{item.vendor[0]?.price?.toFixed(2)}} <span class="small" *ngIf="item.vendor[0]?.remark"><i class="fa fa-info-circle info-icon" aria-hidden="true"></i><span class="info-items">{{item.vendor[0]?.remark}}</span></span>
                                        </ng-container>
                                        <ng-container *ngIf="item.sub_category && item.sub_category.category_ids && !item.sub_category.category_ids.includes(61)"><span class="semi-bold-text">N/A</span></ng-container>
                                    </td>
                                    <td class="qty-td">
                                        <div *ngFor="let iter of item.iteration[0]; let i = index" [ngClass]="item.random[i]">
                                            <div *ngFor="let iter of item.iteration[1]; let j = index" [ngClass]="item.random[j]">
                                                <div *ngFor="let iter of item.iteration[2]; let k = index" [ngClass]="item.random[k]">
                                                    <div *ngFor="let iter of item.iteration[3]; let l = index" [ngClass]="item.random[l]">
                                                        <div *ngFor="let iter of item.iteration[4]; let m = index" [ngClass]="item.random[m]">
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="quantity new-qty" *ngIf="i==item.iteration[0].length-1">
                                                <button [disabled]="item.quantity == 0" [ngStyle]="{'pointer-events': processing ? 'none' : 'all'}" class="count decrease-count" (click)="decreaseVal(item)">–
                                                </button>
                                                <input type="text" class="valbox" [ngStyle]="{'pointer-events': processing ? 'none' : 'all'}" pKeyFilter="pnum" [(ngModel)]="item.quantity" (change)="addItemToCart(item)" maxlength="15" />
                                                <button class="count increase-count" [ngStyle]="{'pointer-events': processing ? 'none' : 'all'}" (click)="increaseVal(item)">+
                                                </button>
                                            </div>
                                        </div>
                                        
                                    </td>

                                </tr>
                            </ng-template>
                        </table>
                    </div>
                    <div class="no-product" *ngIf="noProduct && !pageLoading">
                        <div class="request-product-container">
                            <img src="assets/images/product_request_image.svg" width="217" height="140">
                            <p class="title">Can't find what you are looking for?</p>
                            <p class="sub-title" (click)="showModal(newProductRequestTemplate)">Request a Specific Product</p>
                        </div>
                    </div>
                    <div class="no-product" *ngIf="pageLoading && !noProduct || pageLoading && noProduct">
                        Loading...
                    </div>
                </div>
                <div class="paging-wrap" [class.stop-click]="pageLoading && !noProduct || pageLoading && noProduct">
                    <span *ngIf="total" class="total-count">{{limit * page - 9}} -
          {{limit * page + limit - 10 < total ? limit * page + limit - 10 : total}}
          of {{total}}{{total >= 10000 ? ' Products' : ''}}</span>
                    <ngb-pagination *ngIf="total" [collectionSize]="total" [(page)]="page" [maxSize]="limit" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="onPageChange($event)"></ngb-pagination>
                </div>
                <div class="paging-wrap place-bottom" [class.stop-click]="pageLoading && !noProduct || pageLoading && noProduct">
                    <span *ngIf="total" class="total-count">{{limit * page - 9}} -
          {{limit * page + limit - 10 < total ? limit * page + limit - 10 : total}}
          of {{total}}{{total >= 10000 ? ' Products' : ''}}</span>
                    <ngb-pagination *ngIf="total" [collectionSize]="total" [(page)]="page" [maxSize]="limit" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="onPageChange($event)"></ngb-pagination>
                </div>
            <!-- </tabset> -->
            <!-- <input (click)="openModal(template)"
        class="btn btn-primary"
        type="button"
        value="Request for new products" /> -->
        </div>
    </div>
    <app-product-popup #productPopup [product]="productDetail"></app-product-popup>
    <div class="tabledata-sendaction" *ngIf="rfqData.length > 0">
        <button class="btn btn-outline-default request pull-right" (click)="submitRFQ()">
      Submit RFQs <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
    </button>
    </div>
</div>

<ng-template #attachmentsModal>
    Hello
</ng-template>

<ng-template #newProductRequestTemplate>
    <app-product-request (closeModalClicked)="closeModalClicked($event)" [shipId]="shipId" [portId]="portId" [isPlaceReq]="canPlaceReq" [isPlaceOrder]="canPlaceOrder" [isCatalogue]="true" [roleId]="role" [requisition]="requirementsDropdownDataSource"></app-product-request>
</ng-template>
<ng-template #portChange>
    <div class="modal-body port-change-popup padding40 text-center">
        <button type="button" class="close close1 pull-right" aria-label="Close" (click)="cancelPort();modalRef.hide()">
            <span aria-hidden="true">&#10006;</span>
          </button>
        <h4 class="sure-font">
            Your cart contains items from {{cartPortName}} Port. Do you want to shift previously selected items to new port or delete all items?
            <!-- cancelPort(); Your cart contains items from {{cartPortName ? cartPortName+' Port' : 'a Port'}}. Do you want to discard the selection and add items for new port? -->
        </h4>
        <div class="text-center tmargin20">
            <button class="btn btn-outline-primary" (click)="removeCartItems();modalRef.hide()" type="button">
        Delete Items
      </button>
            <button class="btn btn-primary text-white" (click)="portChanged();modalRef.hide()">Shift Items</button>
        </div>
    </div>
</ng-template>
<ng-template #cartNewRequirement>
    <form [formGroup]="cartNewRequisitionForm" (ngSubmit)="createCartRequirement()">
        <div class="modal-header">
            <h5 class="modal-title">Create New Requisition</h5>
        </div>
        <div class="modal-body">
            <div class="form-group" [ngClass]="{error : Rform.requirement_name.errors && submitted}">
                <label>Requisition Name<span class="mendatory">*</span></label>
                <input type="text" maxlength="100" class="form-control" placeholder="Requisition Name" formControlName="requirement_name" />
                <input-validation [control]="Rform.requirement_name" [submit]="submitted"></input-validation>
            </div>
            <div class="form-group" [ngClass]="{error : Rform.requirement_category_id.errors && submitted}">
                <label>Select Requisition Category<span class="mendatory">*</span></label>
                <ng-select [items]="requirementCategories" bindLabel="name" bindValue="id" formControlName="requirement_category_id" [multiple]="false" placeholder="Select Category" class="form-control" [clearable]="false"></ng-select>
                <input-validation [control]="Rform.requirement_category_id" [submit]="submitted"></input-validation>
            </div>
            <div class="form-group" [ngClass]="{error : Rform.creator_name.errors && submitted}">
                <label>Creator Name<span class="mendatory">*</span></label>
                <input type="text" maxlength="50" class="form-control" placeholder="Creator Name" formControlName="creator_name" />
                <input-validation [control]="Rform.creator_name" [submit]="submitted"></input-validation>
            </div>
            <div class="form-group" [ngClass]="{error : Rform.rank.errors && submitted}">
                <label>Select Creator Rank<span class="mendatory">*</span></label>
                <ng-select [items]="creatorRank" bindLabel="name" bindValue="name" formControlName="rank" [multiple]="false" placeholder="Select Creator Rank" class="form-control" [clearable]="false"></ng-select>
                <input-validation [control]="Rform.rank" [submit]="submitted"></input-validation>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default request border border-secondary" (click)="cancelRequirement()">Cancel</button>
            <button type="submit" class="btn btn-primary" [disabled]="loading || cartNewRequisitionForm.invalid">Create
                <ng-container *ngIf="loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="sr-only">Loading...</span>
                </ng-container>
            </button>
        </div>
    </form>
</ng-template>
