import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt/src/jwthelper.service';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '@app/modules/order/service/order.service';
import { DecimalPipe } from '@angular/common';
import { NewDashboard } from '@app/dashboard/models/newdashboard.model';
import { DashboardService } from '@app/dashboard/dashboard.service';
enum Reffrence_type {
  REFERENCE_TYPE_BUYER = 1,
  REFERENCE_TYPE_VENDOR = 2,
  REFERENCE_TYPE_SHIP = 3,
  REFERENCE_TYPE_CAPTAIN = 4
}

@Injectable({ providedIn: 'root' })
export class SharedService {
  private shipId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  shipId$: Observable<number>;

  shipIdSubject: Subject<number> = new Subject();
  privilages;
  userPrivilages;
  constructor(
    private http: HttpClient,
    private authservice: AuthenticationService,
    private toastr: ToastrService,
    private orderService: OrderService,
    private decimalPipe: DecimalPipe,
    private dashboardService: DashboardService
  ) {
    this.shipId$ = this.shipId.asObservable();
  }
  jwtHelper = new JwtHelperService();
  getCurrencyList = [
    { id: 'ALL', value: 'Albania Lek' },
    { id: 'AFN', value: 'Afghanistan Afghani' },
    { id: 'ARS', value: 'Argentina Peso' },
    { id: 'AWG', value: 'Aruba Guilder' },
    { id: 'AUD', value: 'Australia Dollar' },
    { id: 'AZN', value: 'Azerbaijan New Manat' },
    { id: 'BSD', value: 'Bahamas Dollar' },
    { id: 'BBD', value: 'Barbados Dollar' },
    { id: 'BDT', value: 'Bangladeshi taka' },
    { id: 'BYR', value: 'Belarus Ruble' },
    { id: 'BZD', value: 'Belize Dollar' },
    { id: 'BMD', value: 'Bermuda Dollar' },
    { id: 'BOB', value: 'Bolivia Boliviano' },
    { id: 'BAM', value: 'Bosnia and Herzegovina Convertible Marka' },
    { id: 'BWP', value: 'Botswana Pula' },
    { id: 'BGN', value: 'Bulgaria Lev' },
    { id: 'BRL', value: 'Brazil Real' },
    { id: 'BND', value: 'Brunei Darussalam Dollar' },
    { id: 'KHR', value: 'Cambodia Riel' },
    { id: 'CAD', value: 'Canada Dollar' },
    { id: 'KYD', value: 'Cayman Islands Dollar' },
    { id: 'CLP', value: 'Chile Peso' },
    { id: 'CNY', value: 'China Yuan Renminbi' },
    { id: 'COP', value: 'Colombia Peso' },
    { id: 'CRC', value: 'Costa Rica Colon' },
    { id: 'HRK', value: 'Croatia Kuna' },
    { id: 'CUP', value: 'Cuba Peso' },
    { id: 'CW', value: 'Curaçao' },
    { id: 'CZK', value: 'Czech Republic Koruna' },
    { id: 'DKE', value: 'Denmark Euro' },
    { id: 'DKK', value: 'Denmark Krone' },
    { id: 'DOP', value: 'Dominican Republic Peso' },
    { id: 'XCD', value: 'East Caribbean Dollar' },
    { id: 'EGP', value: 'Egypt Pound' },
    { id: 'SVC', value: 'El Salvador Colon' },
    { id: 'EEK', value: 'Estonia Kroon' },
    { id: 'EUR', value: 'Euro Member Countries' },
    { id: 'FKP', value: 'Falkland Islands (Malvinas) Pound' },
    { id: 'FJD', value: 'Fiji Dollar' },
    { id: 'GHC', value: 'Ghana Cedis' },
    { id: 'GIP', value: 'Gibraltar Pound' },
    { id: 'GTQ', value: 'Guatemala Quetzal' },
    { id: 'GGP', value: 'Guernsey Pound' },
    { id: 'GYD', value: 'Guyana Dollar' },
    { id: 'HNL', value: 'Honduras Lempira' },
    { id: 'HKD', value: 'Hong Kong Dollar' },
    { id: 'HUF', value: 'Hungary Forint' },
    { id: 'ISK', value: 'Iceland Krona' },
    { id: 'INR', value: 'India Rupee' },
    { id: 'IDR', value: 'Indonesia Rupiah' },
    { id: 'IRR', value: 'Iran Rial' },
    { id: 'IVC', value: 'Ivory Coast Cfa' },
    { id: 'IMP', value: 'Isle of Man Pound' },
    { id: 'ILS', value: 'Israel Shekel' },
    { id: 'JMD', value: 'Jamaica Dollar' },
    { id: 'JPY', value: 'Japan Yen' },
    { id: 'JEP', value: 'Jersey Pound' },
    { id: 'KZT', value: 'Kazakhstan Tenge' },
    { id: 'KSA', value: 'Kingdom Of Saudi Arabia' },
    { id: 'KPW', value: 'Korea (North) Won' },
    { id: 'KRW', value: 'Korea (South) Won' },
    { id: 'KWD', value: 'Kuwait' },
    { id: 'KGS', value: 'Kyrgyzstan Som' },
    { id: 'LAK', value: 'Laos Kip' },
    { id: 'LVL', value: 'Latvia Lat' },
    { id: 'LBP', value: 'Lebanon Pound' },
    { id: 'LRD', value: 'Liberia Dollar' },
    { id: 'LTL', value: 'Lithuania Litas' },
    { id: 'MKD', value: 'Macedonia Denar' },
    { id: 'MYR', value: 'Malaysia Ringgit' },
    { id: 'MUR', value: 'Mauritius Rupee' },
    { id: 'MXN', value: 'Mexico Peso' },
    { id: 'MNT', value: 'Mongolia Tughrik' },
    { id: 'MZN', value: 'Mozambique Metical' },
    { id: 'NAD', value: 'Namibia Dollar' },
    { id: 'NPR', value: 'Nepal Rupee' },
    { id: 'ANG', value: 'Netherlands Antilles Guilder' },
    { id: 'NZD', value: 'New Zealand Dollar' },
    { id: 'NIO', value: 'Nicaragua Cordoba' },
    { id: 'NGN', value: 'Nigeria Naira' },
    { id: 'NOK', value: 'Norway Krone' },
    { id: 'OMR', value: 'Oman Rial' },
    { id: 'PKR', value: 'Pakistan Rupee' },
    { id: 'PAB', value: 'Panama Balboa' },
    { id: 'PYG', value: 'Paraguay Guarani' },
    { id: 'PEN', value: 'Peru Nuevo Sol' },
    { id: 'PHP', value: 'Philippines Peso' },
    { id: 'PLN', value: 'Poland Zloty' },
    { id: 'QAR', value: 'Qatar Riyal' },
    { id: 'RON', value: 'Romania New Leu' },
    { id: 'RUB', value: 'Russia Ruble' },
    { id: 'SHP', value: 'Saint Helena Pound' },
    { id: 'SAR', value: 'Saudi Arabia Riyal' },
    { id: 'RSD', value: 'Serbia Dinar' },
    { id: 'SCR', value: 'Seychelles Rupee' },
    { id: 'SGD', value: 'Singapore Dollar' },
    { id: 'SBD', value: 'Solomon Islands Dollar' },
    { id: 'SOS', value: 'Somalia Shilling' },
    { id: 'ZAR', value: 'South Africa Rand' },
    { id: 'LKR', value: 'Sri Lanka Rupee' },
    { id: 'SEK', value: 'Sweden Krona' },
    { id: 'CHF', value: 'Switzerland Franc' },
    { id: 'SRD', value: 'Suriname Dollar' },
    { id: 'SYP', value: 'Syria Pound' },
    { id: 'TWD', value: 'Taiwan New Dollar' },
    { id: 'THB', value: 'Thailand Baht' },
    { id: 'TTD', value: 'Trinidad and Tobago Dollar' },
    { id: 'TRY', value: 'Turkey Lira' },
    { id: 'TRL', value: 'Turkey Lira' },
    { id: 'TVD', value: 'Tuvalu Dollar' },
    { id: 'UAH', value: 'Ukraine Hryvna' },
    { id: 'GBP', value: 'United Kingdom Pound' },
    { id: 'USD', value: 'United States Dollar' },
    { id: 'UYU', value: 'Uruguay Peso' },
    { id: 'UZS', value: 'Uzbekistan Som' },
    { id: 'VEF', value: 'Venezuela Bolivar' },
    { id: 'VND', value: 'Viet Nam Dong' },
    { id: 'YER', value: 'Yemen Rial' },
    { id: 'ZWD', value: 'Zimbabwe Dollar' }
  ];

  currencyDropdown = [
    { id: 'INR', name: 'INR' },
    { id: 'USD', name: 'USD' },
    { id: 'JPY', name: 'JPY' },
    { id: 'EUR', name: 'EUR' },
    { id: 'SGD', name: 'SGD' },
    { id: 'AED', name: 'AED' },
    { id: 'AUD', name: 'AUD' },
    { id: 'QAR', name: 'QAR' },
    { id: 'SAR', name: 'SAR' }
  ];

  defaultCurrency = 'USD';

  vendorCurrencyDropdown = [
    { id: 'INR', name: 'INR' },
    { id: 'USD', name: 'USD' },
    { id: 'JPY', name: 'JPY' },
    { id: 'EUR', name: 'EUR' },
    { id: 'SGD', name: 'SGD' },
    { id: 'GBP', name: 'GBP' },
    { id: 'AED', name: 'AED' },
    { id: 'QAR', name: 'QAR' },
    { id: 'SAR', name: 'SAR' }
  ];

  requesterDetails = [
    {
      name: "Aastha Arora",
      designation: "Operations Head",
      phone: "+91 9119060044, +91 9899603866",
      email: "aastha@shipskart.com",
      id: 1554
    },
    {
      name: "Ankit Kashyap",
      designation: "Sr. Operations Executive",
      phone: "+91 9113490199",
      email: "ankit@shipskart.com",
      id: 1543
    },
    {
      name: "Bablu Singh",
      designation: "Operation Executive",
      phone: "+91 9910703185",
      email: "bablu@shipskart.com",
      id: 1510
    },
    {
      name: "Nitanshu Singh Bhandari",
      designation: "Operation Executive",
      phone: "+91 9910703185",
      email: "nitanshu@shipskart.com",
      id: 2296
    },
    {
      name: "Krishna Bihari",
      designation: "Operation Executive",
      phone: "+91 9821631465",
      email: "support@shipskart.com",
      id: 2287
    },
    {
      name: "Mehul Lyall",
      designation: "Key Account Manager",
      phone: "+91 9311342648",
      email: "mehul@shipskart.com",
      id: 2475
    }, {
      name: "Rahul Gupta",
      designation: "Asst. Manager",
      phone: "+91 9643335482, +91 9716165637",
      email: "rahul@shipskart.com",
      id: 1518
    }, {
      name: "Rajkumar",
      designation: "Sr. Executive-Operations",
      phone: "+91 9599194875",
      email: "stores@shipskart.com",
      id: 1547
    }, {
      name: "Vishak S",
      designation: "Operations Executive",
      phone: "+91 6202520925",
      email: "kochi@shipskart.com",
      id: 2302
    },
    {
      name: "Zahid",
      designation: "Operations Executive",
      phone: "+91 9136793879",
      email: "mumbai@shipskart.com",
      id: 3190
    },
    {
      name: "Shailesh Kumar Yadav",
      designation: "Operations Executive",
      phone: "+91 8921383179",
      email: "shailesh@shipskart.com",
      id: 1544
    },
    {
      name: "Shashi Ranjan Sharma",
      designation: "Branch Manager",
      phone: "+91 9946752448",
      email: "shashi@shipskart.com",
      id: 1546
    },
    {
      name: "Shipra",
      designation: "Operations Executive",
      phone: "+91 9289366059",
      email: "shipra@shipskart.com",
      id: 1520
    }, {
      name: "Surendra Deonandan Prasad",
      designation: "Branch Manager",
      phone: "+91 9599189379",
      email: "surendra@shipskart.com",
      id: 4090
    },
    {
      name: "Mos Subadmin",
      designation: "Sr. Tech Executive",
      phone: "+91 9205443218",
      email: "mossubadmin@gmail.com",
      id: 1552
    },
    {
      name: "Demo",
      designation: "Sr. Tech Executive",
      phone: "+91 7007890066",
      email: "demosubadmin@shipskart.com",
      id: 1068
    },
    {
      name: "Vineet C",
      designation: "Sr. Operations Executive",
      phone: "+91 9560096063",
      email: "vineet@shipskart.com",
      id: 2301
    },
    {
      name: "Virendra Pratap Singh",
      designation: "Operations Executive",
      phone: "+91 7317777444",
      email: "support@shipskart.com",
      id: 1513,
    }, {
      name: "Mahesh Agarwal",
      designation: "Operations Executive",
      phone: "+91 8800650488",
      email: "maheshag@shipskart.com",
      id: 2661,
    }, {
      name: "Deepak Shukla",
      designation: "Operations Executive",
      phone: "+91 9599194876",
      email: "deepak@shipskart.com",
      id: 2801,
    }, {
      name: "Jayanti Maheshwari",
      designation: "Operations Executive",
      phone: "+91 7567925779",
      email: "gujarat@shipskart.com",
      id: 2250,
    }, {
      name: "Aghil C",
      designation: "Operations Executive",
      phone: "+91 9205467200",
      email: "aghil@shipskart.com",
      id: 3147,
    }, {
      name: "Ninad Sudhir Shetye",
      designation: "Operations Executive",
      phone: "+91 9818860607",
      email: "ninad@shipskart.com",
      id: 4079,
    }, {
      name: "Pramod Kumar Chaurasia",
      designation: "Operation Executive",
      phone: "+91 7503354237",
      email: "pramodk@Shipskart1.onmicrosoft.com",
      id: 4322
    }, {
      name: "Pramod Mishra",
      designation: "Operations Executive",
      phone: "+91 9599189375 ",
      email: "gujarat@shipskart.com",
      id: 1542,
    }, {
      name: "Bhavesh Maheshwari",
      designation: "Operations Executive",
      phone: "+91 8401995853",
      email: "gujarat@shipskart.com",
      id: 3921,
    }, {
      name: "Vaibhav Sharma",
      designation: "Operations Executive",
      phone: "+91 8684896817",
      email: "mumbai@shipskart.com",
      id: 4427,
    }, {
      name: "Manoj Prakash Patil",
      designation: "Sr. Operations Executive",
      phone: "+91 9643335483",
      email: "mumbai@shipskart.com",
      id: 4428,
    }, {
      name: "Monish Ramchandran",
      designation: "Sr. Operations Executive",
      phone: "+91 9677153658",
      email: "chennai@shipskart.com",
      id: 2678,
    }, {
      name: "Bharati Kannan",
      designation: "Operations Executive",
      phone: "+91 7395926761",
      email: "chennai@shipskart.com",
      id: 3920,
    }, {
      name: "Rohit Kumar ",
      designation: "Operations Executive",
      phone: "+91 8891557954",
      email: "kochi@shipskart.com",
      id: 4429
    },
  ];

  getCountry(isMos?) {
    if (isMos) {
      return this.http.get<{ data }>(`${environment.apiUrl}/v1/country/?is_mos=${isMos}`).pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
    } else {
      return this.http.get<{ data }>(`${environment.apiUrl}/v1/country/`).pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
    }
  }

  getCity() {
    return true;
  }

  getShip() {
    if (this.authservice.currentUserValue.data.member) {
      // for captain
      if (
        this.authservice.currentUserValue.data.member.type ===
        Reffrence_type.REFERENCE_TYPE_CAPTAIN
      ) {
        this.shipIdSubject.next(
          this.authservice.currentUserValue.data.member.reference_id
        );
        return this.authservice.currentUserValue.data.member.reference_id;
      }
      // for admin
      if (
        this.authservice.currentUserValue.data.member.type ===
        Reffrence_type.REFERENCE_TYPE_BUYER
      ) {
        this.shipIdSubject.next(+localStorage.getItem('ship_id'));
        return localStorage.getItem('ship_id');
      }
    }
    this.shipIdSubject.next(+localStorage.getItem('ship_id'));
    return localStorage.getItem('ship_id');
  }

  getShipData() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  getCompany() {
    return this.authservice.currentUserValue.data.user_details.company_details;
  }
  getUserDetails() {
    return this.authservice.currentUserValue.data.user_details;
  }
  checkInventoryRights() {
    return this.authservice.currentUserValue.data.show_inventory;
  }

  getSubAdminsCompanies() {
    if (this.authservice.currentUserValue.data.sub_admins_companies && this.authservice.currentUserValue.data.sub_admins_companies.length > 0) {
      const companyIds: [number] = this.authservice.currentUserValue.data.sub_admins_companies.map(x => x.company_id);
      return companyIds;
    }
  }

  getDesignation() {
    if (this.authservice.currentUserValue.data.user_details) {
      const designationId: number = this.authservice.currentUserValue.data.user_details.designation_id;
      return designationId;
    }
  }

  getSubAdminsPrivileges() {
    if (this.authservice.currentUserValue.data.sub_admins_privileges && this.authservice.currentUserValue.data.sub_admins_privileges.length > 0) {
      const privileges: [number] = this.authservice.currentUserValue.data.sub_admins_privileges.map(x => x.privilege_id);
      return privileges;
    }
  }

  setShipId(id: number) {
    this.shipId.next(id);
    this.shipIdSubject.next(id);
  }

  getUserRole() {
    const role = this.getUserClaim();
    return role.roles[0];
  }

  getTokenPayload() {
    const myRawToken = this.authservice.currentUserValue.data.access_token;
    const payload = this.jwtHelper.decodeToken(myRawToken);
    return payload;
  }
  getUserClaim() {
    let payload = this.getTokenPayload();
    payload = payload && payload.user_claims ? payload.user_claims : payload;
    return payload;
  }
  getUserIdentity() {
    const payload = this.getTokenPayload();
    return payload.identity;
  }
  enumSelector(definition) {
    return Object.keys(definition).map(key => ({
      value: definition[key],
      title: key
    }));
  }

  saveAsBlob(
    data: any,
    type = 'application/vnd.ms-excel',
    name = 'reports.xlsx'
  ) {
    let blob;
    let file;
    if (type == null) {
      blob = new Blob([data]);
      file = new File([blob], name);
      saveAs(file);
    } else {
      blob = new Blob([data], { type });
      file = new File([blob], name, { type });
      saveAs(file);
    }
  }
  changePassword(payload) {
    return this.http.put(
      `${environment.apiUrl}/v1/user/change-password`,
      payload
    );
  }
  public convertModelToFormData(
    // tslint:disable-next-line:ban-types
    model: Object,
    form: FormData = null,
    namespace = ''
  ): FormData {
    const formData = form || new FormData();
    delete model['zoho_warehouses'];
    delete model['zoho_vendors'];
    delete model['zoho_taxes'];
    for (const propertyName in model) {
      if (!model.hasOwnProperty(propertyName) || !model[propertyName]) { continue; }
      const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
      if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element, index) => {
          const tempFormKey = `${formKey}[${index}]`;
          this.convertModelToFormData(element, formData, tempFormKey);
        });
      } else if (
        typeof model[propertyName] === 'object' &&
        !(model[propertyName] instanceof File)
      ) {
        this.convertModelToFormData(model[propertyName], formData, formKey);
      } else { formData.append(formKey, model[propertyName].toString()); }
    }
    return formData;
  }

  getNotification(page: number, limit: number) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/notification/?page=${page}&limit=${limit}`)
      .pipe(
        map(data => {
          return data.data;
        })
      );
  }
  updateNotification(id) {
    return this.http.put(`${environment.apiUrl}/v1/notification/${id}`, {});
  }

  isFormBank(form) {
    const formvalue = Object.values(form.value);
    let isBlank = true;
    Array.from(new Set(formvalue)).forEach(ele => {
      if (ele) {
        isBlank = false;
      }
      if (ele === 0) {
        isBlank = false;
      }
    });
    return isBlank;
  }
  syncZoho() {
    return this.http.post(`${environment.apiUrl}/inventory_api/inventory/sync_catalogue_items_with_zoho/`, {})
  }

  getAllBrands() {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/product/brands`).pipe(map(dataval => {
      return dataval.data;
    }))
  }

  /* uploadFile(files: FileList) {
    if (files.length > 5) {
      this.toastr.error('Please select a maximum of 5 files.');
      return;
    } else {
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        const fileSize = file.size / 1024 / 1024;
        formData.append('file', file);
        if (fileSize > 5) {
        } else {
          return this.orderService.supportingDocument(formData).subscribe(data => {
            this.toastr.success('File Uplodaded Successfully')
          })
        }
      }
    }
  } */

  downloadAttachement(attachedFile) {
    return `${attachedFile}`
  }

  removeMarginAmt(item) {
    let prc_without_tax;
    if (parseFloat(item.tax_rate) != null) {
      prc_without_tax = (parseFloat(item.price) * 100) / (parseFloat(item.tax_rate) + 100);
    } else {
      prc_without_tax = parseFloat(item.price);
      item.tax_rate = 0;
    }
    const prc_without_margin = (prc_without_tax * 100) / (parseFloat(item.margin) + 100);
    const prc_with_tax = prc_without_margin + ((prc_without_margin * parseFloat(item.tax_rate)) / 100);
    item.price = prc_with_tax;
    return parseFloat(item.price);
  }
  removeMargin(item) {
    let prc_without_tax;
    if (parseFloat(item.tax) != null) {
      prc_without_tax = (parseFloat(item.price) * 100) / (parseFloat(item.tax) + 100);
    } else {
      prc_without_tax = parseFloat(item.price);
      item.tax_rate = 0;
    }
    const prc_without_margin = (prc_without_tax * 100) / (parseFloat(item.margin) + 100);
    item.price = prc_without_margin;
    return parseFloat(item.price);
  }
  private appliedFiltersSubject = new BehaviorSubject<any>(null);
  appliedFilters$: Observable<any> = this.appliedFiltersSubject.asObservable();

  setAppliedFilters(filters: any): void {
    this.appliedFiltersSubject.next(filters);
  }

  deletePanel(panelId: number, deleteValue: number) {
    return this.http.delete(`${environment.apiUrl}/v1/dashboard/reports/${panelId}/${deleteValue}`)
  }


  private selectedDataSubject = new BehaviorSubject<MyData>({ formdata: null, data: null });
  selectedData$ = this.selectedDataSubject.asObservable();

  setSelectedData(formdata: any, data: any) {
    const newdata = { formdata, data }
    this.selectedDataSubject.next(newdata);
  }


  removeFilter(event) {
    let filter = {}
    filter[event.filter_id] = null
    new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set('x-filter', JSON.stringify(filter));
      this.dashboardService.getReportData(event.report_id, headers, 0)
        .then((response: NewDashboard) => {
          if (response) {
            setTimeout(() => {
              resolve(response);
            }, 200);
          }
        })
        .catch(error => {
          reject(error); // Reject the promise with the error
        });
    })
  }
}

interface MyData {
  formdata: any;
  data: any;
}
