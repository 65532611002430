<div>
    <div class="filters inline-block option pull-right">
        <div class="search-categories search-field">
            <form [formGroup]="filterForm" (ngSubmit)="filterData(1)">
                <div class="searchbox" [class.showCross]="selectedAutocomplete">
                    <div class="ng-autocomplete">
                        <p-autoComplete #autocomplete formControlName ="fname" placeholder="Search user by name, email & phone" [ngModel]="selectedAutocomplete" [suggestions]="suggestion" (keyup)="showCrossBtn($event)" (completeMethod)="search($event)" (keyup.enter)="filterData(1)" (onSelect)="doOnSelect($event)">
                            <ng-template let-suggestion let-i="index" pTemplate="item" styleClass="wid100">
                                <div class="search-company ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5;width: 500px;">
                                    <p>{{suggestion.fname | titlecase}} {{suggestion.lname}}</p>
                                    <p class="font12">Email : <strong>{{suggestion.email}}</strong></p>
                                    <p class="font12">Phone : <strong>{{suggestion.phone}}</strong></p>
                                </div>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                    <button class="find-btn cross-btn" (click)="resetForm(2)" type="reset">&times;</button>
                    <button class="find-btn" type="submit"><span class="fa fa-search"></span></button>
                </div>
            </form>
        </div>

        <button *ngIf="role == userRole.ROLE_ADMIN || role == userRole.ROLE_SUBADMIN" class="btn empty-cart filter lmargin10" (click)="openFilters = !openFilters;msg = false;"><span class="fa fa-filter"></span> Filters</button>
        <button class="btn empty-cart filter lmargin10" (click)="exportUsers()" [disabled]="exporting"><span class="fa fa-download" *ngIf="!exporting"></span> Export Users
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="exporting"></span>
    </button>

        <a class="btn buttn req lmargin10 rs-min-width140" *ngIf="getUserPermission()" routerLink="/user/add">{{ onlyShowDeliveryDrivers ? 'Add Drivers' : 'Add User'}}</a>
        <span (click)="openFilters = !openFilters" class="click-shadow" [ngClass]="{'show': openFilters}"></span>
        <div class="filter-options" [ngClass]="{'show': openFilters}">
            <a href="javascript:void(0)" (click)="openFilters = false" class="close-filter-btn">&#215;</a>
            <label class="required-some-data" *ngIf="msg">Please Enter Some Value to Filter Data.</label>
            <form [formGroup]="filterForm" (ngSubmit)="filterData(1)">
                <div class="row">
                    <!-- <div class="col-md-4">
                        <label for="">Name</label>
                        <input formControlName="fname" placeholder="Name" type="text" class="form-control" />
                    </div>
                    <div class="col-md-4">
                        <label for="">Phone</label>
                        <input pKeyFilter="pnum" formControlName="phone" placeholder="Phone No" type="text" maxlength="16" class="form-control" />
                    </div>
                    <div class=" col-md-4">
                        <label for="">Email</label>
                        <input formControlName="email" placeholder="Email" type="text" class="form-control" />
                        <span *ngIf="submitted" style="color:#ff0000;font-size: 13px;">Invalid email address</span>
                    </div> -->
                    <div class="col-md-4 tmargin20">
                        <label for="">Company</label>
                        <ng-select [items]="companys" bindLabel="name" bindValue="id" [multiple]="true" [closeOnSelect]="false" formControlName="company_id" placeholder="Select Company" class="hide-cross-btn rfq-subcatlist form-control" [clearOnBackspace]="true" [clearSearchOnAdd]="true">
                            <ng-template ng-option-tmp let-item="item">
                                {{item.name}} | <b> {{getCompanyType(item.type)}}</b>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="col-md-4 tmargin20">
                        <label for="">Verification Status</label>
                        <ng-select [items]="verifyStatuses" bindLabel="title" bindValue="value" formControlName="verified" placeholder="Select" class="form-control" clearAllText="Clear" [clearable]="false">
                        </ng-select>
                    </div>
                    <div class="col-md-4 tmargin50">
                        <input class="btn btn-outline-primary" type="button" value="Clear Filter" (click)="resetForm(1)" />
                        <input class="btn btn-primary" type="submit" value="Apply" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="viewbox">
    <h3 class="inline-block rs-top-space pad-bottom">{{ onlyShowDeliveryDrivers ? 'Drivers' : 'Users'}}</h3>
    <div class="load-listing" *ngIf="loading">
        Loading...
    </div>
    <div class="data-table" *ngIf="!loading">
        <ngx-datatable class="material paging-table top" [class.stop-click]="isLoading" #userTable [rows]="rows" [columnMode]="'force'" [headerHeight]="40" [scrollbarH]="true" [footerHeight]="40" [rowHeight]="'auto'" [externalPaging]="true" [loadingIndicator]="isLoading"
            [count]="page.count" [offset]="page.offset" [limit]="page.limit" (page)='setPage($event)'>
            <ngx-datatable-column *ngIf="getUserPermission()" headerClass="no-arrow" name="" prop="" frozenLeft="True" [width]="40" [maxWidth]="40">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                    <a routerLink="/user/edit/{{value.id}}" target="_blank"><i class="fa fa-pencil"></i></a>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Name" prop="fname">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                    <a routerLink="/user/details/{{ row.id }}" target="_blank">{{ value|titlecase }}</a>
                    <ng-container *ngIf="row.source">
                        <br/>
                        <span class="registered-user-label">Source: {{ row.source }}</span>
                    </ng-container>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let col of columns" [name]="col.name" [prop]="col.prop">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <div class="column-item" (click)="onCellClicked(row)">
                        <span class="{{ col.prop }}" [ngSwitch]="col.type">
              <span *ngSwitchCase="'date'">
                {{ value | utctolocal | date: 'longDate' }}, {{ value | utctolocal | date: 'shortTime' }}
              </span>
                        <span *ngSwitchDefault>
                {{ value }}
              </span>
                        </span>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Action" prop="" [width]="100" *ngIf="(role != userRole.ROLE_USER && role != userRole.ROLE_COMPANY_ADMIN &&(role == userRole.ROLE_SUBADMIN && canSubAdminChangePassword)|| (role == userRole.ROLE_ADMIN))  ">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                    <a href="javascript:void(0)" (click)="ChangePasswordModel(changePassword,value.token)">Change Password</a>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
                    <app-paging class="paging" [curPage]="curPage" [pageSize]="pageSize" [rowCount]="rowCount" [offset]="offset" [table]="table" (page)="onPageChange($event,1)" [filter]="filterForm.value"></app-paging>
                    <app-paging class="paging place-bottom" [curPage]="curPage" [pageSize]="pageSize" [rowCount]="rowCount" [offset]="offset" [table]="table" (page)="onPageChange($event,1)" [filter]="filterForm.value"></app-paging>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
</div>

<ng-template #changePassword>
    <div class="modal-body cancelmodal-spc padding30">
        <button type="button" class="close cross-butn" aria-label="Close" (click)="modalRef.hide();">
            <span aria-hidden="true">&times;</span>
        </button>
        <h3 class="text-center bmargin20">Change Password</h3>
        <form #changePassword action="#" [formGroup]="ChangePasswordForm" (submit)="onChangedPassword()" class="form" role="form">

            <div class="form-group" [ngClass]="{error : submitted && form.password.errors}">
                <label for="">New Password</label>
                <div class="label-input">
                    <input [type]="isTextFieldType ? 'text' : 'password'" class="form-control password-field" formControlName="password" id="" placeholder="Password">
                    <button class="eye-icon-btn user list" (click)="togglemyPasswordFieldType()" type="button">
                    <i [class]="isTextFieldType ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                </button>
                </div>
                <input-validation [control]="form.password" [submit]="submitted"></input-validation>
            </div>
            <div class="form-group" [ngClass]="{error : submitted && form.confirmPassword.errors}">
                <label for="">Confirm Password</label>
                <div class="label-input">
                    <input [type]="isTextFieldConfirmType ? 'text' : 'password'" class="form-control password-field" formControlName="confirmPassword" id="" placeholder="Confirm Password">
                    <button class="eye-icon-btn user list" (click)="togglemyConfirmPasswordFieldType()" type="button">
                        <i [class]="isTextFieldConfirmType ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                    </button>
                </div>
                <input-validation [control]="form.confirmPassword" [submit]="submitted"></input-validation>
            </div>
            <div class="text-right pad-top">
                <button type="button" (click)="modalRef.hide();" class="btn btn-outline-primary">Cancel</button>
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>
        </form>

    </div>
</ng-template>
