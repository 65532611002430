import { SharedService } from './../../../../shared/service/shared.service';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { Product } from '../../model/product.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { UserRoles } from '@app/core/enums/user-roles';
import { CompanyService } from '@app/modules/company/services/company.service';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '@app/authentication/services/loading.service';
import { AlertService } from '@app/shared/service/alert.service';
import { environment } from "@environment/environment";
import { S3Service } from "@app/shared/service/aws-bucket.service";
@Component({
  selector: 'app-product-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  id: number;
  product: Product;
  imgForm: FormGroup;
  selectedId: any;
  modalRef: any;
  files: File[] = [];
  submitted: boolean;
  showLoader: boolean;
  role: number;
  userRoles = UserRoles;
  private unsubscribe$ = new Subject<any>();
  env;
  loading = false;
  companyType: number;
  params = null;
  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private tostr: ToastrService,
    private sharedService: SharedService,
    private router: Router,
    private companyService: CompanyService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private s3Service: S3Service
  ) {
    this.env = environment;
    this.role = this.sharedService.getUserRole();
    this.companyType = this.sharedService.getCompany().type;

  }

  ngOnInit() {
    this.route.params.subscribe(value => {
      this.id = value.id;
    });
    this.params = null
    this.route.queryParams.subscribe(param => this.params = param)
    this.imgForm = this.formBuilder.group({
      img_name: ['', [Validators.required]],
    });
    if (this.role === this.userRoles.ROLE_ADMIN || this.role === this.userRoles.ROLE_SUBADMIN || this.role === this.userRoles.ROLE_COMPANY_ADMIN && this.companyType === 2) {
      this.ProductData();
    } else {
      this.router.navigate(['/']);
    }
  }
  get form() {
    return this.imgForm.controls;
  }
  private ProductData() {
    this.loadingTrue();
    return this.productService.getByid(this.id).subscribe(async value => {
      this.product = value;
      this.updateProductImages();
      this.loadingFalse();
      // this.toSentenceCase(this.product.description);
    }, error => {
      this.loadingFalse();
      this.tostr.error(error.error.message);
      this.router.navigate(['product/']);
    });
  }

  openModal(template: TemplateRef<any>, id = null) {
    this.selectedId = id;
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-md' })
    );
    this.alertService.backModalClose(this.modalRef);
  }

  selectedfileName(files: FileList) {
    if (this.product.images.length < 5 && this.product.images.length + files.length <= 5) {
      if (files.length > 0) {
        this.files = Array.from(files);
      }
    } else {
      this.files = [];
      this.form.img_name.setValue('');
      this.tostr.error("Only 5 product images can be uploaded!");
    }
  }

  addDocument() {
    this.submitted = true;
    if (!this.imgForm.valid) {
      return false;
    } else {
      this.showLoader = true;
      this.files.forEach((file, ind) => {
        let imgName = this.imgForm.get('img_name').value;
        const cleanPath = imgName.replace("C:\\fakepath\\", "");
        this.imgForm.removeControl('img_name');
        let formData = this.sharedService.convertModelToFormData(this.imgForm.value);
        formData.append('image', file, file.name);
        formData.append('status', '1');
        formData.append('product_id', this.id.toString());
        formData.append('alt_tag', this.product.name);
        if (cleanPath) {
          formData.append('img_name', cleanPath);
        }
        this.productService.uploadImg(formData).subscribe(data => {
          if (ind === this.files.length - 1) {
            this.ProductData();
            this.submitted = false;
            this.showLoader = false;
            formData = null;
            this.imgForm.reset();
            this.modalRef.hide();
            this.tostr.success('Image Successfully Update');
          }
        }, error => {
          this.submitted = false;
          this.showLoader = false;
          formData = null;
          this.tostr.error(error.error.message);
        });

      })

    }
  }


  deleteProduct() {
    this.loadingTrue();
    this.productService.deleteProduct(this.id).subscribe(data => {
      this.tostr.success(data.data.message);
      this.loadingFalse()
      this.goPreviousUrl();
      // this.router.navigate(['product/']);

    }, error => {
      this.loadingFalse()
      this.tostr.error(error.error.message);
    });
  }

  onImgError(event) {
    event.target.src = '/assets/images/placeholder_image.png';
  }

  goPreviousUrl() {
    if (this.product.is_requested) {
      this.router.navigate(['/product/request']);
      return;
    }
    this.companyService.getUrl.pipe(takeUntil(this.unsubscribe$))
      .subscribe(url => {
        if (url && url.page && url.filter) {
          if (this.params.price == 'true') {
            return this.router.navigate(['/product/prices'], { queryParams: { page: url.page, filter: url.filter } });
          } else {
            return this.router.navigate(['/product'], { queryParams: { page: url.page, filter: url.filter } });
          }
        } else {
          return this.router.navigate(['/product']);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  loadingFalse() {
    this.loading = false;
    this.loadingService.setLoading(false);
  }

  loadingTrue() {
    this.loading = true;
    this.loadingService.setLoading(true);
  }
  async openNewPage(str) {
    const imge = await this.s3Service.getS3Object(this.env.bucket, str.url).then(res => res);
    window.open(imge, "_blank");
  }


  //Array of images are decrypt to protect images
  async updateProductImages() {
    try {
      // Create an array of promises to fetch and update each image
      if (this.product !== undefined) {
        const imagePromises = this.product.images.map(async (img) => {
          img.path = await this.s3Service.getS3Object(this.env.bucket, img.path);
          return img; // Return the modified image object
        });
        // Wait for all promises to resolve
        const resolvedImages = await Promise.all(imagePromises);
        // Filter out any null values if necessary (e.g., in case of errors)
        this.product.images = resolvedImages.filter(img => img !== null);
        return this.product.images;
      }
    } catch (error) {
      throw error; // Optionally throw the error to handle it further up the chain
    }
  }
}
