import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ChartRedirectionService {

  constructor(
    private router: Router
  ) { }

  generateFilterBasedPayload(filter, graphAxes, val = null, primary_axis_ids = null) {
    let filterPayload = {}
    let axes = graphAxes;
    const appliedFilters = filter.filters.filter(item => item.filter_value);
    if (appliedFilters && appliedFilters.length > 0) {
      appliedFilters.forEach(item => {
        switch (item.filter_id) {
          case 4:
            const fromToDate = item.filter_value.split(':');
            filterPayload['from_date'] = fromToDate[0];
            filterPayload['to_date'] = fromToDate[1];
            break;
          case 19:
            filterPayload['top_n'] = item.filter_value;
            break;
          case 21: // Done
            filterPayload['sub_admin'] = item.filter_value.split(',').map(item => parseInt(item));;
            break;
          case 20: // Done
            filterPayload['country_ids'] = item.filter_value.split(',').map(item => parseInt(item));
            break;
          case 22: // Done
            filterPayload['created_by'] = item.filter_value.split(',').map(item => parseInt(item));
            break;
          case 18:
            const deliveryFromToDate = item.filter_value.split(':');
            filterPayload['delivery_from_date'] = deliveryFromToDate[0];
            filterPayload['delivery_to_date'] = deliveryFromToDate[1];
            break;
          case 23: // Done
            filterPayload['product_code'] = item.filter_value;
            break;
          case 12: // Done
            filterPayload['category_ids'] = item.filter_value.split(',').map(item => parseInt(item));
            break;
          case 6: // Done
            filterPayload['ship_ids'] = item.filter_value.split(',').map(item => parseInt(item));
            break;
          case 5: // Done
            filterPayload['client_ids'] = item.filter_value.split(',').map(item => parseInt(item));
            break;
          case 8: // Done
            filterPayload['vendor_ids'] = item.filter_value.split(',').map(item => parseInt(item));
            break;
          case 7: // Done
            filterPayload['port_ids'] = item.filter_value.split(',').map(item => parseInt(item));
            break;
          case 24: // Done
            filterPayload['purchaser_ids'] = item.filter_value.split(',').map(item => parseInt(item));
            break;
        }
      })
    }
    if (axes) {
      if (axes == 71 || axes == 9 || axes == 10 || axes == 47) {
        filterPayload['order_status'] = [1, 2, 3, 4, 5, 6];
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/orders')
      }
      else if (axes == 51 || axes == 52 || axes == 69 || axes == 22 || axes == 11 || axes == 43 || axes == 15 || axes == 72 || axes == 74 || axes == 50 || axes == 14 || axes == 13 || axes == 12 || axes == 13 || axes == 33) {
        filterPayload['order_status'] = [4];
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/orders')
      }
      else if (axes == 21) {
        filterPayload['order_status'] = [3];
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/orders')
      }
      else if (axes == 62 || axes == 24) {
        filterPayload['order_status'] = [5];
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/orders')
      }
      else if (axes == 20) {
        filterPayload['order_status'] = [1];
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/orders')
      }
      else if (axes == 32) {
        filterPayload['name'] = val;
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/port')
      }
      else if (axes == 23) {
        filterPayload['order_status'] = [6];
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/orders')
      }
      else if (axes == 73 || axes == 17) {
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/requisitions?req_status=2&page=1');
      }
      else if (axes == 48) {
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/requisitions?req_status=1&page=1')
      }
      else if (axes == 19) {
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/requisitions?priority_type=Urgent&page=1')
      }
      else if (axes == 54 || axes == 55 || axes == 8) {
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/requisitions?req_status=-1&page=1');
      }
      else if (axes == 16) {
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/requisitions?req_status=0&page=1');
      }
      else if (axes == 18) {
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/requisitions?req_status=3&page=1');
      }
      else if (axes == 7) {
        filterPayload['name'] = val;
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/company?type=2&page=1');
      }
      else if (axes == 5) {
        filterPayload['name'] = val;
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/company?type=1&page=1');
      }
      else if (axes == 65 || axes == 42) {
        if (val)
          filterPayload['name'] = val;
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/product')
      }
      else if (axes == 67 || axes == 6) {
        filterPayload['name'] = val;
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/user')
      }
      else if (axes == 64) {
        filterPayload['order_number'] = val.split('/ ')[1];
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/orders')
      }
      else if (axes == 58 || axes == 76 || axes == 31 || axes == 60 || axes == 59 || axes == 61 || axes == 75 || axes == 78 || axes == 76) {
        if (val) {
          filterPayload['name'] = val;
        }
        this.checkForPrimaryAxis(primary_axis_ids, filter);
        this.router.navigateByUrl('/ship');
      }
    }
    localStorage.setItem('filterPayload', JSON.stringify(filterPayload));
  }

  checkForPrimaryAxis(axes, val) {
    if (axes) {
      console.log(axes, val)
    }
  }
}