import { CommonService } from '@app/shared/helper/common';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from '@app/modules/category/services/category.service';
import { UserRoles } from './../../../../core/enums/user-roles';
import { Company } from './../../../company/models/company.modal';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { ProductColumns } from './../../model/product-column';
import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Product } from '../../model/product.model';
import { ProductService } from '../../services/product.service';
import { DataTableColumn, DataTablePagination } from '@app/core/models/data-table';
import { Router } from '@angular/router';
import { PaginationData } from '@app/core/models/response.model';
import { SharedService } from '@app/shared/service/shared.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FileUpload } from 'primeng/fileupload';
import { LoadingService } from '@app/authentication/services/loading.service';
import { AlertService } from '@app/shared/service/alert.service';

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.css']
})
export class NewProductComponent implements OnInit, AfterContentChecked {
  newProducts: Product[] = [];
  columns: DataTableColumn[] = ProductColumns;
  isLoading: boolean;
  page = new DataTablePagination();
  selectedAutocomplete = '';

  uploadForm: FormGroup;
  submitted = false;
  productList: Product[] = [];
  loading = false;
  openFilters: boolean;
  toggleProductView = false;
  product: Product;
  companys;
  msg: boolean;
  total: number;
  isEditMode: boolean;
  role: number;
  company: Company;
  userRoles = UserRoles;
  filterForm: FormGroup;
  value;
  sortInfo;
  fileName: string;
  modalRef: BsModalRef;
  loaderView = false;

  status = [{ name: 'Active', value: 1 }, { name: 'Inactive', value: 0 }];

  suggestion: Product[];

  @ViewChild('newProductTable', { static: false }) table;
  @ViewChild('autocomplete', { static: false }) autocomplete;
  @ViewChild('productUpload', { static: false }) productUpload: FileUpload;
  categories: any;
  formData: FormData;
  file: File;

  actions: any[] = [{ name: 'Update Product If Shipskart Code Exist', id: 1 },
  { name: 'Create New Product If Shipskart Code Exist', id: 0 }];
  clickedPage: any;

  constructor(
    private productService: ProductService,
    private router: Router,
    private cdref: ChangeDetectorRef,
    private sharedService: SharedService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private categoryService: CategoryService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private loadingService: LoadingService,
    private alertService: AlertService
  ) {
    this.page.limit = 10;
    this.page.offset = 0;
    this.page.count = 0;
    this.formData = new FormData();
  }

  ngOnInit() {
    this.role = this.sharedService.getUserRole();
    this.company = this.sharedService.getCompany();
    this.uploadForm = this.formBuilder.group({
      category_id: [null, Validators.required],
      file_name: ['', Validators.required],
      // is_new: [0]
    });


    this.filterForm = this.formBuilder.group({
      name: [''],
      impa_code: [''],
      product_code: [''],
      category_id: [''],
      sub_category_id: [''],
      status: [1]
    });
    this.allCategories();
    this.setPage(this.page);
  }

  formReset() {
    this.uploadForm.reset();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  onCellClicked(row) {
    this.router.navigate(['/product/detail/', row.id]);
  }

  sortCallback(sortInfo: {
    sorts: { dir: string; prop: string }[];
    column: {};
    prevValue: string;
    newValue: string;
  }) {
    const params = new HttpParams()
      .set('orderby', `${sortInfo.sorts[0].dir}`)
      .set('page', `${this.page.offset + 1}`)
      .set('limit', `${this.page.limit}`)
      .set('coloum', `${sortInfo.sorts[0].prop}`);
    this.getDate(params);
  }

  setPage(page: DataTablePagination, parms = {}) {
    // Server pagination start from 1
    this.isLoading = true;
    const pageNo = page.offset + 1;
    const params = new HttpParams()
      .set('page', `${pageNo}`)
      .set('limit', `${page.limit}`);
    this.getDate(params);

    // table scroll on pagination change
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 5);
  }

  onPageChange($event) {
    this.clickedPage = $event.page;
  }

  getDate(params) {
    this.page = this.clickedPage ? this.page : this.commonService.CheckPage(this.page);
    const pageNo = this.page.offset + 1;
    const filter = this.commonService.CheckFilter(this.filterForm);
    const filterFormValue = JSON.parse(filter);
    this.filterForm.patchValue(filterFormValue);
    const headers = new HttpHeaders().set(
      'x-filter',
      filter
    );
    this.productService
      .getProductRequest(params)
      .subscribe((ProductData: PaginationData) => {
        this.newProducts = ProductData.results.map((product: Product) => {
          const rowData = {
            id: product.id,
            name: product.name,
            category: product.category ? product.category.name : '',
            description: product.description,
            sub_category: product.sub_category ? product.sub_category.name : '',
          };
          return rowData;
        });
        this.page.count = ProductData.total;
        this.isLoading = false;
      });
  }

  getSearch() {
    this.autocomplete.hide()
    this.page.offset = 0;
    const search = this.filterForm.get('name').value;
    this.productService.getProductRequest({ search: search })
      .subscribe((data: PaginationData) => {
        this.newProducts = data.results.map((product: Product) => {
          const rowData = {
            id: product.id,
            name: product.name,
            category: product.category ? product.category.name : '',
            description: product.description,
            sub_category: product.sub_category ? product.sub_category.name : '',
          };
          return rowData;
        });
        this.page.count = data.total;
        this.isLoading = false;
      });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray product-modal' })
    );
    this.alertService.backModalClose(this.modalRef)
  }

  selectedfileName(files: FileList) {
    if (files.length > 0) {
      this.fileName = files[0].name;
      this.file = files[0];
    }
  }

  uploadProduct() {
    if (this.uploadForm.valid) {
      const formData = new FormData();
      // const is_edit = +(this.uploadForm.value.is_new);
      formData.append('file', this.file, this.fileName);
      formData.append('file_name', this.fileName);
      formData.append('category_id', this.uploadForm.value.category_id);
      // formData.append('is_new', is_edit.toString());

      this.isLoading = true;
      this.productService.uploadProduct(formData).subscribe(
        data => {
          this.isLoading = false;
          this.toastr.success('Product(s) imported successfully');
          this.modalRef.hide();
          this.file = this.fileName = null;
          this.setPage(this.page);
          this.uploadForm.reset();  // #TODO  Due To upload product >> Click filter >> upload form auto hits# ERROR
        }, error => {
          this.toastr.error('Something went wrong', error.error);
          this.isLoading = false;
        }
      );
    }
  }

  resetForm() {
    this.filterForm.reset({
      name: '',
      impa_code: '',
      product_code: '',
      category_id: null,
      sub_category_id: null
    });
    this.selectedAutocomplete = '';
    this.isLoading = true;
    this.commonService.buildFilterUrlParm(this.filterForm);
    const params = new HttpParams();
    setTimeout(() => {
      this.getDate(params);
    });
    this.openFilters = false;
  }

  allCategories() {
    this.categoryService.getAllV2(1, 20).subscribe(data => {
      this.categories = data.data;
    });
  }

  exportProduct() {
    this.loaderView = true;
    this.loadingService.setLoading(true);
    let params;
    const pageNo = this.page.offset + 1;
    const header = new HttpHeaders()
      .set('x-file', 'xlsx')
      .set('x-filter', JSON.stringify(this.filterForm.value));

    params = new HttpParams()
      .set('page', `${pageNo}`)
      .set('limit', `${this.page.limit}`);

    this.productService.export(params, header).subscribe(data => {
      this.loaderView = false;
      this.loadingService.setLoading(false);
      this.sharedService.saveAsBlob(
        data,
        'application/vnd.ms-excel',
        `products.xlsx`
      );
    });
  }

  search(event) {
    this.productService
      .getProductRequestSuggestion(event.query)
      .subscribe(data => {
        data.data.map(x => {
          x.name = x._source.name;
          x.id = x._id;
        });
        this.suggestion = data.data;
      }, error => {
        this.suggestion = [];
      });
  }

  showCrossBtn(event) {
    this.selectedAutocomplete = event.target.value;
  }

  doOnSelect(event) {
    this.filterForm.get('name').setValue(event.name);
    this.getSearch();
  }

  navigateProductEdit(id) {
    return this.router.navigate([`/product/edit/${id}`], { queryParams: { from: 'request' } })
  }
}
