import { Order } from "./../model/order.model";
import { environment } from "@environment/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { PredictedPriceRequestPayload, PredictedPriceResponse } from "@app/shared/model/PredictingUnqotedPricesModels";
import { FormGroup } from '@angular/forms';
import { FormDataToJsonService } from "@app/shared/service/form-data-to-json.service";


@Injectable({ providedIn: "root" })
export class OrderService {
  constructor(private http: HttpClient,
    private formDataToJson: FormDataToJsonService
  ) { }

  getAll(params, headers = null) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/order/`, { headers, params })
      .pipe(
        map((dataVal) => {
          return dataVal.data;
        })
      );
  }

  addOrder(order: Order) {
    return this.http.post<Order>(`${environment.apiUrl}/v1/order/`, order);
  }
  addProduct(payload) {
    return this.http.post<Order>(
      `${environment.apiUrl}/v1/order/details`,
      payload
    );
  }
  editOrderDetails(payload) {
    return this.http.put<Order>(
      `${environment.apiUrl}/v1/order/details/${payload.id}`,
      payload
    );
  }
  updateMargin(orderId, vendorId, body = null) {
    return this.http.put<any>(
      `${environment.apiUrl}/v1/order/update_req_quotation_to_product_prices/${orderId}/${vendorId}`,
      body
    );
  }
  deleteOrderDetails(id) {
    return this.http.delete<any>(
      `${environment.apiUrl}/v1/order/details/delete/${id}`
    );
  }

  editOrder(payload) {
    return this.http.put(`${environment.apiUrl}/v1/order/`, payload);
  }

  reOrder(payload) {
    return this.http.post<Order>(
      `${environment.apiUrl}/v1/order/reorder`,
      payload
    );
  }
  getByid(id: number) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/order/details/${id}`)
      .pipe(
        map((dataVal) => {
          return dataVal.data;
        })
      );
  }

  removeProduct(payload) {
    return this.http.post<any>(`${environment.apiUrl}/v1/order/`, payload);
  }
  export(params, header) {
    return this.http
      .get(`${environment.apiUrl}/v1/order/export`, {
        params,
        headers: header,
        responseType: "blob",
      })
      .pipe(
        map((dataVal) => {
          return dataVal;
        })
      );
  }
  exportDetails(id: number, type, isVendorPdf) {
    const header = new HttpHeaders().set("x-file", [type]);
    if (type == 'pdf') {
      return this.http
        .get(`${environment.apiUrl}/v1/order/details/export/${id}?is_vendor_pdf=${isVendorPdf}`, {
          headers: header,
          responseType: "blob",
        })
        .pipe(
          map((dataVal) => {
            return dataVal;
          })
        );
    } else {
      return this.http
        .get(`${environment.apiUrl}/v1/order/details/export/${id}`, {
          headers: header,
          responseType: "blob",
        })
        .pipe(
          map((dataVal) => {
            return dataVal;
          })
        );
    }
  }

  updateStatus(payload, id) {
    return this.http.put(
      `${environment.apiUrl}/v1/order/update-status/${id}`,
      payload
    );
  }
  updateDelivery(payload) {
    return this.http.post(`${environment.apiUrl}/v1/order/delivery`, payload);
  }
  rating(payload) {
    return this.http.post(`${environment.apiUrl}/v1/review/`, payload);
  }
  addPayment(payload) {
    return this.http.post(`${environment.apiUrl}/v1/order/payment/`, payload);
  }
  getPayment(orderId: number) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/order/payment/${orderId}`)
      .pipe(
        map((dataVal) => {
          return dataVal.data;
        })
      );
  }
  getRating(orderId: number) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/review/${orderId}`)
      .pipe(
        map((dataVal) => {
          return dataVal.data;
        })
      );
  }
  getVendorRating(params, headers = null) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/review/`, { params, headers })
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  downloadPreDeliveryNote(id: number, type: string, supply, doe, orderId, address = null) {
    const header = new HttpHeaders().set("x-file", [type]);
    return this.http
      .get(
        `${environment.apiUrl}/v1/order/${id}/pre-delivery-note/${supply}/${doe}/${orderId}/${address}`,
        {
          headers: header,
          responseType: "blob",
        }
      )
      .pipe(
        map((dataVal) => {
          return dataVal;
        })
      );
  }

  downloadDeliveryNote(id: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/v1/order/${id}/delivery-note`);
  }

  uploadInvoice(body) {
    return this.http.post(`${environment.apiUrl}/v1/order/invoice`, body);
  }

  updatePO(orderId, poNumber) {
    const formData = new FormData();
    formData.append("po_number", poNumber);
    return this.http.post(
      `${environment.apiUrl}/v1/order/${orderId}/po-number`,
      formData
    );
  }

  addDiscount(body) {
    const formDataToJson = this.formDataToJson.convertFormDataToJson(body);
    return this.http.post(`${environment.apiUrl}/v1/order/apply-offer`, formDataToJson);
  }

  removeDiscount(offerId): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}/v1/order/remove-offer/${offerId}`
    );
  }

  setPreferredVendor(orderId, vendorIds, body = null) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/order/set_preferred_vendor_list/${orderId}/${vendorIds}`,
      body
    );
  }

  sendQuotation(orderId, vendorIds, body, newVendor = false) {
    if (newVendor) {
      return this.http.post<any>(
        `${environment.apiUrl}/v1/order/send_request_for_quotation_to_guest_vendor/${orderId}/${vendorIds}`,
        body
      );
    } else {
      return this.http.post<any>(
        `${environment.apiUrl}/v1/order/send_request_for_quotation_to_vendor/${orderId}/${vendorIds}`,
        body
      );
    }
  }

  sendQuotationToClient(orderId, vendorIds, body) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/order/send_email_quotation_to_client/${orderId}/${vendorIds}`,
      body
    );
  }
  sendReQuotation(orderId, vendorIds, body) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/order/send_request_for_re_quotation_to_vendor/${orderId}/${vendorIds}`,
      body
    );
  }

  updateReq(orderId, vendorId, is_system_price, body = null) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/order/update_req_quotation_to_product_prices/${orderId}/${vendorId}?is_system_price=${is_system_price}`,
      body
    );
  }

  generateInvoice(orderId, payload, type, invoice = 0): Observable<any> {
    const header = new HttpHeaders({ "Content-Type": "application/json" }).set(
      "x-file",
      [type]
    );
    return this.http
      .post<any>(
        `${environment.apiUrl}/v1/order/invoice/preview/${orderId}/${invoice}`,
        payload,
        {
          headers: header,
          responseType: "blob" as "json",
        }
      )
      .pipe(
        map((dataVal) => {
          return dataVal;
        })
      );
  }

  exportQuotation(
    orderId,
    vendorId,
    type = "xlsx",
    quotation = true,
    productIds = [0],
    selectedPredictedPrices = [],
    isMargin?,
    final_submission?
  ) {
    const header = new HttpHeaders().set("x-file", [type]).set("x-filter", JSON.stringify(selectedPredictedPrices));
    let url;
    if (quotation) {
      url = `${environment.apiUrl}/v1/order/export_req_quotation_details/${orderId}/${vendorId}/${productIds}`;
    } else {
      url = `${environment.apiUrl}/v1/order/export_product_quotation_sheet/${orderId}/${vendorId}/${isMargin}?final_submission=${final_submission}`;
    }
    return this.http
      .get(url, {
        headers: header,
        responseType: "blob",
      })
      .pipe(
        map((dataVal) => {
          return dataVal;
        })
      );
  }

  uploadQuotationSheet(orderId, vendorId, payload) {
    const headers = new HttpHeaders();
    headers.set("Content-Type", null);
    headers.set("Accept", "multipart/form-data");
    return this.http.post<any>(
      `${environment.apiUrl}/v1/order/import_req_quotation_details/${orderId}/${vendorId}`,
      payload,
      { headers }
    );
  }

  supportingDocument(file) {
    const header = new HttpHeaders();
    header.set("Content-Type", "application/json");
    header.set("Accept", "multipart/form-data");
    return this.http
      .post<{ data }>(
        `${environment.apiUrl}/v1/product/supporting_documents/`,
        file,
        { headers: header }
      )
      .pipe(
        map((dataValue) => {
          return dataValue.data;
        })
      );
  }

  updateOrder(order_id, payload) {
    return this.http.put<any>(
      `${environment.apiUrl}/v1/order/edit-order/${order_id}`,
      payload
    );
  }

  getPredictedPrices(payload: PredictedPriceRequestPayload) {
    return this.http.post<PredictedPriceResponse[]>(`${environment.apiUrl}/v1/order/predict_product_price`, payload);
  }

  /* Move to cart*/
  moveToCart(payload) {
    const { order_id, product_id } = payload;
    return this.http.post<any>(`${environment.apiUrl}/v1/order/move_requirement_to_cart_from_req_details`, { order_id, product_id })
  }

  updateApprovalStatus(form: FormGroup) {
    const value = form.value;
    return this.http.put<any>(`${environment.apiUrl}/v1/order/update_approval_status`, {
      order_id: value.order_id,
      approval_status: value.approval_status,
      reason_for_approval_or_rejection: value.reason_for_approval_or_rejection
    })
  }

  getShipskartLocation() {
    return this.http.get(`${environment.apiUrl}/v1/order/mos/user_groups`).pipe(map(res => res))
  }
}
